import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { Cookies, useCookies } from "react-cookie";

import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Switch from "@mui/material/Switch";
import { useState, useEffect } from "react";
import Loading from "components/loadingComponent";
import EditScoreForm from "./editSCoreForm";
import { server } from "utils/server";

const psychomotorEvaluationOptions = ["Select", "Legibility", "Accuracy", "Neatness", "Dexterity"];

export default function BroadSheet({
  data,
  isClicked,
  reload,
  getClass,
  broadsheetSubject,
  showLoad,
  counter,
}) {
  const [selectedEvaluation, setSelectedEvaluation] = useState("");
  const [studentsState, setStudentsState] = useState(data.psychomotorEvaluation);
  const [evaluationDropDownDisplay, setEvaluationDropDownDisplay] = useState(false);
  const [scoreKey, setScoreKey] = useState(0);
  const [students, setStudents] = useState(data);
  const [scoreHeaders, setScoreHeaders] = useState([]);
  const [scoreMain, setScoreMain] = useState([]);
  const [showScores, setShowScores] = useState(false);
  const [firstTermState, setFirstTermState] = useState(false);
  const [secondTermState, setSecondTermState] = useState(false);
  const [thirdTermState, setThirdTermState] = useState(false);
  const [term, setTerm] = useState("first");
  const [renderEdit, setRenderEdit] = useState(false);
  const [valueToEdit, setValueToEdit] = useState(0);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [arrCounter, setArrCounter] = useState(0);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [idToEdit, setIdToEdit] = useState("");
  const [idToCreate, setIdToCreate] = useState("");
  const [classToCreate, setClassToCreate] = useState("");
  const [subjectToCreate, setSubjectToCreate] = useState("");
  const [renderCreate, setRenderCreate] = useState(false);
  const [showVet, setShowVet] = useState(false);
  const [showNow, setShowNow] = useState(false);
  const [selectedEvaluationName, setSelectedEvaluationName] = useState("");
  const [testToEdit, setTestToEdit] = useState("");
  const [showComponent, setShowComponent] = useState(true);
  const [studentsTemp, setStudentsTemp] = useState([]);
  const [subjectsTemp, setSubjectsTemp] = useState([]);
  const [classesTemp, setClassesTemp] = useState([]);
  const [firstIndex, setFirstIndex] = useState([]);
  const [secondIndex, setSecondIndex] = useState([]);
  const [getTemp, setGetTemp] = useState(2);
  const [done, setDone] = useState(0);
  const [overAlls, setOverAlls] = useState([]);
  const { server1 } = server();

  const [key, setKey] = useState(0);
  const navigation = useNavigate();

  const getClassResults = async () => {
    // console.log(isLoggedIn);

    const response = await fetch(`${server1}/teacher/get_class_scores_one_subject`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      var temp = {
        studentArr: responseData.data[counter],
        class: responseData.classes[counter],
        subjects: responseData.subjects[counter],
        skills: responseData.skills,
      };
      setStudents(temp);
    } else {
      console.log("error");
    }
  };

  const getSession = async () => {
    const response = await fetch(`${server1}/admins/get_current_session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      if (responseData.data.term == 1) {
        setFirstTermState(true);
      }
      if (responseData.data.term == 2) {
        setSecondTermState(true);
      }
      if (responseData.data.term == 3) {
        setThirdTermState(true);
      }
    } else {
      console.log("failed");
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  useEffect(() => {
    if (students) {
      if (firstTermState && students.class.first_published) {
        setShowNow(true);
      }
      if (firstTermState && !students.class.first_published) {
        setShowVet(true);
      }
      if (secondTermState && students.class.second_published) {
        setShowNow(true);
      }
      if (secondTermState && !students.class.second_published) {
        setShowVet(true);
      }
      if (thirdTermState && students.class.third_published) {
        setShowNow(true);
      }
      if (thirdTermState && !students.class.third_published) {
        setShowVet(true);
      }
    }
  }, students);

  const enableEdit = async () => {
    setIsLoading(true);
    var term = "";
    if (firstTermState) {
      term = "first";
    }
    if (secondTermState) {
      term = "second";
    }
    if (thirdTermState) {
      term = "third";
    }
    var data = {
      classId: students.class._id,
      term: term,
    };

    const response = await fetch(`${server1}/admins/unlock_scores`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.ok) {
      setAlertDisplay(true);
      setAlertType("success");
      setAlertMessage("Edit class enabled");
      setIsLoading(false);
      // setStudents(temp)
    } else {
      console.log("failed");
    }
  };

  const sendRating = async (id, rating) => {
    showLoad(true);
    const data = {
      studentId: id,
      classId: students.class._id,
      skill: selectedEvaluation,
      rating: rating,
    };
    const response = await fetch(`${server1}/teacher/update_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
      reload(getTemp + 1);
    } else {
      console.log("failed");
    }
  };

  const submitClass = async () => {
    showLoad(true);
    var term = "";
    if (firstTermState) {
      term = "first";
    }
    if (secondTermState) {
      term = "second";
    }
    if (thirdTermState) {
      term = "third";
    }

    const data = {
      classId: students.class._id,
      term: term,
    };
    const response = await fetch(`${server1}/teacher/submit_class`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded

      reload(getTemp + 1);
      //const responseData = await response.json();
    } else {
      // User creation failed
      // setAlertMessage(msg.message);
      // setAlertType("error");
      // setAlertDisplay(true);
      console.log("failed");
      // const errorData = await response.json();
      // console.log(errorData);
      // console.error("User creation failed:", errorData.error);
    }
  };

  //Updating Students PsychomotorEvaluation
  const handleStudentPsychomotorEvaluationChange = (name, newValue) => {
    switch (selectedEvaluation) {
      case "Legibility":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  legibility: item.legibility === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Accuracy":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  accuracy: item.accuracy === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Neatness":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  neatness: item.neatness === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Dexterity":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  dexterity: item.dexterity === newValue ? "" : newValue,
                }
              : item
          )
        );
      default:
        return;
    }
  };

  //Updating StudentEnability
  const updateStudentEnability = (no) => {
    setStudents((prevData) =>
      prevData.map((item) =>
        item.No === no ? { ...item, isEnabled: item.isEnabled ? false : true } : item
      )
    );
  };
  var setCapitals = (string) => {
    var subHolder = string.split(" ");
    var mainSubject = "";
    for (let i = 0; i < subHolder.length; i++) {
      if (subHolder[i]) {
        if (subHolder[i].length > 0) {
          var first = subHolder[i][0].toUpperCase();
          var others = subHolder[i].slice(1);
          var full = first + others.toLowerCase();
          if (i === 0) {
            mainSubject = full;
          } else {
            mainSubject = mainSubject + " " + full;
          }
        }
      }
    }
    return mainSubject;
  };

  const handleSelectPsych = (data, student) => {
    var skillsArr = [];
    var found = false;
    for (let index = 0; index < student.skills.length; index++) {
      if (selectedEvaluation === student.skills[index].skill) {
        skillsArr.push(
          <input
            style={{ cursor: "pointer" }}
            onClick={() => sendRating(student.studentDetails._id, data)}
            type="checkbox"
            checked={student.skills[index].rating == data ? true : false}
          />
        );
      }
    }
    if (!found) {
      skillsArr.push(
        <input
          style={{ cursor: "pointer" }}
          onClick={() => sendRating(student.studentDetails._id, data)}
          type="checkbox"
          checked={false}
        />
      );
    }
    return skillsArr;
  };

  const handleScoreEdit = (studentIndex, test, score) => {
    var scoreId = students.studentArr[studentIndex].scoreDetails
      ? students.studentArr[studentIndex].scoreDetails._id
      : 0;
    setIdToEdit(scoreId);
    setRenderEdit(true);
    setTestToEdit(test);
    setFirstIndex(studentIndex);
    let mainScore;
    if (scoreId.length > 0) {
      if (test == "test1a") {
        mainScore = students.studentArr[studentIndex].scoreDetails.test1a;
      }
      if (test == "test1b") {
        mainScore = students.studentArr[studentIndex].scoreDetails.test1b;
      }
      if (test == "exam1") {
        mainScore = students.studentArr[studentIndex].scoreDetails.exam1;
      }
      if (test == "test2a") {
        mainScore = students.studentArr[studentIndex].scoreDetails.test2a;
      }
      if (test == "test2b") {
        mainScore = students.studentArr[studentIndex].scoreDetails.test2b;
      }
      if (test == "exam2") {
        mainScore = students.studentArr[studentIndex].scoreDetails.exam2;
      }
      if (test == "test3a") {
        mainScore = students.studentArr[studentIndex].scoreDetails.test3a;
      }
      if (test == "test3b") {
        mainScore = students.studentArr[studentIndex].scoreDetails.test3b;
      }
      if (test == "exam3") {
        mainScore = students.studentArr[studentIndex].scoreDetails.exam2;
      }
    } else {
      mainScore = 0;
    }

    // displayDropDownMenu(id);
    setValueToEdit(mainScore);
  };
  const getNewClass = async (subjectValue) => {
    const response = await fetch(`${server1}/teacher/get_new_class/${subjectValue}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });

    const responseData = await response.json();
    if (response.ok) {
      //  setStudents(responseData.data)
      //  setSubjects(responseData.subjectAndClass)
      //  setClasses(responseData.subjectAndClass)
      var temp = {
        studentArr: responseData.data,
        class: responseData.subjectAndClass,
        subjects: responseData.subjectAndClass,
        skills: {},
      };
      setStudents(temp);
      //  setNum(num+2)
    } else {
      console.log("failed");
    }
  };

  const editScore = async (data) => {
    try {
      showLoad(true);
      setRenderEdit(false);
      const response = await fetch(`${server1}/teacher/update_subject_score/${idToEdit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify(data),
      });
      var msg = await response.json();
      if (response.ok) {
        broadsheetSubject(msg.data._id);

        getNewClass(msg.data._id)
          .then(() => {
            var newDone = done + 2;
            setDone(newDone);
          })
          .then(() => {
            showLoad(false);
          });
        setDone(done + 2);
        //reload(getTemp+1)
      } else {
        // User creation failed
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
        // const errorData = await response.json();
        // console.log(errorData);
        // console.error("User creation failed:", errorData.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createScore = async (data) => {
    showLoad(true);
    setRenderCreate(false);
    const response = await fetch(`${server1}/teacher/create_score`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
      getClassResults()
        .then(() => {
          setDone(done + 2);
        })
        .then(() => {
          showLoad(false);
        });
      // reload(getTemp+1)
    } else {
      setAlertMessage(msg.message);

      setAlertType("error");
      setAlertDisplay(true);
    }
  };

  useEffect(() => {
    showLoad(false);
  }, []);
  // useEffect(()=>{
  //   reload(getTemp+2)
  // }, [getTemp])

  var handleOverAlls = () => {
    var output = [];
    var totals = [];
    var averages = [];
    var allOverAlls = [];
    for (let i = 0; i < students.studentArr.length; i++) {
      var studentTotal = 0;
      var available = false;
      for (let index = 0; index < students.studentArr[i].scoreDetails.length; index++) {
        available = true;
        var scores = students.studentArr[i].scoreDetails[index];
        if (firstTermState) {
          var score = scores.test1a + scores.test1b + scores.exam1;
          studentTotal += score;
        }
        if (secondTermState) {
          var score = scores.test2a + scores.test2b + scores.exam2;
          studentTotal += score;
        }
        if (thirdTermState) {
          var score = scores.test3a + scores.test3b + scores.exam3;
          studentTotal += score;
        }
      }
      if (available) {
        var studentAverage = studentTotal / students.studentArr[i].scoreDetails.length;
        var temp = {
          average: studentAverage,
          total: studentTotal,
          available: available,
        };
        allOverAlls = [...allOverAlls, temp];
        averages = [...averages, studentAverage];
        totals = [...totals, studentTotal];
      } else {
        var temp = {
          average: 0,
          total: 0,
        };
        allOverAlls = [...allOverAlls, temp];
        averages = [...averages, 0];
        totals = [...totals, 0];
      }
    }
    averages.sort();
    averages.reverse();

    for (let index = 0; index < allOverAlls.length; index++) {
      const subjectPositionFn = (element) => element == allOverAlls[index].average;

      const classPosition = averages.findIndex(subjectPositionFn);

      output.push(
        <tr key={scoreKey}>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">
              {allOverAlls[index].available ? allOverAlls[index].average.toFixed(1) : "N/A"}
            </MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">
              {allOverAlls[index].available ? allOverAlls[index].total : "N/A"}
            </MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">
              {allOverAlls[index].available ? classPosition + 1 : "N/A"}
            </MDTypography>
          </td>
        </tr>
      );
    }
    return output;
  };

  const positionArrange = (num) => {
    var newNum = num.toString();
    var suffix = "th";
    var resultString = newNum;
    if (newNum[newNum.length - 1] == "1" && newNum[newNum.length - 2] == "1") {
      suffix = "th";
    } else if (newNum[newNum.length - 1] == "1" && newNum[newNum.length - 2] != "1") {
      suffix = "st";
    } else if (newNum[newNum.length - 1] == "3" && newNum[newNum.length - 2] != "1") {
      suffix = "rd";
    } else if (newNum[newNum.length - 1] == "2" && newNum[newNum.length - 2] != "1") {
      suffix = "nd";
    }
    return resultString + suffix;
  };

  const showSubjectInfos = () => {
    var infosArray = [];

    var subjectPositionArray = [];
    for (let i = 0; i < students.studentArr.length; i++) {
      //const subjectPosition = (element) => element == score;
      var scores = students.studentArr[i].scoreDetails;
      if (firstTermState) {
        if (scores != null) {
          var score = scores.test1a + scores.test1b + scores.exam1;
          subjectPositionArray = [...subjectPositionArray, score];
        }
      }
      if (secondTermState) {
        if (scores != null) {
          var score = scores.test2a + scores.test2b + scores.exam2;
          subjectPositionArray = [...subjectPositionArray, score];
        }
      }
      if (thirdTermState) {
        if (scores != null) {
          var score = scores.test3a + scores.test3b + scores.exam3;
          subjectPositionArray = [...subjectPositionArray, score];
        }
      }
    }
    subjectPositionArray.sort();
    subjectPositionArray.reverse();
    var subjectTotal = subjectPositionArray.reduce((a, b) => a + b, 0);
    var subjectAverage = subjectTotal / subjectPositionArray.length;
    var subjectAverageFlag = true;
    if (isNaN(subjectAverage)) {
      subjectAverage = "N/A";
      subjectAverageFlag = false;
    }
    var subjectPositionFlag = true;

    var flag = false;

    for (let index = 0; index < students.studentArr.length; index++) {
      flag = true;
      var scores = students.studentArr[index].scoreDetails;
      if (firstTermState) {
        var score = "N/A";
        if (scores != null) {
          var score = scores.test1a + scores.test1b + scores.exam1;
        }
        const subjectPositionFn = (element) => element == score;
        var subjectPosition = subjectPositionArray.findIndex(subjectPositionFn);
        if (subjectPosition < 0) {
          subjectPositionFlag = false;
        }
        var mainPosition = positionArrange(subjectPosition + 1);
        infosArray.push(
          <tr key={scoreKey} className="tableHeaderRow">
            <td style={{ display: "flex", justifyContent: "center" }}>
              {!students.subjects.class.first_locked && (
                <Icon
                  style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: "absolute" }}
                  onClick={() => {
                    handleScoreEdit(index, "test1a", scores ? scores.test1a : 0);
                  }}
                >
                  edit
                </Icon>
              )}
              <MDTypography variant="overline">{scores ? scores.test1a : "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              {!students.subjects.class.first_locked && (
                <Icon
                  style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: "absolute" }}
                  onClick={() => {
                    handleScoreEdit(index, "test1b", scores ? scores.test1b : 0);
                  }}
                >
                  edit
                </Icon>
              )}
              <MDTypography variant="overline">{scores ? scores.test1b : "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              {!students.subjects.class.first_locked && (
                <Icon
                  style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: "absolute" }}
                  onClick={() => {
                    handleScoreEdit(index, "exam1", scores ? scores.exam1 : 0);
                  }}
                >
                  edit
                </Icon>
              )}
              <MDTypography variant="overline">{scores ? scores.exam1 : "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">{score ?? "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">
                {subjectAverageFlag ? subjectAverage.toFixed(1) : "N/A"}
              </MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">
                {subjectPositionFlag ? mainPosition : "N/A"}
              </MDTypography>
            </td>
          </tr>
        );
      }
      if (secondTermState) {
        var score = "N/A";
        if (scores != null) {
          var score = scores.test2a + scores.test2b + scores.exam2;
        }
        const subjectPositionFn = (element) => element == score;
        const subjectPosition = subjectPositionArray.findIndex(subjectPositionFn);
        if (subjectPosition < 0) {
          subjectPositionFlag = false;
        }
        var mainPosition = positionArrange(subjectPosition + 1);
        infosArray.push(
          <tr key={scoreKey} className="tableHeaderRow">
            <td style={{ display: "flex", justifyContent: "center" }}>
              {!students.subjects.class.second_locked && (
                <Icon
                  style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: "absolute" }}
                  onClick={() => {
                    handleScoreEdit(index, "test2a", scores ? scores.test2a : 0);
                  }}
                >
                  edit
                </Icon>
              )}
              <MDTypography variant="overline">{scores ? scores.test2a : "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              {!students.subjects.class.second_locked && (
                <Icon
                  style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: "absolute" }}
                  onClick={() => {
                    handleScoreEdit(index, "test2b", scores ? scores.test2b : 0);
                  }}
                >
                  edit
                </Icon>
              )}
              <MDTypography variant="overline">{scores ? scores.test2b : "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              {!students.subjects.class.second_locked && (
                <Icon
                  style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: "absolute" }}
                  onClick={() => {
                    handleScoreEdit(index, "exam2", scores ? scores.exam2 : 0);
                  }}
                >
                  edit
                </Icon>
              )}
              <MDTypography variant="overline">{scores ? scores.exam2 : "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">{score}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">
                {subjectAverageFlag ? subjectAverage.toFixed(1) : "N/A"}
              </MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">
                {subjectPositionFlag ? mainPosition : "N/A"}
              </MDTypography>
            </td>
          </tr>
        );
      }
      if (thirdTermState) {
        var score = "N/A";
        if (scores != null) {
          score = scores.test3a + scores.test3b + scores.exam3;
        }
        const subjectPositionFn = (element) => element == score;
        const subjectPosition = subjectPositionArray.findIndex(subjectPositionFn);
        if (subjectPosition < 0) {
          subjectPositionFlag = false;
        }
        var mainPosition = positionArrange(subjectPosition + 1);
        infosArray.push(
          <tr key={scoreKey} className="tableHeaderRow">
            <td style={{ display: "flex", justifyContent: "center" }}>
              {!students.subjects.class.third_locked && (
                <Icon
                  style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: "absolute" }}
                  onClick={() => {
                    handleScoreEdit(index, "test3a", scores ? scores.test3a : 0);
                  }}
                >
                  edit
                </Icon>
              )}
              <MDTypography variant="overline">{scores ? scores.test3a : "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              {!students.subjects.class.third_locked && (
                <Icon
                  style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: "absolute" }}
                  onClick={() => {
                    handleScoreEdit(index, "test3b", scores ? scores.test3b : 0);
                  }}
                >
                  edit
                </Icon>
              )}
              <MDTypography variant="overline">{scores ? scores.test3b : "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              {!students.subjects.class.third_locked && (
                <Icon
                  style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: "absolute" }}
                  onClick={() => {
                    handleScoreEdit(index, "exam3", scores ? scores.exam3 : 0);
                  }}
                >
                  edit
                </Icon>
              )}
              <MDTypography variant="overline">{scores ? scores.exam3 : "N/A"}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">{score}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">
                {subjectAverageFlag ? subjectAverage.toFixed(1) : "N/A"}
              </MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">
                {subjectPositionFlag ? mainPosition : "N/A"}
              </MDTypography>
            </td>
          </tr>
        );
      }
    }

    // if(!flag){
    //   var studentId= students.studentArr[index]._id
    //   var first= ''
    //   var second= ''
    //   var third= ''
    //   if(firstTermState){
    //     first= 'test1a'
    //     second= 'test1b'
    //     third= 'exam1'
    //   }
    //   if (secondTermState) {
    //     first= 'test2a'
    //     second= 'test2b'
    //     third= 'exam2'
    //   }
    //   if (thirdTermState) {
    //     first= 'test3a'
    //     second= 'test3b'
    //     third= 'exam3'
    //   }
    //   infosArray.push(
    //     <tr key={Math.random()} className="tableHeaderRow">
    //       <td style={{ display: "flex", justifyContent: "center" }}>
    //       {thirdTermState && !students.class.third_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(first, studentId, classId, id)}}>edit</Icon>}
    //       {secondTermState && !students.class.second_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(first, studentId, classId, id)}}>edit</Icon>}
    //       {firstTermState && !students.class.first_locked_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(first, studentId, classId, id)}}>edit</Icon>}
    //         <MDTypography variant="overline">N/A</MDTypography>
    //       </td>
    //       <td style={{ display: "flex", justifyContent: "center" }}>
    //       {thirdTermState && !students.class.third_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(second, studentId, classId, id)}}>edit</Icon>}
    //       {secondTermState && !students.class.second_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(second, studentId, classId, id)}}>edit</Icon>}
    //       {firstTermState && !students.class.first_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(second, studentId, classId, id)}}>edit</Icon>}
    //         <MDTypography variant="overline">N/A</MDTypography>
    //       </td>
    //       <td style={{ display: "flex", justifyContent: "center" }}>
    //       {thirdTermState && !students.class.third_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(third, studentId, classId, id)}}>edit</Icon>}
    //       {secondTermState && !students.class.second_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(third, studentId, classId, id)}}>edit</Icon>}
    //       {firstTermState && !students.class.first_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(third, studentId, classId, id)}}>edit</Icon>}
    //         <MDTypography variant="overline">N/A</MDTypography>
    //       </td>
    //       <td style={{ display: "flex", justifyContent: "center" }}>
    //         <MDTypography variant="overline">N/A</MDTypography>
    //       </td>
    //       <td style={{ display: "flex", justifyContent: "center" }}>
    //         <MDTypography variant="overline">N/A</MDTypography>
    //       </td>
    //       <td style={{ display: "flex", justifyContent: "center" }}>
    //         <MDTypography variant="overline">N/A</MDTypography>
    //       </td>
    //     </tr>
    //   )
    // }

    const handleNewScore = (test, studentId, classId, subjectId) => {
      setTestToEdit(test);
      setIdToCreate(studentId);
      setClassToCreate(classId);
      setSubjectToCreate(subjectId);

      setRenderCreate(true);
      // displayDropDownMenu(id);
    };

    return infosArray;
  };

  var scoreTable = [];
  var scoreTableHeaders = [];
  var scoreBySubjects = [];
  const handleScoreHeaderView = () => {
    for (let index = 0; index < students.subjects.length; index++) {
      scoreTableHeaders.push(
        <th key={students.subjects[index]._id}>
          <MDTypography variant="h6">{students.subjects[index].name}</MDTypography>
        </th>
      );
    }
    setScoreHeaders(scoreTableHeaders);
  };

  //When ever the Publish button on the teacher's dashboard is Clicked
  useEffect(() => {
    isClicked > 0 && console.log("success");
  }, []);

  useEffect(() => {
    // setKey(key+1)
    //setScoreMain(scoreMain)

    setShowScores(false);
  }, [scoreHeaders]);

  return (
    <>
      <MDTypography variant="h6">
        {/* {`${students.class ? students.class.subject.name.toUpperCase() : ""} ${
          students.class.class ? students.class.class.className.toUpperCase() : ""
        }`} */}
      </MDTypography>
      <MDTypography variant="h6">
        {`${firstTermState ? "FIRST TERM" : secondTermState ? "SECOND TERM" : "THIRD TERM"}`}
      </MDTypography>
      <Card
        sx={{
          width: "90%",
          mt: 3,
          p: 2,
        }}
      >
        {isLoading && <Loading />}
        {alertDisplay && (
          <div
            style={{
              background: alertType === "error" ? "#d31a38" : "green",
              width: 350,
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}

        {showComponent && (
          <div className="broadSheetBox" key={key}>
            {/* {showScores} */}
            {/* The table that displays students name and number */}
            <div className="studentsTable">
              {/* <MDTypography variant="h6">Students</MDTypography> */}
              <table>
                <thead>
                  <tr>
                    {/* {data.students.headers.map((header) => ( */}
                    <th>
                      <MDTypography variant="button" fontWeight="bold">
                        S/NO
                      </MDTypography>
                    </th>
                    <th>
                      <MDTypography variant="button" fontWeight="bold">
                        NAME
                      </MDTypography>
                    </th>
                    {/* ))} */}
                  </tr>
                </thead>

                {students.studentArr &&
                  students.studentArr.map((student, index) => {
                    return (
                      <tr key={student.profile.firstName}>
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">{index + 1}</MDTypography>
                        </td>
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">
                            {student.profile ? student.profile.firstName : "N/A"}{" "}
                            {student.profile ? student.profile.lastName : ""}
                          </MDTypography>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
            {/* {scoreMain} */}
            {/* {students.subjects && students.subjects.map((subject)=>{ */}
            {/* return(  */}
            <div className="tableBox">
              <MDTypography fontSize="16px" variant="button" fontWeight="bold">
                {/* {setCapitals(students.subjects.subject.name)} */}
                {/* {classSubjects[index].name} */}
              </MDTypography>
              <table>
                {showComponent && (
                  <thead style={{ width: "100%" }}>
                    <tr className="tableHeaderRow">
                      <th key={`1ca${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          CA 20%
                        </MDTypography>
                      </th>
                      <th key={`2ca${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          MID-TERM 20%
                        </MDTypography>
                      </th>
                      <th key={`exam${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          EXAM 60%
                        </MDTypography>
                      </th>
                      <th key={`total${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          TOTAL
                        </MDTypography>
                      </th>
                      <th key={`avg${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          Subject Average
                        </MDTypography>
                      </th>

                      <th key={`pos${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          Subject Position
                        </MDTypography>
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody style={{ width: "100%" }} key={done}>
                  {showComponent && showSubjectInfos()}
                  {/* {students.studentArr && students.studentArr.map((student, index) => {
                    return (
                      <tr key={student.score.firstName}>
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">{index+1}</MDTypography>
                        </td>
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">
                            {`${student.profile.firstName} ${student.profile.middleName??student.profile.middleName} ${student.profile.lastName}`}
                          </MDTypography>
                        </td>
                      </tr>
                    );
                  })} */}
                </tbody>
              </table>
            </div>
            {renderEdit && (
              <EditScoreForm
                initialValue={valueToEdit}
                cancelled={() => setRenderEdit(false)}
                newScoreSubmit={createScore}
                submitted={editScore}
                test={testToEdit}
                students={students}
                index1={firstIndex}
                index2={secondIndex}
              />
            )}
          </div>
        )}
      </Card>
      <div style={{ display: "flex" }}>
        {/* {showVet &&
        <Link to={`/manage-result-submission/${students.class._id}`} style={{ width: "120px" }}>
          <MDButton color="dark" size="small" variant="outlined" sx={{ mt: 2, mb: 4 }}>
            Vet Result
          </MDButton>
        </Link>
      }
      {!showVet && showNow && 
        <MDButton color="dark" size="small" disabled variant="outlined" sx={{ mt: 2, mb: 4, mr: 2 }}>
          Result Vetted
        </MDButton>
      } */}

        {/* <MDButton onClick={enableEdit} color="dark" size="small" variant="outlined" sx={{ mt: 2, mb: 4 }}>
      Submit
    </MDButton> */}
      </div>
    </>
  );
}

BroadSheet.propTypes = {
  data: PropTypes.object.isRequired,
  isClicked: PropTypes.number,
  reload: PropTypes.func.isRequired,
  getClass: PropTypes.func.isRequired,
  broadsheetSubject: PropTypes.func.isRequired,
  showLoad: PropTypes.func.isRequired,
  counter: PropTypes.number,
};
