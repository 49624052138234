import { Cookies, useCookies } from "react-cookie";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";

//Hooks
import { useState, useEffect, useRef } from "react";

//datas
import { formTeacherData } from "../data";
import { server } from "utils/server";

export default function ParentForm({ submitted, cancelled, formError }) {
  const formRef = useRef(null);
  const listRef = useRef(null);
  const [saveTeacherButtonElements, setSaveTeacherButtonElements] = useState([
    "add",
    "Save Teacher",
    "inherit",
  ]);
  const [saveSubjectButtonElements, setSaveSubjectButtonElements] = useState([
    "add",
    "Save Subject",
    "inherit",
  ]);
  const [classNameValue, setClassNameValue] = useState("");
  const [selectedSubjectsState, setSelectedSubjectsState] = useState([]);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);

  const [teacherFormState, setTeacherFormState] = useState(formTeacherData);
  const [formTeacherFormDisplay, setFormTeacherFormDisplay] = useState(true);
  const [classSubjectFormDisplay, setClassSubjectFormDisplay] = useState(true);
  const [formTeacherValue, setFormTeacherValue] = useState("");
  const [currentSubjectValues, setCurrentSubjectValues] = useState([]);
  const [currentFormTeacher, setCurrentFormTeacher] = useState("");
  const [currentSubjects, setCurrentSubjects] = useState([]);
  const [addedTeachers, setAddedTeachers] = useState([]);
  const [showFormTeacherBlock, setShowFormTeacherBlock] = useState(false);
  const [formTeacherBlock, setFormTeacherBlock] = useState('');
  const [subjectValue, setSubjectValue] = useState("");
  const [teacherValue, setTeacherValue] = useState("");
  const [allSubjects, setAllSubjects] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [searchTeachers, setSearchTeachers] = useState([]);
  const [searchSubjects, setSearchSubjects] = useState([]);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [searchSubjectsDropdown, setSearchSubjectsDropdown] = useState(false);
  const { server1 } = server()
  const [hoverBackground, setHoverBackground] = useState("#fff");

  const [opacity, setOpacity] = useState(0);

  const teacherData = [];
  var subjectData = [];
  var temp = [];
  var subjectTemp=[]
  const handleMouseOver = {
    paddingLeft: 10,
    background: hoverBackground,
    cursor: "pointer",
    borderRadius: 5,
  };
  const handleFormTeacherSelect = (newTemp) => {
    setCurrentFormTeacher(newTemp._id);
    setFormTeacherValue('');
    setShowFormTeacherBlock(true)
    setFormTeacherBlock(
      `${newTemp.firstName} ${newTemp.middleName ? newTemp.middleName : newTemp.lastName} ${
        newTemp.middleName ? newTemp.lastName : ""
      }`
    )
    setSearchTeachers([]);
  };

  const closeAndRemoveFormTeacher= ()=>{
    setFormTeacherValue('')
    setShowFormTeacherBlock(false)
    setFormTeacherBlock('')
  }
  const handleSubjectSelect = (newTemp) => {
    
    setCurrentSubjects([...currentSubjects, newTemp._id]);
    setCurrentSubjectValues([...currentSubjectValues, newTemp]);
    setSubjectValue('')
    setSearchSubjects([]);
  };

  const removeSubject= (value)=>{
    var newSubjects= []
    var newCurrentSubjects= []
    for (let index = 0; index < currentSubjects.length; index++) {
      if(currentSubjects[index]!==value){
        newSubjects= [...newSubjects, currentSubjects[index]]
      }  
    }
    for (let index = 0; index < currentSubjectValues.length; index++) {
      if(currentSubjectValues[index]._id!==value){
        newCurrentSubjects= [...newCurrentSubjects, currentSubjectValues[index]]
      }  
    }
    setCurrentSubjects(newSubjects);
    setCurrentSubjectValues(newCurrentSubjects);
  }

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      listRef.current.focus();
    }
  };
  const handleSubjectChange= (newValue)=>{
    if (newValue == "") {
      subjectData=[];
      setSubjectValue('')
    } else {
      //allTeachers
     
      for (let index = 0; index < allSubjects.length; index++) {
        if(allSubjects[index].name){
          if(allSubjects[index].name.toLowerCase().includes(newValue)){
            subjectTemp = [...subjectTemp, allSubjects[index]];
          }
        } 
      }
      
      if (subjectTemp.length > 0) {
        subjectTemp.forEach((newTemp) => {
          subjectData.push(
            <div
              key={newTemp._id}
              style={handleMouseOver}
              onClick={() => {
                handleSubjectSelect(newTemp);
              }}
              onMouseEnter={setHoverBackground("#dddddd")}
              onMouseOut={setHoverBackground("#fff")}
            >
              {`${newTemp.name}`}
            </div>
          );
        });
      } else {
        subjectData.push(
          <div style={{ paddingLeft: 10, paddingBottom: 10 }}>No subject found</div>
        );
      }
      
    }
   
    setSearchSubjectsDropdown(true);
    setSearchSubjects(subjectData);
    setSubjectValue(newValue);
  }

  const selectedSubjects=[]
  const handleShowSelectedSubjects= ()=>{
   for (let index = 0; index < currentSubjectValues.length; index++) {
     selectedSubjects.push(
      <div style={{color: '#1E3F66', fontSize: 13, marginTop: 5}}>
        <span style={{marginRight: 20}}>{`${currentSubjectValues[index].name}`}</span>
        <button 
          style={{
            background: '#1E3F66', 
            color: 'white', 
            border: 'none', 
            padding: 3, 
            borderRadius: 4,
            cursor: 'pointer'
          }} 
          onClick={()=>{removeSubject(currentSubjectValues[index]._id)}}
        >
          remove
        </button>  
      </div>
     )
    
   }
   
   setSelectedSubjectsState(selectedSubjects)
  }








  //Updating Teacher Inputs
  const handleFormTeacherChange = (newValue) => {
    if (newValue == "") {
      setSearchTeachers([]);
    } else {
      
      for (let index = 0; index < allTeachers.length; index++) {
        var firstSecond = `${allTeachers[index].firstName} ${allTeachers[index].middleName}`;
        var firstLast = `${allTeachers[index].firstName} ${allTeachers[index].lastName}`;
        var lastFirst = `${allTeachers[index].lastName} ${allTeachers[index].firstName}`;
        var lastMiddle = `${allTeachers[index].lastName} ${allTeachers[index].middleName}`;
        var middleFirst = `${allTeachers[index].middleName} ${allTeachers[index].firstName}`;
        var middleLast = `${allTeachers[index].middleName} ${allTeachers[index].lastName}`;
        var allOrder = `${allTeachers[index].firstName} ${allTeachers[index].middleName} ${allTeachers[index].lastName}`;
        var firstMiddleLast = `${allTeachers[index].firstName} ${allTeachers[index].middleName} ${allTeachers[index].lastName}`;
        var firstLastMiddle = `${allTeachers[index].firstName} ${allTeachers[index].lastName} ${allTeachers[index].middleName}`;
        var middleFirstLast = `${allTeachers[index].middleName} ${allTeachers[index].firstName} ${allTeachers[index].lastName}`;
        var middleLastFirst = `${allTeachers[index].middleName} ${allTeachers[index].lastName} ${allTeachers[index].firstName}`;
        var lastFirstMiddle = `${allTeachers[index].lastName} ${allTeachers[index].firstName} ${allTeachers[index].middleName}`;
        var lastMiddleFirst = `${allTeachers[index].lastName} ${allTeachers[index].middleName} ${allTeachers[index].firstName}`;
        var first = allTeachers[index].firstName.includes(newValue);
        var middle = allTeachers[index].middleName.includes(newValue);
        var last = allTeachers[index].lastName.includes(newValue);
        var firstSecondCheck = firstSecond.toLowerCase().includes(newValue);
        var firstLastCheck = firstLast.toLowerCase().includes(newValue);
        var lastFirstCheck = lastFirst.toLowerCase().includes(newValue);
        var lastMiddleCheck = lastMiddle.toLowerCase().includes(newValue);
        var middleFirstCheck = middleFirst.toLowerCase().includes(newValue);
        var middleLastCheck = middleLast.toLowerCase().includes(newValue);
        var allOrderCheck = allOrder.toLowerCase().includes(newValue);
        var firstMiddleLastCheck = firstMiddleLast.toLowerCase().includes(newValue);
        var firstLastMiddleCheck = firstLastMiddle.toLowerCase().includes(newValue);
        var middleFirstLastCheck = middleFirstLast.toLowerCase().includes(newValue);
        var middleLastFirstCheck = middleLastFirst.toLowerCase().includes(newValue);
        var lastFirstMiddleCheck = lastFirstMiddle.toLowerCase().includes(newValue);
        var lastMiddleFirstCheck = lastMiddleFirst.toLowerCase().includes(newValue);

        if (
          first ||
          middle ||
          last ||
          firstSecondCheck ||
          firstLastMiddleCheck ||
          middleFirstLastCheck ||
          middleLastFirstCheck ||
          lastFirstMiddleCheck ||
          lastMiddleFirstCheck ||
          firstLastCheck ||
          lastFirstCheck ||
          lastMiddleCheck ||
          middleFirstCheck ||
          middleLastCheck ||
          allOrderCheck ||
          firstMiddleLastCheck
        ) {
          temp = [...temp, allTeachers[index]];
        }
      }

      if (temp.length > 0) {
        temp.forEach((newTemp) => {
          teacherData.push(
            <div
              key={newTemp._id}
              style={handleMouseOver}
              onClick={() => {
                handleFormTeacherSelect(newTemp);
              }}
              onMouseEnter={setHoverBackground("#dddddd")}
              onMouseOut={setHoverBackground("#fff")}
            >
              {`${newTemp.firstName} ${
                newTemp.middleName ? newTemp.middleName : newTemp.lastName
              } ${newTemp.middleName ? newTemp.lastName : ""} ${newTemp.staffNumber ? newTemp.staffNumber: ''}`}
            </div>
          );
        });
      } else {
        teacherData.push(
          <div style={{ paddingLeft: 10, paddingBottom: 10 }}>No teacher found</div>
        );
      }
    }

    
    setSearchDropdown(true);
    setSearchTeachers(teacherData);
    setFormTeacherValue(newValue);
  };
  const handleClassNameChange = (value) => {
    setClassNameValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitted({
      name: classNameValue,
      arm: "",
      teacher: currentFormTeacher,
      subjects: currentSubjects
    });
  };

  //Handling Submit
  const getTeachers = async () => {
    try {
      const response = await fetch(`${server1}/admins/get_all_teachers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "sds": cookie.sds_auth_token
        },
      });
      
      if (response.ok) {
        // User creation succeeded
        const responseData = await response.json();
        setAllTeachers(responseData.data);
        
      } else {
        // User creation failed
        formError('could not get teachers')
      }
    } catch (error) {
      formError('could not get teachers')
    }
  };

  const getSubjects = async () => {
    try {
      const response = await fetch(`${server1}/admins/get_all_subjects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "sds": cookie.sds_auth_token
        },
      });
      
      if (response.ok) {
        // User creation succeeded
        const responseData = await response.json();
        setAllSubjects(responseData.data);
        
      } else {
        // User creation failed
        formError('could not get subjects')
      }
    } catch (error) {
      formError('could not get subjects')
    }
  };

  useEffect(() => setOpacity(1), []);
  useEffect(() => {
    handleShowSelectedSubjects()
  
  }, [currentSubjectValues]);

  useEffect(() => {
    getTeachers();
    getSubjects();
    
  }, [addedTeachers]);

  useEffect(() => {
    console.log('success');
  }, [allTeachers]);

  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ width: "430px", maxWidth: "100%" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <div style={{ padding: "0px 10px", marginBottom: "6px" }} className="formHeaderRow">
          <MDTypography variant="h6">Create Class</MDTypography>
          <MDTypography onClick={cancelled}>
            <MDTypography onClick={cancelled}>
              <Icon style={{ cursor: "pointer" }}>cancel</Icon>
            </MDTypography>
          </MDTypography>
        </div>
        <form
          action=""
          method=""
          onSubmit={handleSubmit}
          className="form"
          style={{ paddingBottom: "15px" }}
        >
          <div className="rows">
            <MDInput
              type="text"
              value={classNameValue}
              onChange={(e) => handleClassNameChange(e.target.value)}
              label="Class Name"
              required
              fullWidth
            />
          </div>
{/* 
          <div className="rows" style={{ padding: "0px 0px 10px 20px" }}>
            <MDTypography variant="button">Select Subject</MDTypography>
            <select
              style={{
                width: "240px",
                padding: "8px 4px",
                border: "1px solid gray",
                outline: "none",
                cursor: "pointer",
              }}
              onChange={(e) => setSubjectValue(e.target.value)}
            >
              <option style={{ color: "#666" }}>Select Subject</option>
              {allSubjects.map((className) => {
                return <option value={className}>{className}</option>;
              })}
            </select>
            <MDTypography variant="button">Select Teacher</MDTypography>
            <select
              style={{
                width: "240px",
                padding: "8px 4px",
                border: "1px solid gray",
                outline: "none",
                cursor: "pointer",
              }}
              onChange={(e) => setTeacherValue(e.target.value)}
            >
              <option style={{ color: "#666" }}>Select Teacher</option>
              {allTeachers.map((teacher) => {
                return <option value={teacher}>{teacher}</option>;
              })}
            </select>
          </div> */}

          <button ref={formRef} type="submit" style={{ position: "absolute", zIndex: -1 }}></button> 
          {formTeacherFormDisplay && (
          <div className="rows" style={{ paddingTop: "12px" }}>
                {showFormTeacherBlock && (
                  <div style={{padding: 7,
                    fontSize: 13, 
                    position: 'absolute', 
                    background: 'blue', 
                    borderRadius: 20, 
                    color: 'white', 
                    zIndex: 2000
                  }}
                  >
                    <span style={{marginRight: 20}}>{formTeacherBlock}</span>
                    <span onClick={closeAndRemoveFormTeacher} 
                     style={{float: 'right', 
                     fontSize: 20,
                     marginTop: -6,
                     height: 12,
                     marginRight: 6,
                     cursor: 'pointer'
                  }}>×</span>
                  </div>
                )}
              <MDInput
                type={"text"}
                label="search teacher"
                value={formTeacherValue}
                multiline={false}
                rows={null}
                onChange={(e) => handleFormTeacherChange(e.target.value)}
                required={false}
                fullWidth
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />
              <div
                tabIndex={0}
                ref={listRef}
                style={{
                  outline: "none",
                  maxHeight: 500,
                  overflowY: "auto",
                  zIndex: 200,
                  background: "#ffffff",
                  fontSize: 14,
                  position: "absolute",
                  width: "91%",
                  marginTop: 90,
                  borderRadius: 5,
                }}
              >
                {temp.map((newTemp)=>{
                  return <div key={newTemp._id} className="yh" style={{position: 'absolute'}}>
                    {`${newTemp.firstName} ${newTemp.middleName?newTemp.middleName:newTemp.lastName} ${newTemp.middleName?newTemp.lastName:''}`}
                  </div>
                })}
                {searchTeachers}
              </div>
            </div>
          )}
           {classSubjectFormDisplay && (
            <div className="rows" style={{ paddingTop: "12px"}}>
              <div style={{width: '100%'}}>
              <div
                tabIndex={0}
                ref={listRef}
                style={{
                  outline: "none",
                  maxHeight: 500,
                  overflowY: "auto",
                  zIndex: 200,
                  background: "#ffffff",
                  fontSize: 14,
                  position: "absolute",
                  width: "91%",
                  marginTop: 45,
                  borderRadius: 5,
                }}
              >
                {/* {subjectTemp.map((newTemp)=>{
                  return <div key={newTemp._id} className="yh" style={{position: 'absolute'}}>
                     `${newTemp.name}`
                  </div>
                })} */}
                {searchSubjects}
              </div>
              <MDInput
                type={"text"}
                label="search subject"
                value={subjectValue}
                multiline={false}
                rows={null}
                onChange={(e) => handleSubjectChange(e.target.value)}
                required={false}
                fullWidth
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />
              </div>
              <div style={{color: '#702963', marginBottom: 50}}>
                <h6>Selected subjects</h6>
                {currentSubjectValues.map((subject)=>{
                  <div>
                    <div>{`${subject.name}`}</div>
                    <button onClick={()=>{removeSubject(subject._id)}}>remove</button>
                    
                  </div>
                })}
                {selectedSubjectsState}
              </div>
            </div>
          )}
        </form>
        <div className="rows" onClick={() => formRef.current.click()}>
          <MDButton
            type="submit"
            variant="contained"
            sx={{ mx: 1 }}
            color="dark"
            value={classNameValue}
            onChange={(e) => setClassNameValue(e.target.value)}
            fullWidth
          >
            <Icon>add</Icon>&nbsp;Submit
          </MDButton>
        </div>
      </Card>
    </div>
  );
}

ParentForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
  formError: PropTypes.func.isRequired,
};
