import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import { useState, useContext, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import Loading from "components/loadingComponent";
import { server } from "utils/server";





export default function ResultComponent({ resultData }) {
  const [child, setChild]= useState(null)

  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstTermState, setFirstTermState] = useState(false);
  const [secondTermState, setSecondTermState] = useState(false);
  const [thirdTermState, setThirdTermState] = useState(false);
  const [view, setView] = useState(false);
  const [resultMargin, setResultMargin] = useState(6);
  const [session, setSession] = useState();
  const { server1 } = server()

  
  
  const [otherStudents, setOtherStudents] = useState(null);
  const [pupilTotal, setPupilTotal] = useState(null);
  const [pupilAverage, setPupilAverage] = useState(null);
  const [totalStudents, setotalStudents] = useState(null);

  const traits = [
    "5. Excellent Degree of observable Traits",
    "4. High level of observable Traits",
    "3. Acceptable level of observable Traits",
    "2. Minimal level of observable Traits",
    "1. No regards for observable Traits",
  ];
 
  var sideHeaderRow= {
    sideHeader: true
  }

  var spanTwoRow= {
    spanTwo: true,
  }
  var xsRow= {
    xs: true,
  }
  var freeRow= {free: true}

  const getSession = async () => {
    
    const response = await fetch(`${server1}/admins/get_current_session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token
      },
    });
    const responseData = await response.json();
    if (response.ok) { 
      if(responseData.data.term==1){
        setFirstTermState(true)
      }
      if(responseData.data.term==2){
        setSecondTermState(true)
      }
      if(responseData.data.term==3){
        setThirdTermState(true)
      }
      setSession(responseData.data.session)
    } else {
      console.log('failed')
    }
  };


  useEffect(() => {
    getSession()
  }, [])




  useEffect(()=>{
    if(child){
        var childTotal=0
        var childAverage=0
        for (let index = 0; index < child.scores.length; index++) {
          var wholeScore= 0
          var student= child.scores[index]
          if(firstTermState){
            wholeScore= student.test1a + student.test1b + student.exam1
          }
          if(secondTermState){
            wholeScore= student.test2a + student.test2b + student.exam2
          }
          if(thirdTermState){
            wholeScore= student.test3a + student.test3b + student.exam3
          }
          childTotal+= wholeScore
      }
      childAverage= (childTotal/child.scores.length).toFixed(1)
      setPupilTotal(childTotal)
      setPupilAverage(childAverage)
    }
  }, [child])

  const positionArrange=(num)=>{
    var newNum= num.toString()
    var suffix= 'th'
    var resultString= newNum
    if(newNum[newNum.length-1]=='1' && newNum[newNum.length-2]=='1'){
      suffix= 'th'
    } else if(newNum[newNum.length-1]=='1' && newNum[newNum.length-2]!='1'){
      suffix= 'st'
    } else if(newNum[newNum.length-1]=='3' && newNum[newNum.length-2]!='1'){
      suffix= 'rd'
    } else if(newNum[newNum.length-1]=='2' && newNum[newNum.length-2]!='1'){
      suffix= 'nd'
    }
    return resultString+suffix
   }


  const displayScores= (subject)=>{

    var scoresViewArray= []
    var othersArray= []
    var total= 0
    for(let i=0;i<otherStudents.length;i++){
      for (let index = 0; index < otherStudents[i].scores.length; index++) {
        if(otherStudents[i].scores[index].subjectId==subject._id){
          var wholeScore= 0
          var oneOtherStudent= otherStudents[i].scores[index]
          if(firstTermState){
            wholeScore= oneOtherStudent.test1a + oneOtherStudent.test1b + oneOtherStudent.exam1
          }
          if(secondTermState){
            wholeScore= oneOtherStudent.test2a + oneOtherStudent.test2b + oneOtherStudent.exam2
          }
          if(thirdTermState){
            wholeScore= oneOtherStudent.test3a + oneOtherStudent.test3b + oneOtherStudent.exam3
          }
          total+= wholeScore
          othersArray= [...othersArray, wholeScore]
        }
      }  
    }
    othersArray.sort()
    othersArray.reverse()
    
    var classAverage= (total/othersArray.length).toFixed(1)
    
    var studentScore= 0
    for(let i=0;i<child.scores.length;i++){
      var wholeScore= 0
      var mainStudent= child.scores[i]
      if(firstTermState){
        wholeScore= mainStudent.test1a + mainStudent.test1b + mainStudent.exam1
      }
      if(secondTermState){
        wholeScore= mainStudent.test2a + mainStudent.test2b + mainStudent.exam2
      }
      if(thirdTermState){
        wholeScore= mainStudent.test3a + mainStudent.test3b + mainStudent.exam3
      }
  
        const subjectPositionFn = (element) => element == wholeScore;
        const classPosition= othersArray.findIndex(subjectPositionFn)
      if(child.scores[i].subjectId==subject._id){
        scoresViewArray.push(
          (
            <div
              style={{
                width: "100%",
                borderTop: "1px solid gray",
                borderLeft: "1px solid gray",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
            <div
              key={subject.name}
              style={{
                height: 50,
                borderRight: "1px solid gray",
                borderBottom: "1px solid gray",
                width: `${determineWidth(sideHeaderRow)}`,
                display: "flex",
                alignItems: "center",
                paddingLeft: "6px",
              }}
            >
              <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
                {subject.name}
              </MDTypography>
              {/* {row.checked && (
                <MDTypography variant="overline">
                  <Icon>check</Icon>
                </MDTypography>
              )} */}
          </div>
          <div
          key={'ca'}
          style={{
            height: 50,
            borderRight: "1px solid gray",
            borderBottom: "1px solid gray",
            width: `${determineWidth(freeRow)}`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "6px",
          }}
        >
          <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
            {firstTermState&&child.scores[i].test1a}
            {secondTermState&&child.scores[i].test2a}
            {thirdTermState&&child.scores[i].test3a}
          </MDTypography>
          {/* {row.checked && (
            <MDTypography variant="overline">
              <Icon>check</Icon>
            </MDTypography>
          )} */}
        </div>
          <div
          key={subject.name}
          style={{
            height: 50,
            borderRight: "1px solid gray",
            borderBottom: "1px solid gray",
            width: `${determineWidth(freeRow)}`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "6px",
          }}
        >
          <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
            {firstTermState&&child.scores[i].test1b}
            {secondTermState&&child.scores[i].test2b}
            {thirdTermState&&child.scores[i].test3b}
          </MDTypography>
          {/* {row.checked && (
            <MDTypography variant="overline">
       
       
       
            <Icon>check</Icon>
            </MDTypography>
          )} */}
      </div>
      <div
          key={subject.name}
          style={{
            height: 50,
            borderRight: "1px solid gray",
            borderBottom: "1px solid gray",
            width: `${determineWidth(freeRow)}`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "6px",
          }}
        >
          <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
            {firstTermState&&child.scores[i].exam1}
            {secondTermState&&child.scores[i].exam2}
            {thirdTermState&&child.scores[i].exam3}
          </MDTypography>
          {/* {row.checked && (
            <MDTypography variant="overline">
              <Icon>check</Icon>
            </MDTypography>
          )} */}
      </div>
      <div
          key={subject.name}
          style={{
            height: 50,
            borderRight: "1px solid gray",
            borderBottom: "1px solid gray",
            width: `${determineWidth(freeRow)}`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "6px",
          }}
        >
          <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
            {wholeScore}
          </MDTypography>
          {/* {row.checked && (
            <MDTypography variant="overline">
              <Icon>check</Icon>
            </MDTypography>
          )} */}
      </div>
      <div
          key={subject.name}
          style={{
            height: 50,
            borderRight: "1px solid gray",
            borderBottom: "1px solid gray",
            width: `${determineWidth(freeRow)}`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "6px",
          }}
        >
          <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
            {classAverage}
          </MDTypography>
          {/* {row.checked && (
            <MDTypography variant="overline">
              <Icon>check</Icon>
            </MDTypography>
          )} */}
      </div>
      <div
          key={subject.name}
          style={{
            height: 50,
            borderRight: "1px solid gray",
            borderBottom: "1px solid gray",
            width: `${determineWidth(freeRow)}`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "6px",
          }}
        >
          <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
            {/* {firstTermState&&child.scores[i].exam1}
            {secondTermState&&child.scores[i].ecam2}
            {thirdTermState&&child.scores[i].exam3} */}
            {wholeScore>=85?'A1':wholeScore>=75?'B2':wholeScore>=70?'B3':
            wholeScore>=60?'C4':wholeScore>=55?'c5':wholeScore>=50?'c6':
            wholeScore>=45?'D7':wholeScore>=40?'E8':'F9'
            }
          </MDTypography>
          {/* {row.checked && (
            <MDTypography variant="overline">
              <Icon>check</Icon>
            </MDTypography>
          )} */}
      </div>
      <div
          key={subject.name}
          style={{
            height: 50,
            borderRight: "1px solid gray",
            borderBottom: "1px solid gray",
            width: `${determineWidth(freeRow)}`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "6px",
          }}
        >
          <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
           {positionArrange(classPosition+1)}
          </MDTypography>
          {/* {row.checked && (
            <MDTypography variant="overline">
              <Icon>check</Icon>
            </MDTypography>
          )} */}
      </div>
      <div
          key={subject.name}
          style={{
            height: 50,
            borderRight: "1px solid gray",
            borderBottom: "1px solid gray",
            width: `${determineWidth(freeRow)}`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "6px",
          }}
        >
          <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
          {wholeScore>=85?'Excellent':wholeScore>=75?'Very Good':wholeScore>=70?'Good':
            wholeScore>=60?'Upper Credit':wholeScore>=55?'Medium Credit':wholeScore>=50?'Lower Credit':
            wholeScore>=45?'Pass':wholeScore>=40?'Poor':'Fail'
            }
          </MDTypography>
          {/* {row.checked && (
            <MDTypography variant="overline">
              <Icon>check</Icon>
            </MDTypography>
          )} */}
      </div>
      </div>
          )
        )
      }

    }
  return  scoresViewArray
  }

  const getStudent = async () => {
    
    setIsLoading(true);
    const response = await fetch(`${server1}/parent/get_child/${resultData}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    console.log(responseData.classStudents)
    if (response.ok) {
      //Filter the response.data to get the parent's children
     
      setChild(responseData.data);
      setOtherStudents(responseData.classStudents)
      setIsLoading(false)
      
    } else {
      const errorData = await response.json();
      //console.error("User creation failed:", errorData.error);
    }
  };

  useEffect(()=>{
    if(window.innerWidth>400){
      setResultMargin(0)
    }
    window.addEventListener('resize', ()=>{
      if(window.innerWidth<400){
        setResultMargin(0)
      }
      if(window.innerWidth>400){
        setResultMargin(6)
      }
    })
    getStudent();
  }, [])

  useEffect(()=>{
    if(child){
      if(firstTermState && child.class.first_published){
        setView(true)
      }
      if(secondTermState && child.class.second_published){
        setView(true)
      }
      if(thirdTermState && child.class.third_published){
        setView(true)
      }
    }
   
  }, [child])


  const determineWidth = (row) => {
    if (row.sideHeader) {
      return `${124 * 2 - 10}px`;
    }
    if (row.sideHeader) {
      return `${108 * 2 - 10}px`;
    }
    if (row.spanTwo) {
      return `${64 * 4 - 20}px`;
    }
    if (row.ratingPart) {
      return `${64 * 2 - 10}px`;
    }
    if (row.xs) {
      return "23.6px";
    }
    if (!row.sideHeader && !row.spanTwo && !row.ratingPart && !row.xs) {
      return `${64 + 3.75}px`;
    }
  };
  const renderInformation = (key, info) => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          borderBottom: "1px solid gray",
          paddingLeft: "8px",
        }}
      >
        <MDTypography variant="button" fontWeight="medium">
          {key}&nbsp;&nbsp;&nbsp; {info}
        </MDTypography>
      </div>
    );
  };
  return (
    <Card
      className="child-result"
      sx={{
        width: "820px",
        maxWidth: "100%",
        mt: 1,
        p: 2,
      }}
    >
      {isLoading && <Loading/>}
      {!view && <div>Results not ready</div>}
      {child && view &&
      <div className="resultBox">
        <div className="resultLayer">
          <MDBox width="100%" display="flex" gap="100px">
            <MDBox display="flex" gap="60px">
              <MDTypography width='max-content' variant="button" fontWeight="medium">
                Name:&nbsp;&nbsp;{`${child.user.firstName} ${child.user.middleName} ${child.user.lastName}`}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" gap="60px">
              <MDTypography variant="button" fontWeight="medium">
                Sex:&nbsp;&nbsp;{child.user.gender??'N/A'}
              </MDTypography>
              <MDTypography width='200px' variant="button" fontWeight="medium">
                Term:&nbsp;&nbsp;{firstTermState?'First Term':secondTermState?'Second Term':'Third Term'}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox width="100%" display="flex" gap="200px">
            <MDBox display="flex" gap="60px">
              <MDTypography width='max-content' variant="button" fontWeight="medium">
                Class:&nbsp;&nbsp;{child.class.className}
              </MDTypography>
              <MDTypography variant="button" fontWeight="medium">
                Year/Session:&nbsp;&nbsp;{session && `${session}/${session+1}`}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
            <MDTypography width='100px' variant="button" fontWeight="medium">
              No In Class:&nbsp;&nbsp;{child.classNumber}
            </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox width="100%">
            <div
              style={{
                width: "781px",
                borderTop: "1px solid gray",
                borderLeft: "1px solid gray",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {/* var temp= {
    user: currentChild,
    Student: currentStudent,
    scores: childScores,
    class: classDetail,
    subjects: allSubjects
  } */}
      <div
        key={'SUBJECT NAME'}
        style={{
          height: 50,
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          width: `${determineWidth(sideHeaderRow)}`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
          SUBJECT NAME
        </MDTypography>
        {/* {row.checked && (
          <MDTypography variant="overline">
            <Icon>check</Icon>
          </MDTypography>
        )} */}
      </div>
      <div
        key={'CA 20%'}
        style={{
          height: 50,
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          width: `${determineWidth(freeRow)}`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
          CA 20%
        </MDTypography>
        {/* {row.checked && (
          <MDTypography variant="overline">
            <Icon>check</Icon>
          </MDTypography>
        )} */}
      </div>
      <div
        key={'MID TERM 20%'}
        style={{
          height: 50,
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          width: `${determineWidth(freeRow)}`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
          MID TERM 20%
        </MDTypography>
        {/* {row.checked && (
          <MDTypography variant="overline">
            <Icon>check</Icon>
          </MDTypography>
        )} */}
      </div>
      <div
        key={'EXAM 50%'}
        style={{
          height: 50,
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          width: `${determineWidth(freeRow)}`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
          EXAM 60%
        </MDTypography>
        {/* {row.checked && (
          <MDTypography variant="overline">
            <Icon>check</Icon>
          </MDTypography>
        )} */}
      </div>
      <div
        key={'TOTAL 100%'}
        style={{
          height: 50,
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          width: `${determineWidth(freeRow)}`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
          TOTAL 100%
        </MDTypography>
        {/* {row.checked && (
          <MDTypography variant="overline">
            <Icon>check</Icon>
          </MDTypography>
        )} */}
      </div>
      <div
        key={'SUBJECT AVERAGE'}
        style={{
          height: 50,
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          width: `${determineWidth(freeRow)}`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
          SUBJECT AVERAGE
        </MDTypography>
        {/* {row.checked && (
          <MDTypography variant="overline">
            <Icon>check</Icon>
          </MDTypography>
        )} */}
      </div>
      <div
        key={'GRADE'}
        style={{
          height: 50,
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          width: `${determineWidth(freeRow)}`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
          GRADE
        </MDTypography>
        {/* {row.checked && (
          <MDTypography variant="overline">
            <Icon>check</Icon>
          </MDTypography>
        )} */}
      </div>
      <div
        key={'SUBJECT POSITION'}
        style={{
          height: 50,
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          width: `${determineWidth(freeRow)}`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
        SUBJECT POSITION
        </MDTypography>
        {/* {row.checked && (
          <MDTypography variant="overline">
            <Icon>check</Icon>
          </MDTypography>
        )} */}
      </div>
      <div
        key={'REMARK'}
        style={{
          height: 50,
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          width: `${determineWidth(freeRow)}`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <MDTypography variant="overline" fontWeight="medium" lineHeight="15px">
        REMARK
        </MDTypography>
        {/* {row.checked && (
          <MDTypography variant="overline">
            <Icon>check</Icon>
          </MDTypography>
        )} */}
      </div>
      </div>
              {child.subjects.map((row) => {
               return (
                <div
                
                >
                  {displayScores(row)}  
                </div>
                );
              })}
              {/* <div
                style={{
                  width: "calc(100% - 1px)",
                  height: "40px",
                  borderBottom: "1px solid gray",
                  borderRight: "1px solid gray",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <MDBox
                  height="40px"
                  width="167px"
                  bgColor="dark"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingLeft="15px"
                >
                  <MDTypography
                    variant="overline"
                    fontWeight="medium"
                    lineHeight="15px"
                    color="light"
                  >
                    RATINGS
                  </MDTypography>
                  
                </MDBox>
              </div> 
              <div
                style={{
                  width: "calc(100% - 1px)",
                  height: "270px",
                  borderBottom: "1px solid gray",
                  borderRight: "1px solid gray",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "calc(100% - 167px)",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "50%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {renderInformation("No Of Students:", child.classNumber)}
                    {renderInformation("Pupils Total Scores:", 919)}
                    {renderInformation("Pupils Average:", "80%")}
                  </div>
                </div>
                <div
                  style={{
                    width: "167px",
                    height: "100%",
                    borderLeft: "1px solid gray",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "20px",
                    paddingLeft: "8px",
                    gap: "4px",
                  }}
                >
                  {/* {traits.map((trait) => (
                    <MDTypography variant="button" fontWeight="medium" key={trait}>
                      {trait}
                    </MDTypography>
                  ))} */}
                {/* </div> */}
              {/* </div> */}
             































              <div
                style={{
                  width: "calc(100% - 7px)",
                  height: "100px",
                  borderBottom: "1px solid gray",
                  borderRight: "1px solid gray",
                  borderLeft: "1px solid gray",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "calc(100%)",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "50%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {renderInformation("No Of Students:", child.classNumber)}
                    {renderInformation("Pupil's Total Scores:", pupilTotal)}
                    {renderInformation("Pupil's Average:", pupilAverage)}
                  </div>
                </div>
                {/* <div
                  style={{
                    width: "167px",
                    height: "100%",
                    borderLeft: "1px solid gray",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "20px",
                    paddingLeft: "8px",
                    gap: "4px",
                  }}
                > */}
                  {/* {traits.map((trait) => (
                    <MDTypography variant="button" fontWeight="medium" key={trait}>
                      {trait}
                    </MDTypography>
                  ))} */}
                {/* </div> */}
              </div>































             
          </MDBox>
        </div>
      </div> }
    </Card>
  );
}

ResultComponent.propTypes = {
  resultData: PropTypes.object.isRequired,
};
