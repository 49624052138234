//Dummy Data
import { useState, useContext, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import Card from "@mui/material/Card";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import BroadSheet from "./broadSheet";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loading from "components/loadingComponent";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import Footer from "examples/Footer";
import { LoginContext } from "../../App";
import { server } from "utils/server";


export default function ManageResultSubmission() {
 // const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [isClicked, setIsClicked] = useState(0);
  const [adminState, setAdminState] = useState(false);
  const [teacherState, setTeacherState] = useState(true);
  const [staffState, setStaffState] = useState(false);
  const [parentState, setParentState] = useState(false);
  const [pupilState, setPupilState] = useState(false);
  const [pupilData, setPupilData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [userState, setUserState] = useState(true);
  const [generalEndState, setGeneralEndState] = useState(true);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [skills, setSkills] = useState([]);
  const [firstTermState, setFirstTermState] = useState(false);
  const [secondTermState, setSecondTermState] = useState(false); 
  const [thirdTermState, setThirdTermState] = useState(false); 
  const [pages, setPages] = useState(1); 
  const [pagination, setPagination] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [goBackEnabled, setGoBackEnabled] = useState(false); 
  const [goFrontEnabled, setGoFrontEnabled] = useState(false); 
 
  const [broadsheetRendering, setBroadsheetRendering] = useState([]);
  const [renderSheets, setRenderSheets] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [renderCreate, setRenderCreate] = useState(false);
  const [paginationCounter, setPaginationCounter] = useState(1);
  
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [num, setNum] = useState(0);
  const navigation = useNavigate();
  const [done, setDone] = useState(false);
  const { server1 } = server()
  var isAdmin= true

  const getSession = async () => {
    
    const response = await fetch(`${server1}/admins/get_current_session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token
      },
    });
    const responseData = await response.json();
    if (response.ok) { 
      if(responseData.data.term==1){
        setFirstTermState(true)
      }
      if(responseData.data.term==2){
        setSecondTermState(true)
      }
      if(responseData.data.term==3){
        setThirdTermState(true)
      }
      
    } else {
      console.log('failed')
    }
  };


  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
    getSession()
    //console.log(cookie.sds_auth_token)
  }, []);



  const handleCounter= (index)=>{
    setPaginationCounter(index)
    showPagination(index)  
  }


  const handlePagination= (index)=>{
    setCurrentPage(index)
    setIsLoading(true)
    getClassResults(index).then(()=>{
      setIsLoading(false)
    })
  }


  const getClassResults = async (searchPage) => {
    // console.log(isLoggedIn);
    
     const response = await fetch(`${server1}/admins/get_all_class_scores/${searchPage}`, {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
         "sds": cookie.sds_auth_token
       },
     });
     const responseData = await response.json();
     if (response.ok) {
       setPages(responseData.pages)
       setStudents(responseData.data)
       setSubjects(responseData.subjects)
       setClasses(responseData.classes)
       setSkills(responseData.skills)  
     } else {
       console.log('success')
     }
   };

   const showPagination= (counter)=>{
    var paginationArray= []
    if(counter==1){
      setGoBackEnabled(false)
    } else {
      setGoBackEnabled(true)
    }
    var pagePage= Math.ceil(pages/5)
    if(counter==pagePage){
      setGoFrontEnabled(false)
    } else {
      setGoFrontEnabled(true)
    }
    var start= (counter * 5)-5
    var stop= counter * 5
    if(pages<counter * 5){
      var stop= pages
    }
    
    for (let index = start; index < stop; index++) {
      if(index+1==currentPage){
        paginationArray.push(
          <div style={{fontSize: 13, margin: 5}}>{index+1}</div>
        )
      } else {
        paginationArray.push(
          <button
            style={{borderColor: '#344767', color: '#344767',  borderStyle: 'solid', width: 30, height: 30, margin: 3, cursor: 'pointer'}} 
            onClick={()=>{handlePagination(index+1)}}
          >
            {index+1}
          </button>
        )
      }
      
    }
    
    
    setPagination(paginationArray)
   }
   

  const broadsheetsArray= []
  const renderBroadSheets= (classesData, studentsData, subjectsData)=>{
   
    // forCreate={renderCreate}
    for (let index = 0; index < classesData.length; index++) {

      var temp= {
        studentArr: studentsData[index],
        class: classesData[index],
        subjects: subjectsData[index],
        skills: skills,
      }
      if(firstTermState && classesData[index].first_locked || 
        secondTermState && classesData[index].second_locked ||
        thirdTermState && classesData[index].third_locked){
          broadsheetsArray.push(
            ( <div style={{marginBottom: 40}}>
            <BroadSheet data={temp} key={num} getResults={getClassResults} thisPage={currentPage} counter={index} showLoad={(par)=>{setIsLoading(par)}} reload={(num)=>{setNum(num)}} isClicked={isClicked} />
            </div>) 
           )
      } else {
         broadsheetsArray.push(
          <div style={{marginBottom: 40}}>
            <MDTypography variant="h6">
            {`${classesData[index].className.toUpperCase()} ${firstTermState?'FIRST TERM':secondTermState?'SECOND TERM':'THIRD TERM'}`}
            </MDTypography>
            <Card
              sx={{
                width: "100%",
                mt: 3,
                p: 2,
              }}

            >
            <MDTypography variant="h6">
              Results for this class not submitted yet
            </MDTypography>
            </Card>
          </div>
         )
      }
      
      
    }

    var paginationArray= []
    if(paginationCounter==1){
      setGoBackEnabled(false)
    } else {
      setGoBackEnabled(true)
    }
    var start= (paginationCounter * 5)-5
    var stop= paginationCounter * 5
    if(pages<paginationCounter * 5){
      var stop= pages
    }
    
    for (let index = start; index < stop; index++) {
      if(index+1==currentPage){
        paginationArray.push(
          <div style={{fontSize: 14, margin: 7}}>{index+1}</div>
        )
      } else {
        paginationArray.push(
          <button
            style={{borderColor: '#344767', color: '#344767',  borderStyle: 'solid', width: 30, height: 30, margin: 3, cursor: 'pointer'}} 
            onClick={()=>{handlePagination(index+1)}}
          >
            {index+1}
          </button>
        )
      }
    }
    var pagePage= pages/5
    if(paginationCounter==pagePage){
      setGoFrontEnabled(false)
    } else {
      setGoFrontEnabled(true)
    }
    
    setPagination(paginationArray)
    setBroadsheetRendering(broadsheetsArray)
    setRenderSheets(true)
    
  }

useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
    setIsLoading(true)
    getClassResults(currentPage).then(()=>{
      setIsLoading(false)
    })
    console.log('gotten')  
  //checkLogged();
}, []);

// useEffect(() => {
//   if(done){
//     setIsLoading(false)
//   }
// }, [done]);


useEffect(() => {
  getClassResults(currentPage).then(()=>{
    setIsLoading(false)
  })
}, [num]);


useEffect(() => {
  
  renderBroadSheets(classes, students, subjects)
//checkLogged();
}, [classes]);




  return (
    <DashboardLayout>
      {isLoading && <Loading />}
        {alertDisplay && (
          <div
            style={{
              background: alertType === "error" ? "#d31a38" : "green",
              width: 350,
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}
      <DashboardNavbar />
      <MDTypography variant="h6">Class Broadsheets</MDTypography>
      <MDBox paddingTop="15px" display="flex" flexDirection="column" gap="15px">
        {renderSheets && broadsheetRendering}
      </MDBox>
      {pagination && <div style={{display: 'flex', marginTop: 20}}>
        {goBackEnabled && <button style={{borderColor: '#344767', borderStyle: 'solid', width: 30, height: 30, cursor: 'pointer', margin: 3, cursor: 'pointer'}} onClick={()=>{handleCounter(paginationCounter-1)}}>
            {'<<'}
          </button>
        }
        {!goBackEnabled && <button disabled style={{borderColor: 'grey', borderStyle: 'solid', width: 30, height: 30, color: 'grey', margin: 3}} >
          {'<<'}
        </button>
        }
        {pagination && pagination}
        {goFrontEnabled && <button style={{borderColor: '#344767', borderStyle: 'solid', width: 30, height: 30, margin: 3, cursor: 'pointer' }} onClick={()=>{handleCounter(paginationCounter+1)}}>
          {'>>'}
        </button>
        }
        {!goFrontEnabled && <button disabled style={{borderColor: 'grey', borderStyle: 'solid', width: 30, height: 30, margin: 3}}>
          {'>>'}
        </button>
        }
      </div>}
      <div style={{textAlign: 'center'}}>page {currentPage}</div>
    </DashboardLayout>
  );
}


