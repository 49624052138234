//Dummy Data
import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import Card from "@mui/material/Card";
import Select from "react-select";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import BroadSheet from "./broadSheet";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loading from "components/loadingComponent";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import Footer from "examples/Footer";
import { LoginContext } from "../../App";
import { server } from "utils/server";

export default function TeacherSubjectScores() {
  // const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [isClicked, setIsClicked] = useState(0);
  const [adminState, setAdminState] = useState(false);
  const [teacherState, setTeacherState] = useState(true);
  const [staffState, setStaffState] = useState(false);
  const [parentState, setParentState] = useState(false);
  const [pupilState, setPupilState] = useState(false);
  const [pupilData, setPupilData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [userState, setUserState] = useState(true);
  const [generalEndState, setGeneralEndState] = useState(true);
  const [classes, setClasses] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [subjectsForSelect, setSubjectsForSelect] = useState([]);
  const [currentSubject, setCurrentSubject] = useState(null);
  const [broadsheetData, setBroadsheetData] = useState(null);
  const [classesForSelect, setClassesForSelect] = useState([]);
  const [skills, setSkills] = useState([]);
  const [firstTermState, setFirstTermState] = useState(false);
  const [secondTermState, setSecondTermState] = useState(false);
  const [thirdTermState, setThirdTermState] = useState(false);
  const [broadsheetRendering, setBroadsheetRendering] = useState([]);
  const [renderSheets, setRenderSheets] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [renderCreate, setRenderCreate] = useState(false);
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [num, setNum] = useState(0);
  const navigation = useNavigate();
  const [done, setDone] = useState(false);
  const { server1 } = server();
  var isAdmin = true;
  const [classSelect, setClassSelect] = useState([]);

  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
  }, []);

  const getClassResults = async () => {
    const response = await fetch(`${server1}/admins/get_teacher_subjects_scores`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      setStudents(responseData.data);
      setSubjects(responseData.subjectAndClass);
      setClasses(responseData.subjectAndClass);
    } else {
      console.log("success");
    }
  };

  const getSubjectForBroadSheet = (id) => {
    setCurrentSubject(id);
  };

  const getSession = async () => {
    const response = await fetch(`${server1}/admins/get_current_session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      if (responseData.data) {
        if (responseData.data.term == 1) {
          setFirstTermState(true);
        }
        if (responseData.data.term == 2) {
          setSecondTermState(true);
        }
        if (responseData.data.term == 3) {
          setThirdTermState(true);
        }
      }
    } else {
      console.log("failed");
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  const getNewClass = async () => {
    try {
      if (!currentSubject) {
        setIsLoading(false);
        setAlertDisplay(true);
        setAlertMessage("Select a subject to view scores");
        setAlertType("error");
      } else {
        setIsLoading(true);
        const response = await fetch(`${server1}/teacher/get_new_class/${currentSubject}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            sds: cookie.sds_auth_token,
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          setStudents(responseData.data);
          setSubjects(responseData.subjectAndClass);
          setClasses(responseData.subjectAndClass);
          var temp = {
            studentArr: responseData.data,
            class: responseData.subjectAndClass,
            subjects: responseData.subjectAndClass,
            skills: skills,
          };
          setBroadsheetData(temp);
          setNum(num + 2);
          setIsLoading(false);
        } else {
          console.log("failed");
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setAlertDisplay(true);
      setAlertMessage("Something when wrong Select subject to view");
      setAlertType(error.message);
    }
    // console.log(isLoggedIn);
  };

  const getClasses = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${server1}/admins/get_teacher_classes`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        if (!responseData.error) {
          setStudents(responseData.data);
          setSubjects(responseData.subjectAndClass);
          setClasses(responseData.subjectAndClass);
          setSubjectsForSelect(responseData.subjects);
          setClassSelect(
            responseData.subjects.map((item) => ({
              label: item.className,
              value: item.className,
              id: item._id,
            }))
          );
          console.log(responseData);
          await setCurrentSubject(responseData.subjects[0]._id);
          var temp = {
            studentArr: responseData.data,
            class: responseData.subjectAndClass,
            subjects: responseData.subjectAndClass,
            skills: skills,
          };
          setBroadsheetData(temp);
        }
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(isLoggedIn);

    // else {
    //  console.log('no data')
    //  setIsLoading(false)
    // }
    // console.log('no data1')
    // setIsLoading(false)
  };

  const renderBroadSheets = (classesData, studentsData, subjectsData) => {
    const broadsheetsArray = [];
    if (classesData == null || studentsData === null || subjectsData === null) {
      return;
    }

    // forCreate={renderCreate}
    for (let index = 0; index < classesData.length; index++) {
      var temp = {
        studentArr: studentsData[index],
        class: classesData[index],
        subjects: subjectsData[index],
        skills: skills,
      };

      broadsheetsArray.push(
        <BroadSheet
          data={temp}
          key={num}
          counter={index}
          showLoad={(par) => {
            setIsLoading(par);
          }}
          reload={(num) => {
            setNum(num);
          }}
          isClicked={isClicked}
        />
      );

      // if(firstTermState && classesData[index].class.first_locked ||
      //   secondTermState && classesData[index].class.second_locked ||
      //   thirdTermState && classesData[index].class.third_locked){
      //     broadsheetsArray.push(
      //       ( <BroadSheet data={temp} key={num} counter={index} showLoad={(par)=>{setIsLoading(par)}} reload={(num)=>{setNum(num)}} isClicked={isClicked} />)
      //      )
      // } else {
      //   classes && broadsheetsArray.push(
      //     <>
      //       <MDTypography variant="h6">
      //       {`${classesData[index].className.toUpperCase()} ${firstTermState?'FIRST TERM':secondTermState?'SECOND TERM':'THIRD TERM'}`}
      //       </MDTypography>
      //       <Card
      //         sx={{
      //           width: "100%",
      //           mt: 3,
      //           p: 2,
      //         }}

      //       >
      //       <MDTypography variant="h6">
      //         Results for this class not submitted yet
      //       </MDTypography>
      //       </Card>
      //     </>
      //    )
      // }
    }

    setBroadsheetRendering(broadsheetsArray);
    setRenderSheets(true);
  };

  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
    // getClassResults().then(()=>{
    //   setIsLoading(false)
    // })
    getClasses().then(() => {
      setIsLoading(false);
    });
    // getClasses()
    // setIsLoading(false)
    console.log("gotten");
    //console.log(cookie.sds_auth_token)
    //checkLogged();
  }, []);

  // useEffect(() => {
  //   if(done){
  //     setIsLoading(false)
  //   }
  // }, [done]);

  useEffect(() => {
    getClasses().then(() => {
      setIsLoading(false);
    });
    // getClassResults().then(()=>{
    //   setIsLoading(false)
    // })
  }, [num]);

  // useEffect(() => {
  //   renderBroadSheets(classes, students, subjects)
  // }, [classes]);

  return (
    <DashboardLayout>
      {isLoading && <Loading />}
      {alertDisplay && (
        <div
          style={{
            background: alertType === "error" ? "#d31a38" : "green",
            width: 350,
            position: "absolute",
            zIndex: 10,
            right: 40,
            color: "white",
            borderRadius: 20,
          }}
        >
          <div
            style={{
              height: 40,
              borderRadius: "50%",
              float: "right",
              marginRight: 18,
              cursor: "pointer",
              marginTop: 2,
            }}
            onClick={() => setAlertDisplay(false)}
          >
            ×
          </div>
          <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
        </div>
      )}
      <DashboardNavbar />

      <MDTypography variant="h6">Subject Results</MDTypography>
      {broadsheetData && (
        <div>
          <div>Select Class subject</div>
          <div style={{ display: "flex" }}>
            <div>
              <select
                style={{ padding: 5 }}
                onChange={(e) => {
                  console.log(e.target);
                  setCurrentSubject(e.target.value);
                }}
                value={currentSubject}
              >
                {subjectsForSelect &&
                  subjectsForSelect.map((subject) => {
                    return (
                      <option value={subject._id}>
                        {subject.subjectName} {subject.className}
                      </option>
                    );
                  })}
              </select>
            </div>
            <button
              style={{
                height: 30,
                marginLeft: 20,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 2,
              }}
              onClick={getNewClass}
            >
              view
            </button>
          </div>
        </div>
      )}
      <MDBox paddingTop="15px" display="flex" flexDirection="column" gap="15px">
        {/* {broadsheetRendering} */}
        {broadsheetData && (
          <BroadSheet
            data={broadsheetData}
            key={num}
            counter={0}
            broadsheetSubject={getSubjectForBroadSheet}
            getClass={getNewClass}
            showLoad={(par) => {
              setIsLoading(par);
            }}
            reload={(num) => {
              setNum(num);
            }}
            isClicked={isClicked}
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}
