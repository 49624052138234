/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useContext, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import EnrollmentDetails from "./components/EnrollmentDetails";
import { LoginContext } from "../../App";
import { server } from "utils/server";

function Overview() {
  const [userDetails, setUserDetails] = useState({
    fullName: "Fowgate Support Team",
    mobile: "N/A",
    email: "N/A",
    location: "N/A",
    description: "Hi, I'm the system administrator for Sun Dream International",
    gender: "N/A",
    dateOfBirth: "N/A",
    studentID: "0000000",
    admissionDate: "N/A",
    gradeLevel: "N/A",
  });
  const [adminState, setAdminState] = useState(false);
  const [teacherState, setTeacherState] = useState(false);
  const [staffState, setStaffState] = useState(false);
  const [parentState, setParentState] = useState(false);
  const [pupilState, setPupilState] = useState(false);
  const [user, setUser] = useState(null);
  const { server1 } = server()
 // const [isLoggedIn, setIsLoggedIn]= useContext(LoginContext)
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const navigation= useNavigate()

  const getUser = async () => {
    const response = await fetch(`${server1}/user/user_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setUser(responseData.data);
    } else {
      // User creation failed
      const errorData = await response.json();
      console.error("User creation failed:", errorData.error);
    }
  };
  useEffect(() => {
    if(user){
      if(user.student){
        setPupilState(true)
      }
      if(user.admin){
        setAdminState(true)
      }
      if(user.parent){
        setParentState(true)
        setStaffState(true)
      }
      if(user.teacher){
        setTeacherState(true)
        setStaffState(true)
      }
    }
  }, [user]);

  useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
    getUser()
    
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {user && 
      <Header info={{fullName: `${user.user.firstName} ${user.user.middleName} ${user.user.lastName}`, 
        type: parentState?'parent':pupilState?'student':'staff' 
      }}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {
              /* Display Enrollment details is the user is a pupil */ pupilState && (
                <Grid item xs={12} md={6} xl={4}>
                  <EnrollmentDetails
                    studentID={{'admission number': user.student.admissionNumber}}
                    // admissionDate={userDetails.admissionDate}
                    gradeLevel={{class: user.student.class}}
                  />
                </Grid>
              )
            }

            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description={pupilState?'student':parentState?'parent':'staff'}
                info={{
                  fullName: `${user.user.firstName} ${user.user.middleName} ${user.user.lastName}`,
                  gender: user.user.gender,
                  Date_Of_Birth: user.user.dateOfBirth,
                  mobile: user.user.phone,
                  email: user.user.email,
                  location: user.user.location,
                }}
                social={[
                  {
                    link: "/profile",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "/profile",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "/profile",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      }
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
