import PropTypes from "prop-types";

import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";

//Datas
import { initialAdminData } from "../data";

import { useState, useEffect } from "react";

export default function AdminForm({ submitted, cancelled }) {
  const [opacity, setOpacity] = useState(0);
  const [classValue, setClassValue] = useState("");
  const [classArmValue, setClassArmValue] = useState("");
  const [adminForm, setAdminForm] = useState(initialAdminData);

  //Updating Inputs
  const handleAdminFormChange = (id, newValue) => {
    setAdminForm((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item))
    );
   
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    const data = { class: classValue /*classArm: classArmValue */ };

    adminForm.forEach((value) => {
      data[value.apiLabel] = value.value;
    });
    

    submitted(data);
  };

  useEffect(() => {
    setOpacity(1);
    setAdminForm(initialAdminData);
  }, []);
  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ maxWidth: "530px" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <div className="formHeaderRow" style={{ padding: "0px 10px 6px 10px" }}>
          <MDTypography variant="h6">Create Admin</MDTypography>
          <Icon style={{ cursor: "pointer" }} onClick={cancelled}>
            cancel
          </Icon>
        </div>
        <form className="form" action="" method="post" onSubmit={handleSubmit}>
          <div className="rows">
            {adminForm.map((data) => {
              return (
                <>
                  {data.type !== "file" && data.type !== "date" && (
                    <div style={{ width: `${data.fullWidth ? "100%" : "240px"}` }}>
                      <MDInput
                        key={data.id}
                        type={data.type}
                        label={data.label}
                        value={data.value}
                        required={data.required}
                        onChange={(e) => handleAdminFormChange(data.id, e.target.value)}
                        disabled={data.disable}
                        fullWidth
                      />
                    </div>
                  )}

                  {data.type === "file" && (
                    <div key={data.id} style={{ paddingLeft: "40px" }} className="rows">
                      <MDTypography variant="button" display="flex" alignItems="center">
                        <Icon>upload</Icon>&nbsp; {data.label}
                      </MDTypography>
                      <div style={{ width: "240px" }}>
                        <input
                          type="file"
                          onChange={(e) => handleAdminFormChange(data.id, e.target.files[0])}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  )}
                  {data.type === "date" && (
                    <div style={{ width: "100%" }}>
                      <MDTypography variant="button">Date Of Birth</MDTypography>
                      <MDInput
                        key={data.id}
                        type={data.type}
                        label={data.label}
                        value={data.value}
                        onChange={(e) => handleAdminFormChange(data.id, e.target.value)}
                        fullWidth
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
          <div className="rows">
            <MDButton type="submit" variant="contained" color="dark" fullWidth>
              <Icon>add</Icon>
              Add Admin
            </MDButton>
          </div>
        </form>
      </Card>
    </div>
  );
}

AdminForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
};
