import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";

import PropTypes from "prop-types";

//FormDatas
import { formTeacherData, guarantorsData } from "layouts/settings/data";

import { useState, useEffect } from "react";

export default function FormTeacherForm({ submitted, cancelled, allClassesSubjects }) {
  const [opacity, setOpacity] = useState(0);
  const [classValue, setClassValue] = useState("");
  const [teacherForm, setTeacherForm] = useState(formTeacherData);
  const [FirstGuarantorsForm, setFirstGuarantorsForm] = useState(guarantorsData[0]);
  const [SecondGuarantorsForm, setSecondGuarantorsForm] = useState(guarantorsData[1]);
  const [passwordValue, setPasswordValue] = useState('');
  const [usernameValue, setUsernameValue] = useState('');
  const [subjectsForSelection, setSubjectsForSelection] = useState([]);
  const [newSubjectForAddition, setNewSubjectForAddition] = useState(null);
  const [subjectsForAddition, setSubjectsForAddition] = useState([]);
  const [subjectsForRemoval, setSubjectsForRemoval] = useState([]);

  const handleTeacherFormChange = (id, newValue) => {
    setTeacherForm((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item))
    );
  };

  const handleSelectSubject= (index)=>{
    setNewSubjectForAddition(subjectsForSelection[index])
  }
  const handleAddSelectedSubject= ()=>{
    if(newSubjectForAddition != null){
      var temp= [...subjectsForAddition, newSubjectForAddition]
      setSubjectsForAddition(temp)
    }  
  }

  const removeSubject1= (subject)=>{
    var temp=[]
    var found= false
    for (let index = 0; index < subjectsForAddition.length; index++) {
      if(subjectsForAddition[index].subject!=subject.subject || subjectsForAddition[index].class!=subject.class){
        temp= [...temp, subjectsForAddition[index]]
      }  else {
        if(found){
          temp= [...temp, subjectsForAddition[index]]
        } else {
          found= true
        }
      }
    }
    setSubjectsForAddition(temp)
  }

  const handleFirstGuarantorsFormChange = (id, newValue) => {
    setFirstGuarantorsForm((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item))
    );
  };

  const handleSecondGuarantorsFormChange = (id, newValue) => {
    setSecondGuarantorsForm((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item))
    );
  };

  //Handling Submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = { class: classValue /*classArm: classArmValue */ };

    teacherForm.forEach((value) => {
      data[value.apiLabel] = value.value;
    });
    data['username']= usernameValue
    data['password']= passwordValue
    data['subjects']= subjectsForAddition
   
    submitted(data);
    // setTimeout(() => {
    //   submitted();
    // }, 800);
  };

  useEffect(() => {
    setSubjectsForSelection(allClassesSubjects)
    setOpacity(1);
  }, []);

  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ maxWidth: "530px" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <form onSubmit={handleSubmit} className="form" action="" method="post">
          <div className="formHeaderRow">
            <MDTypography variant="h6">Staff Form</MDTypography>
            <MDTypography onClick={cancelled}>
              <Icon style={{ cursor: "pointer" }}>cancel</Icon>
            </MDTypography>
          </div>
          <div className="rows">
          <div style={{ width: "240px" }}>
            <MDInput
              key={'username'}
              type={'text'}
              label={'username'}
              value={usernameValue}
              required={true}
              onChange={(e) => setUsernameValue(e.target.value)}
              fullWidth
            />
          </div>
          <div style={{ width: "240px" }}>
            <MDInput
              key={'pass'}
              type={'password'}
              label={'password'}
              value={passwordValue}
              required={true}
              onChange={(e) => setPasswordValue(e.target.value)}
              fullWidth
            />
          </div>
            {teacherForm.map((data) => {
              return (
                <>
                  {data.type !== "file" && data.type !== "date" && (
                    <div style={{ width: `${data.fullWidth ? "100%" : "240px"}` }}>
                      <MDInput
                        key={data.id}
                        type={data.type}
                        label={data.label}
                        value={data.value}
                        required={data.required}
                        onChange={(e) => handleTeacherFormChange(data.id, e.target.value)}
                        disabled={data.disable}
                        fullWidth
                      />
                    </div>
                  )}

                  {data.type === "file" && (
                    <div key={data.id} style={{ paddingLeft: "40px" }} className="rows">
                      <MDTypography variant="button" display="flex" alignItems="center">
                        <Icon>upload</Icon>&nbsp; {data.label}
                      </MDTypography>
                      <div style={{ width: "240px" }}>
                        <input
                          type="file"
                          onChange={(e) => handleTeacherFormChange(data.id, e.target.files[0])}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  )}
                  {data.type === "date" && (
                    <div style={{ width: "100%" }}>
                      <MDTypography variant="button">Date Of Birth</MDTypography>
                      <MDInput
                        key={data.id}
                        type={data.type}
                        label={data.label}
                        value={data.value}
                        onChange={(e) => handleTeacherFormChange(data.id, e.target.value)}
                        fullWidth
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
          <div style={{ margin: "12px 0px" }}>
            <MDTypography variant="button" fontWeight="medium">
              Guarantor one
            </MDTypography>
            <div className="rows">
              {FirstGuarantorsForm.map((data) => {
                return (
                  <div style={{ width: `${data.fullWidth ? "100%" : "240px"}` }}>
                    <MDInput
                      key={data.id}
                      type={data.type}
                      label={data.label}
                      value={data.value}
                      required={data.required}
                      onChange={(e) => handleFirstGuarantorsFormChange(data.id, e.target.value)}
                      fullWidth
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ margin: "12px 0px", paddingBottom: "8px" }}>
            <MDTypography variant="button" fontWeight="medium">
              Guarantor Two
            </MDTypography>
            <div className="rows">
              {SecondGuarantorsForm.map((data) => {
                return (
                  <div style={{ width: `${data.fullWidth ? "100%" : "240px"}` }}>
                    <MDInput
                      key={data.id}
                      type={data.type}
                      label={data.label}
                      value={data.value}
                      required={data.required}
                      onChange={(e) => handleSecondGuarantorsFormChange(data.id, e.target.value)}
                      fullWidth
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{fontSize: 15}}>
                Subjects
                </div>
                <div style={{display: 'flex'}}>
                <select
                  onChange={(e)=>handleSelectSubject(e.target.value)}
                >
                  <option>select subject</option>
                  {subjectsForSelection.map((subject, index)=>{
                    return (
                      <option 
                        value= {index}
                        // onClick={()=>handleSelectSubject(subject)}
                        
                      >
                        {subject.subjectName} {subject.className}
                      </option>
                    )
                  })}
                </select>
                <span>
                  <div 
                    style={{
                      padding: "3px 10px 3px 10px", 
                      background: "#344767", 
                      outline: 'none', 
                      border: 'none',
                      borderRadius: '3px',
                      marginLeft: '20px',
                      color: '#fff',
                      cursor: 'pointer',
                      width: 50,
                      height: 30,
                      fontSize: 13
                    }}
                    onClick={handleAddSelectedSubject}  
                  >
                    add
                  </div>
                </span>
                </div>
                <div style={{fontSize: 15}}> Added Subjects </div>
                <div>
                    {subjectsForAddition && subjectsForAddition.map((subject)=>{
                      return (
                        <div style={{display: 'flex'}}>
                        <div style={{fontSize: 13, paddingTop: 4}}>{subject.subjectName} {subject.className}</div>
                        <div>
                          <button
                            style={{
                              padding: "3px 10px 3px 10px", 
                              background: "#344767", 
                              outline: 'none', 
                              border: 'none',
                              borderRadius: '3px',
                              marginLeft: '20px',
                              color: '#fff',
                              cursor: 'pointer',
                            }}
                            onClick={()=>removeSubject1(subject)}
                          >
                            remove
                          </button>
                        </div>
                        </div>
                      )
                    })}
                </div>
          <div className="rows">
            <MDButton type="submit" variant="contained" color="dark" fullWidth>
              <Icon>add</Icon>
              Submit
            </MDButton>
          </div>
        </form>
      </Card>
    </div>
  );
}

FormTeacherForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
  allClassesSubjects: PropTypes.object.isRequired, 
};
