//Result Dummy Data
/* This Is just an array that contains object which are consist of each box Column. e.g All columns
 inside a row followed by the next columns inside a row including the headers. If the columnBox is a
  sideHeader, e.g subjects names, then you should include the sideHeader:true property in the object
   when ever the data is fetched and is being structured in this way. The checked:true is used to indicate
    which ratings is checked. The xs:true is used to determine that the column is a rating box. I useed the generateObject function
    to generate dummy columns*/

//Note: The result component can be restructured and the data structure as well.

const generateObject = (content, num, xs) => {
  const result = [];
  for (let i = 0; i < num; i++) {
    result.push({ name: content ? content : null, xs: xs ? xs : null });
  }
  return result;
};

export const resultDummyData = [
  {
    id: "Ben",
    name: "BEN JOHNSON",
    sex: "MALE",
    term: "1st Term",
    class: "LILIE CLASS (BASIC 4)",
    year: "2022/2023",
    noInClass: 15,
    rows: [
      {
        sideHeader: true,
        name: "SUBJECT NAME",
      },
      {
        name: "CA 20%",
      },
      {
        name: "MID TERM 30%",
      },
      {
        name: "EXAM 50%",
      },
      {
        name: "TOTAL 100%",
      },
      {
        name: "SUBJECT AVERAGE",
      },
      {
        name: "GRADE",
      },
      {
        name: "SUBJECT POSITION",
      },
      {
        name: "REMARK",
      },
      {
        name: "PSYCHOMOTOR EVALUATION",
        spanTwo: true,
      },

      {
        sideHeader: true,
        name: "ENGLISH LANGUAGE",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "DISTINCT",
      },
      {
        name: "",
        ratingPart: true,
      },
      {
        name: "RATINGS",
        ratingPart: true,
      },

      {
        sideHeader: true,
        name: "MATHETICS",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "V.Good",
      },
      {
        name: "LEGIBILITY",
        ratingPart: true,
      },
      {
        name: "5",
        xs: true,
      },
      {
        name: "4",
        xs: true,
      },
      {
        name: "3",
        xs: true,
      },
      {
        name: "2",
        xs: true,
      },
      {
        name: "1",
        xs: true,
      },

      {
        sideHeader: true,
        name: "BASIC SCIENCE & TECHNOLOGY",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "DISTINCT",
      },
      {
        name: "PUNCTUALITY",
        ratingPart: true,
      },
      ...generateObject(null, 2, true),
      {
        checked: true,
        xs: true,
      },
      ...generateObject(null, 2, true),

      {
        sideHeader: true,
        name: "ACCURACY",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "DISTINCT",
      },
      {
        name: "PERSERVERANCE",
        ratingPart: true,
      },
      {
        xs: true,
      },
      {
        checked: true,
        xs: true,
      },
      ...generateObject(null, 3, true),

      {
        sideHeader: true,
        name: "CULTURAL & CREATIVE ARTS",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "DISTINCT",
      },
      {
        name: "DEXTERITY",
        ratingPart: true,
      },
      {
        xs: true,
        checked: true,
      },
      ...generateObject(null, 4, true),

      {
        sideHeader: true,
        name: "FRENCH",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "GOOD",
      },
      {
        name: "DELIGENCE",
        ratingPart: true,
      },
      {
        xs: true,
        checked: true,
      },
      ...generateObject(null, 4, true),

      {
        sideHeader: true,
        name: "PRE-VOCATIONAL STUDIES",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "DISTINCT",
      },
      {
        name: "SELF-CONTROL",
        ratingPart: true,
      },
      {
        xs: true,
        checked: true,
      },
      ...generateObject(null, 4, true),

      {
        sideHeader: true,
        name: "VERBAL & NON-VERBAL",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "DISTINCT",
      },
      {
        name: "NEATNESS",
        ratingPart: true,
      },
      {
        xs: true,
        checked: true,
      },
      ...generateObject(null, 4, true),

      {
        sideHeader: true,
        name: "QUANTITATIVE REASONING",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "DISTINCT",
      },
      {
        name: "HONESTY",
        ratingPart: true,
      },
      {
        xs: true,
        checked: true,
      },
      ...generateObject(null, 4, true),

      {
        sideHeader: true,
        name: "HISTORY",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "DISTINCT",
      },
      {
        name: "RELIABILITY",
        ratingPart: true,
      },
      {
        xs: true,
        checked: true,
      },
      ...generateObject(null, 4, true),

      {
        sideHeader: true,
        name: "IGBO LANG",
      },
      ...generateObject(17, 5),
      {
        name: "A",
      },
      {
        name: "1st",
      },
      {
        name: "DISTINCT",
      },
      {
        name: "ATTENDANCE",
        ratingPart: true,
      },
      {
        xs: true,
        checked: true,
      },
      ...generateObject(null, 4, true),
    ],
  },
];
