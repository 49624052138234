import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";

import PropTypes from "prop-types";
import { Cookies, useCookies } from "react-cookie";

import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Loading from "components/loadingComponent";

import { useParams } from "react-router-dom";

//dummy data
import BroadSheet from "layouts/dashboard/components/broadSheet";
import { LoginContext } from "../../App";

//MUI Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { server } from "utils/server";


export default function ClassBroadSheet() {
  const { classNo } = useParams();
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  // const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [selectedEvaluation, setSelectedEvaluation] = useState('');
  const [studentsState, setStudentsState] = useState([]);
  const [evaluationDropDownDisplay, setEvaluationDropDownDisplay] = useState(false);
  const [students, setStudents] = useState(null);
  const [scoreHeaders, setScoreHeaders] = useState([]);
  const [scoreMain, setScoreMain] = useState([]);
  const [showScores, setShowScores] = useState(false);
  const [firstTermState, setFirstTermState] = useState(false);
  const [secondTermState, setSecondTermState] = useState(false);
  const [thirdTermState, setThirdTermState] = useState(false);
  const [term, setTerm] = useState('first');
  const [renderEdit, setRenderEdit] = useState(false);
  const [valueToEdit, setValueToEdit] = useState(0);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [arrCounter, setArrCounter] = useState(0);
  const [idToEdit, setIdToEdit] = useState("");
  const [idToCreate, setIdToCreate] = useState("");
  const [classToCreate, setClassToCreate] = useState("");
  const [subjectToCreate, setSubjectToCreate] = useState("");
  const [renderCreate, setRenderCreate] = useState(false);
  const [selectedEvaluationName, setSelectedEvaluationName] = useState('');
  const [testToEdit, setTestToEdit] = useState("");
  const [showComponent, setShowComponent] = useState(true);
  const [studentsTemp, setStudentsTemp] = useState([]);
  const [subjectsTemp, setSubjectsTemp] = useState([]);
  const [classesTemp, setClassesTemp] = useState([]);
  const [firstIndex, setFirstIndex] = useState([]);
  const [secondIndex, setSecondIndex] = useState([]);
  const [getTemp, setGetTemp] = useState(2);
  const [done, setDone] = useState(0);
  const [overAlls, setOverAlls] = useState([]);
  const [key, setKey] = useState(0);
  const navigation = useNavigate();
  const { server1 } = server()
 

  const getClassResults = async () => {
    
     const response = await fetch(`${server1}/admins/get_one_class_scores/${classNo}`, {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
         "sds": cookie.sds_auth_token
       },
     });
     const responseData = await response.json();
     if (response.ok) {
      // var temp= {
      //   studentArr: responseData.data[counter],
      //   class: responseData.classes[counter],
      //   subjects: responseData.subjects[counter],
      //   skills: responseData.skills,
      // }
      
       setStudents(responseData.data)
     } else {
       
     }
   };

   const getSession = async () => {
    
    const response = await fetch(`${server1}/admins/get_current_session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token
      },
    });
    const responseData = await response.json();
    if (response.ok) { 
      if(responseData.data.term==1){
        setFirstTermState(true)
      }
      if(responseData.data.term==2){
        setSecondTermState(true)
      }
      if(responseData.data.term==3){
        setThirdTermState(true)
      }
      
    } else {
      console.log('success')
    }
  };

  useEffect(()=>{
    getSession()
  })


   const positionArrange=(num)=>{
    var newNum= num.toString()
    var suffix= 'th'
    var resultString= newNum
    if(newNum[newNum.length-1]=='1' && newNum[newNum.length-2]=='1'){
      suffix= 'th'
    } else if(newNum[newNum.length-1]=='1' && newNum[newNum.length-2]!='1'){
      suffix= 'st'
    } else if(newNum[newNum.length-1]=='3'){
      suffix= 'rd'
    } else if(newNum[newNum.length-1]=='2' && newNum[newNum.length-2]!='1'){
      suffix= 'nd'
    } 
    return resultString+suffix
   }



  const notApproved= async (id, newRating)=>{
    setIsLoading(true)
    const data= {
      studentId: id,
      classId: students.class._id,
      skill: selectedEvaluation,
      rating: newRating
    }
    var term= ''
    if(firstTermState){
      term= 'first'
    }
    if(secondTermState){
      term= 'second'
    }
    if(thirdTermState){
      term= 'third'
    }
    const mainData= {
      term: term
    }
    
    const response = await fetch(`${server1}/admins/scores_not_approved/${students.class._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
      body: JSON.stringify(mainData),
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded
     
      setAlertDisplay(true)
      setAlertType('success')
      setAlertMessage('action successful')
      setKey(getTemp+1)
      setIsLoading(false)
      navigation('/manage-result-submission')
      //const responseData = await response.json();
    } else {
      // User creation failed
      // setAlertMessage(msg.message);
      // setAlertType("error");
      // setAlertDisplay(true);
      
      setAlertDisplay(true)
      setAlertType('error')
      setAlertMessage('action not successful')
      setIsLoading(false)
      // const errorData = await response.json();
      // console.error("User creation failed:", errorData.error);
    }
  }
  

  const approved= async ()=>{
    setIsLoading(true)
    var term= ''
    if(firstTermState){
      term= 'first'
    }
    if(secondTermState){
      term= 'second'
    }
    if(thirdTermState){
      term= 'third'
    }

    const data= {
      classId: students.class._id,
      term: term
    }
    const response = await fetch(`${server1}/admins/approve_scores`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded
      
      setAlertMessage(msg.message);
      setAlertType("success");
      setAlertDisplay(true);
      setIsLoading(false)
      navigation('/manage-result-submission')
      
    } else {
      setAlertMessage(msg.message);
      setAlertType("error");
      setAlertDisplay(true)
      setIsLoading(false)
      
      // const errorData = await response.json();
      // console.error("User creation failed:", errorData.error);
    }
  }

  //Updating Students PsychomotorEvaluation
  const handleStudentPsychomotorEvaluationChange = (name, newValue) => {
    switch (selectedEvaluation) {
      case "Legibility":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  legibility: item.legibility === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Accuracy":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  accuracy: item.accuracy === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Neatness":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  neatness: item.neatness === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Dexterity":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  dexterity: item.dexterity === newValue ? "" : newValue,
                }
              : item
          )
        );
      default:
        return;
    }
  };


  //Updating StudentEnability
  const updateStudentEnability = (no) => {
    setStudents((prevData) =>
      prevData.map((item) =>
        item.No === no ? { ...item, isEnabled: item.isEnabled ? false : true } : item
      )
    );
  };
  var setCapitals= (string)=>{
    var subHolder= string.split(' ')
    var mainSubject= ''
    for(let i=0; i<subHolder.length; i++){
      
      if(subHolder[i]){
        if(subHolder[i].length>0){
          var first= subHolder[i][0].toUpperCase()
          var others= subHolder[i].slice(1) 
          var full= first+others.toLowerCase()
          if(i===0){
            mainSubject= full
          } else {
            mainSubject= mainSubject+' '+full
          }  
        }
      }
    
    }
    return mainSubject
  }

  
  const handleScoreEdit= (studentIndex, scoreIndex, test, score)=> {
    
    var scoreId= students.studentArr[studentIndex].scoreDetails[scoreIndex]._id
    setIdToEdit(scoreId);
    setRenderEdit(true)
    setTestToEdit(test)
    setFirstIndex(studentIndex)
    setSecondIndex(scoreIndex)
    // displayDropDownMenu(id);
    setValueToEdit(score);
    
  }


  
  var handleOverAlls= ()=>{
    var output=[]
    var totals=[]
    var averages=[]
    var allOverAlls=[]
    for (let i = 0; i < students.studentArr.length; i++) {
      var studentTotal= 0
      var available= false
      for (let index = 0; index < students.studentArr[i].scoreDetails.length; index++) {
        available= true
        var scores= students.studentArr[i].scoreDetails[index]
        if(firstTermState){
          var score= scores.test1a+scores.test1b+scores.exam1
          studentTotal+= score
        }
        if(secondTermState){
          var score= scores.test2a+scores.test2b+scores.exam2
          studentTotal+= score
        }
        if(thirdTermState){
          var score= scores.test3a+scores.test3b+scores.exam3
          studentTotal+= score
        } 
      }
      if(available){
        var studentAverage= studentTotal/students.studentArr[i].scoreDetails.length
        var temp= {
          average: studentAverage,
          total: studentTotal,
          available: available
        }
        allOverAlls= [...allOverAlls, temp]
        averages= [...averages, studentAverage]
        totals= [...totals, studentTotal]
      } else {
        var temp= {
          average: 0,
          total: 0
        }
        allOverAlls= [...allOverAlls, temp]
        averages= [...averages, 0]
        totals= [...totals, 0]
      }
    }
    averages.sort()
    averages.reverse()

    for (let index = 0; index < allOverAlls.length; index++) {
      const subjectPositionFn = (element) => element == allOverAlls[index].average;
      
      const classPosition= averages.findIndex(subjectPositionFn)
      
      output.push(
        (
          <tr key={Math.random()}>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">{allOverAlls[index].available?allOverAlls[index].average.toFixed(1):'N/A'}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">{allOverAlls[index].available?allOverAlls[index].total:'N/A'}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">{allOverAlls[index].available?positionArrange(classPosition+1):'N/A'}</MDTypography>
            </td>
          </tr>
        )
      )
    }
    return output
   
  }
  

  const showSubjectInfos= (id)=>{
    var infosArray=[]

    var subjectPositionArray= []
    for (let i = 0; i < students.studentArr.length; i++) {
      //const subjectPosition = (element) => element == score;
     
      for (let index = 0; index < students.studentArr[i].scoreDetails.length; index++) {
        if(students.studentArr[i].scoreDetails[index].subjectId===id){
          var scores= students.studentArr[i].scoreDetails[index]
          if(firstTermState){
            var score= scores.test1a+scores.test1b+scores.exam1
            subjectPositionArray= [...subjectPositionArray, score]
          }
          if(secondTermState){
            var score= scores.test2a+scores.test2b+scores.exam2
            subjectPositionArray= [...subjectPositionArray, score]
          }
          if(thirdTermState){
            var score= scores.test3a+scores.test3b+scores.exam3
            subjectPositionArray= [...subjectPositionArray, score]
          }  
        }
      }
    }
    subjectPositionArray.sort()
    subjectPositionArray.reverse()
    var subjectTotal= subjectPositionArray.reduce((a, b) => a + b, 0)
    var subjectAverage= subjectTotal/subjectPositionArray.length
    
    for (let i = 0; i < students.studentArr.length; i++) {
      
      var flag= false
      var studentId= students.studentArr[i].studentDetails._id
      
      var classId= students.studentArr[i].classDetails._id
      for (let index = 0; index < students.studentArr[i].scoreDetails.length; index++) {
        
        if(students.studentArr[i].scoreDetails[index].subjectId===id){
          var scores= students.studentArr[i].scoreDetails[index]
          flag= true

          if(firstTermState){
            // setOverAlls([...overAlls, ])
            var score= scores.test1a+scores.test1b+scores.exam1
            const subjectPositionFn = (element) => element == score;
            const subjectPosition= subjectPositionArray.findIndex(subjectPositionFn)
            infosArray.push(
              <tr key={Math.random()} className="tableHeaderRow">
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{scores.test1a}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{scores.test1b}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{scores.exam1}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">
                      {score}
                    </MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{subjectAverage.toFixed(1)}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{positionArrange(subjectPosition+1)}</MDTypography>
                  </td>
              </tr>
            )
          }
          if(secondTermState){
            var score= scores.test2a+scores.test2b+scores.exam2
            const subjectPositionFn = (element) => element == score;
            const subjectPosition= subjectPositionArray.findIndex(subjectPositionFn)
            infosArray.push(
              <tr key={Math.random()} className="tableHeaderRow">
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{scores.test2a}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{scores.test2b}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{scores.exam2}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">
                    {score}
                  </MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{subjectAverage.toFixed(1)}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{positionArrange(subjectPosition+1)}</MDTypography>
                </td>
            </tr>
            )
          }
          if(thirdTermState){
            var score= scores.test3a+scores.test3b+scores.exam3
            const subjectPositionFn = (element) => element == score;
            const subjectPosition= subjectPositionArray.findIndex(subjectPositionFn)
            infosArray.push(
              <tr key={Math.random()} className="tableHeaderRow">
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{scores.test3a}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{scores.test3b}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{scores.exam3}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">
                    {score}
                  </MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{subjectAverage.toFixed(1)}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{positionArrange(subjectPosition+1)}</MDTypography>
                </td>
            </tr>
            )
          
        }
        
      }   
    }
  
    
    if(!flag){
      var first= ''
      var second= ''
      var third= ''
      if(firstTermState){
        first= 'test1a'
        second= 'test1b'
        third= 'exam1'
      }
      if (secondTermState) {
        first= 'test2a'
        second= 'test2b'
        third= 'exam2'
      }
      if (thirdTermState) {
        first= 'test3a'
        second= 'test3b'
        third= 'exam3'
      }
      infosArray.push(
        <tr key={Math.random()} className="tableHeaderRow">
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
        </tr>
      )
    }
  }
  return infosArray
  }

  var scoreTable= []
  var scoreTableHeaders= []
  var scoreBySubjects= []
  const handleScoreHeaderView= ()=>{

    for (let index = 0; index < students.subjects.length; index++) {
      scoreTableHeaders.push(
        <th key={students.subjects[index]._id}>
          <MDTypography variant="h6">
            {students.subjects[index].name}
          </MDTypography>
        </th>
      )
    }
    setScoreHeaders(scoreTableHeaders)
  }
  const cancelled= ()=>{

  }

  //When ever the Publish button on the teacher's dashboard is Clicked
  useEffect(() => {
    getClassResults()
  }, []);

  useEffect(() => {
    setKey(key+1)
    //setScoreMain(scoreMain)
    
    setShowScores(false)
  }, [scoreHeaders]);



  useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
  }, []);

  return (
    <DashboardLayout>
    {isLoading && <Loading />}
    {alertDisplay && (
      <div
        style={{
          background: alertType === "error" ? "#d31a38" : "green",
          width: 350,
          position: "absolute",
          zIndex: 10,
          right: 40,
          color: "white",
          borderRadius: 20,
        }}
      >
        <div
          style={{
            height: 40,
            borderRadius: "50%",
            float: "right",
            marginRight: 18,
            cursor: "pointer",
            marginTop: 2,
          }}
          onClick={() => setAlertDisplay(false)}
        >
          ×
        </div>
        <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
      </div>
    )}
      
      {/* <BroadSheet data={classBroadsheet[0]} isAdmin={true} /> */}
      <MDTypography variant="h6">
      {students && `${students.class.className.toUpperCase()} ${firstTermState?'FIRST TERM':secondTermState?'SECOND TERM':'THIRD TERM'}`}
      </MDTypography>
      {students && 
      <Card
        sx={{
          width: "100%",
          mt: 3,
          p: 2,
        }}
      >
        {showComponent && <div className="broadSheetBox" key={key}>
          {/* {showScores} */}
          {/* The table that displays students name and number */}
          <div className="studentsTable">
            <MDTypography variant="h6">Students</MDTypography>
            <table>
              <thead>
                <tr>
                  {/* {data.students.headers.map((header) => ( */}
                    <th >
                      <MDTypography variant="button" fontWeight="bold">
                        S/NO
                      </MDTypography>
                    </th>
                    <th >
                      <MDTypography variant="button" fontWeight="bold">
                        NAME
                      </MDTypography>
                    </th>
                  {/* ))} */}
                </tr>
              </thead>

              {students && students.studentArr && students.studentArr.map((student, index) => {
                return (
                  <tr key={student.name}>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      <MDTypography variant="overline">{index+1}</MDTypography>
                    </td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      <MDTypography variant="overline">
                        {`${student.studentDetails.firstName} ${student.studentDetails.lastName}`}
                      </MDTypography>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          {/* {scoreMain} */}
          {students && students.subjects && students.subjects.map((subject)=>{
          return(  <div  className="tableBox">
            <MDTypography fontSize="16px" variant="button" fontWeight="bold">
              {setCapitals(subject.name)}
                {/* {classSubjects[index].name} */}
              </MDTypography>
                <table>
                  <thead style={{ width: "100%" }}>
                    <tr className="tableHeaderRow">                 
                      <th key={`1ca${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          CA 20%
                        </MDTypography>
                      </th>
                      <th key={`2ca${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          MID-TERM 20%
                        </MDTypography>
                      </th>
                      <th key={`exam${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          EXAM 60%
                        </MDTypography>
                      </th>
                      <th key={`total${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          TOTAL
                        </MDTypography>
                      </th>
                      <th key={`avg${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                        Subject Average
                        </MDTypography>
                      </th>
                      
                      <th key={`pos${term}`}>
                        <MDTypography variant="button" fontWeight="bold">
                          Subject Position
                        </MDTypography>
                      </th> 
                    </tr>
                  </thead>
                  <tbody style={{ width: "100%" }} key={done}>
                    {showComponent && showSubjectInfos(subject._id)}
                  </tbody>
                </table>
                </div>)
              }
            )}
            <div className="overallTable">
            <MDTypography variant="h6">OverAll</MDTypography>
            <table>
              <thead>
                <tr>
                  {/* {broadSheetDummyData.overAll.headers.map((header) => (
                    <th key={header}>
                      <MDTypography variant="button" fontWeight="bold">
                        {header}
                      </MDTypography>
                    </th>
                  ))} */}
                  <th key={'Overall Average'}>
                    <MDTypography variant="button" fontWeight="bold">
                      Overall Average
                    </MDTypography>
                  </th>
                  <th key={'Overall Total'}>
                    <MDTypography variant="button" fontWeight="bold">
                      Overall Total
                    </MDTypography>
                  </th>
                  <th key={'Overall Position'}>
                    <MDTypography variant="button" fontWeight="bold">
                      Overall Position
                    </MDTypography>
                  </th>
                </tr>
              </thead>
              {students && showComponent && handleOverAlls()}
              {/* {broadSheetDummyData.overAll.overAllScores.map((score) => {
                return (
                  <tr key={Math.random()}>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      <MDTypography variant="overline">{score.OverallAverage}</MDTypography>
                    </td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      <MDTypography variant="overline">{score.OverallTotal}</MDTypography>
                    </td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      <MDTypography variant="overline">{score.OverallPosition}</MDTypography>
                    </td>
                  </tr>
                );
              })} */}
            </table>
          </div>
          {/* <div className="psychomotorEvaluationTable">
            <div className="psychomotorHeader">
              <MDTypography variant="h6">Psychomotor Evaluation</MDTypography>
            </div>
            <table>
              <thead>
                <tr>
                  <div className="psychomotorDropdownBox">
                    <div
                      className="selectedEvaluation"
                      onClick={() => {
                        setEvaluationDropDownDisplay(true);
                      }}
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        display="flex"
                        alignItems="center"
                      >
                        {selectedEvaluationName}&nbsp;<Icon>expand_more</Icon>
                      </MDTypography>
                    </div>
                    <div
                      style={{
                        display: `${evaluationDropDownDisplay ? "flex" : "none"}`,
                      }}
                      className="evaluationMenuBox"
                    >
                      <Card sx={{ width: "100%", height: "100%", p: 1 }}>
                        <ul>
                          {students.skills.map((option) => {
                            return (
                              <li
                                key={option._id}
                                onClick={() => {
                                  setEvaluationDropDownDisplay(false);
                                  setSelectedEvaluation(option._id);
                                  setSelectedEvaluationName(option.name)
                                }}
                              >
                                <MDTypography variant="button" fontWeight="bold">
                                  {option.name}
                                </MDTypography>
                              </li>
                            );
                          })}
                        </ul>
                      </Card>
                    </div>
                  </div>
                  <div>
                    {[5, 4, 3, 2, 1].map((value) => {
                      return (
                        <th key={value}>
                          <MDTypography variant="button" fontWeight="bold">
                            {value}
                          </MDTypography>
                        </th>
                      );
                    })}
                  </div>
                </tr>
              </thead>
              {students.studentArr.map((student) => {
                return (
                  <tr key={student.studentDetails._id}>
                    {[5, 4, 3, 2, 1].map((data) => {
                      return (
                        <td key={data}>
                          {selectedEvaluation.length > 0 && (
                            <> */}
                            { /* {!isAdmin ? (
                                <input
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleStudentPsychomotorEvaluationChange(
                                      student.student,
                                      data.toString()
                                    )
                                  }
                                  type="checkbox"
                                  checked={
                                    (selectedEvaluation === "Legibility" &&
                                      student.legibility === data.toString()) ||
                                    (selectedEvaluation === "Accuracy" &&
                                      student.accuracy === data.toString()) ||
                                    (selectedEvaluation === "Neatness" &&
                                      student.neatness === data.toString()) ||
                                    (selectedEvaluation === "Dexterity" &&
                                      student.dexterity === data.toString())
                                      ? true
                                      : false
                                  }
                                />
                              ) : (*/}
                              {/* {handleSelectPsych(data, student)} */}
                                {/* <MDTypography variant="button" >
                                  <Icon onClick={()=>{
                                      sendRating(student.studentDetails._id, data)
                                    }
                                  }>
                                    {student.skills.map((skill)=>{
                                      {selectedEvaluation === skill.skill &&
                                          skill.rating === data
                                          ? "check"
                                          : ""
                                      }})
                                    } */}
                                    {/* {(selectedEvaluation === "Legibility" &&
                                      student.legibility === data.toString()) ||
                                    (selectedEvaluation === "Accuracy" &&
                                      student.accuracy === data.toString()) ||
                                    (selectedEvaluation === "Neatness" &&
                                      student.neatness === data.toString()) ||
                                    (selectedEvaluation === "Dexterity" &&
                                      student.dexterity === data.toString())
                                      ? "check"
                                      : ""} */}
                                  {/* </Icon>
                                </MDTypography> */}
                            {/* )}*/}
                            {/* </>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </table> */}
            {/* <MDBox paddingTop="10px">
              <MDButton color="dark" onClick={() => reload(getTemp+1)}>
                <Icon>publish</Icon>&nbsp;Set
              </MDButton>
            </MDBox> */}
          {/* </div> */}
        {/* {isAdmin && (*/}
            {/* <div className="enabilityTable">
              <MDTypography variant="h6">_</MDTypography>
              <table>
                <thead>
                  <tr>
                    <th>
                      <MDTypography variant="h6">Enability</MDTypography>
                    </th>
                  </tr>
                </thead>
                {students.map((student) => {
                  return (
                    <tr key={student.No}>
                      <Switch
                        checked={student.isEnabled}
                        onChange={() => updateStudentEnability(student.No)}
                      />
                    </tr>
                  );
                })}
              </table>
            </div> */}
        {/* )}*/}
        </div>}
      </Card>}
      <MDBox display="flex" gap="10px" justifyContent="end" paddingTop="10px">
        <MDButton onClick={notApproved} color="error">Not Approved</MDButton>
        <MDButton onClick={approved} color="success">
          <Icon>publish</Icon>&nbsp;Publish
        </MDButton>
      </MDBox>
    </DashboardLayout>
  );
}
