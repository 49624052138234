import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";

import { LoginContext } from "App";
import { useState, useEffect } from "react";

export default function AcademicReports() {
  //const [isLoggedIn, setIsLoggedIn] = useState(LoginContext);
  const navigation = useNavigate();

  useEffect(() => {
    
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography variant="h6">Academic Reports</MDTypography>
    </DashboardLayout>
  );
}
