import PropTypes from "prop-types"
import MDButton from "components/MDButton";
import { useState } from "react";

import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function PinView({ pin , cancel }) {
    const [ copied, setCopied ] = useState(false)

    const handleCopy = async () => {
        try {
          await navigator.clipboard.writeText(pin);
          setCopied(true)
          setTimeout(() => {
            setCopied(false)
          }, 3000)
        //   alert('Text copied to clipboard!');
        } catch (error) {
          console.error('Error copying text:', error);
          alert('Failed to copy text to clipboard!');
        }
      };

  return (
    <form
      style={{ 
        position: 'fixed',
        zIndex: '1000',
        width: '85%',
        height: '97%',
        display: "flex", 
        flexDirection: "column", 
        justifyContent: 'center',
        alignItems: 'center',
        gap: "12px", 
        paddingBottom: "10px",
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
     }}
    >
        <Card 
            style={{ 
                width: '100%',
                maxWidth: '500px',
                padding: '20px',
                position: 'relative',
                top: '-50px'
             }}
        >
            <MDBox style={{ textAlign: 'center' }}>
                <MDTypography style={{ textAlign: "right", cursor: "pointer" }}>
                    <Icon onClick={() => cancel()}>cancel</Icon>
                </MDTypography>
                <MDTypography variant='h6'>PIN Generated</MDTypography>
                <p style={{ fontSize: '15px' }}>Pin generated successfully!</p>
                <MDBox>
                    <MDTypography variant='h4'>{pin}</MDTypography>
                </MDBox>
                <MDBox 
                    style={{ 
                        display: 'flex',
                        flexDirection:'column',
                        justifyContent: 'center',
                        alignItems: "center",
                        marginTop: "20px"
                     }}
                >
                    {copied && <p style={{ color: 'green', fontSize: '15px' }}>copied!</p>}
                    <MDButton variant='contained' color='info' onClick={() => handleCopy()}>    
                        <Icon>content_copy</Icon>
                          Copy
                    </MDButton>
                </MDBox>
            </MDBox>
        </Card>
    </form>
  );
}

PinView.propTypes = {
  pin: PropTypes.string.isRequired,
//   action: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
