import PropTypes from "prop-types";
import MDButton from "components/MDButton";

import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function Info({ text, action, cancel }) {
  return (
    <form
      style={{
        position: "fixed",
        zIndex: "1000",
        width: "85%",
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
        paddingBottom: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: "500px",
          padding: "20px",
          position: "relative",
          top: "-50px",
        }}
      >
        <MDBox style={{ textAlign: "center" }}>
          <MDTypography variant="h6" style={{ marginBottom: "20px" }}>
            {text}
          </MDTypography>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <MDButton variant="contained" color="info" onClick={() => action()}>
              OK
            </MDButton>
            <MDButton variant="contained" color="error" onClick={() => cancel()}>
              cancel
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </form>
  );
}

Info.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
