import PropTypes from "prop-types";

import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";

import { useState, useEffect } from "react";

export default function EditScoreForm({ submitted, cancelled, initialValue, test, students, index1, index2 }) {
  const [opacity, setOpacity] = useState(0);
  const [scoreValue, setScoreValue] = useState(initialValue);
  const [testValue, setTestValue] = useState(test);
  const [studentsValues, setStudentsValues] = useState(students);
  const [errorState, setErrorState] = useState(false);
  



  const handleSubmit = (e) => {
    e.preventDefault();
    if(isNaN(scoreValue)){
      setErrorState(true)
      return
    } else {

      const data = {
        subjectId: students.subjects.subject._id,
        classId: students.subjects.class._id,
        userId: students.studentArr[index1].profile._id,
        score: scoreValue,
        test: testValue
      };
      submitted(data);
    }
    
  };

  useEffect(() => {
    setOpacity(1);
  }, []);
  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ width: `${window.innerWidth - 60 + "px"}`, maxWidth: "430px" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <form className="form" action="" method="post" onSubmit={handleSubmit}>
          <div className="formHeaderRow">
            <MDTypography variant="h6">Edit Score</MDTypography>
            <Icon style={{ cursor: "pointer" }} onClick={cancelled}>
              cancel
            </Icon>
          </div>
          {errorState && <small style={{color: 'red', fontSize: 12,float: 'left'}}>**please enter a number</small>}
          <div className="rows">
            <MDInput
              type="number"
              label="Score"
              value={scoreValue}
              onChange={(e) => setScoreValue(e.target.value)}
              fullWidth
              required
            ></MDInput>
          </div>

          <div className="rows">
            <MDButton type="submit" variant="contained" color="dark" fullWidth>
              <Icon>add</Icon>
              Submit
            </MDButton>
          </div>
        </form>
      </Card>
    </div>
  );
}

EditScoreForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  test: PropTypes.string.isRequired,
  students: PropTypes.object,
  index1: PropTypes.number,
  index2: PropTypes.number
};
