import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { Cookies, useCookies } from "react-cookie";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";

//Hooks
import { useState, useEffect, useRef } from "react";
import { server } from "utils/server";

export default function EditClassForm({
  submitted,
  cancelled,
  initialValue,
  formError,
  otherValues,
}) {
  const [classNameValue, setClassNameValue] = useState(initialValue);
  const [formTeacherFormDisplay, setFormTeacherFormDisplay] = useState(true);
  const [searchTeachers, setSearchTeachers] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [hoverBackground, setHoverBackground] = useState("#fff");
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [formTeacherValue, setFormTeacherValue] = useState("");
  const [showFormTeacherBlock, setShowFormTeacherBlock] = useState(false);
  const [formTeacherBlock, setFormTeacherBlock] = useState("");
  const [currentFormTeacher, setCurrentFormTeacher] = useState("");
  const [classSubjectFormDisplay, setClassSubjectFormDisplay] = useState(true);
  const [searchSubjects, setSearchSubjects] = useState([]);
  const [subjectValue, setSubjectValue] = useState("");
  const [currentSubjectValues, setCurrentSubjectValues] = useState([]);
  const [selectedSubjectsState, setSelectedSubjectsState] = useState([]);
  const [alreadyPostedSubjects, setAlreadyPostedSubjects] = useState([]);
  const [alreadyPostedSubjectsDisplay, setAlreadyPostedSubjectsDisplay] = useState([]);
  const [searchSubjectsDropdown, setSearchSubjectsDropdown] = useState(false);
  const [allSubjects, setAllSubjects] = useState([]);
  const [currentSubjects, setCurrentSubjects] = useState([]);
  const [addedTeachers, setAddedTeachers] = useState([]);
  const [alreadyPostedTeacher, setAlreadyPostedTeacher] = useState(null);
  const [otherValuesToUse, setOtherValuesToUse] = useState(otherValues);
  const [classTeacherValue, setClassTeacherValue] = useState(otherValues.form_teacher);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const listRef = useRef(null);
  const { server1 } = server();

  const teacherData = [];
  var subjectData = [];
  var temp = [];
  var subjectTemp = [];
  const handleMouseOver = {
    paddingLeft: 10,
    background: hoverBackground,
    cursor: "pointer",
    borderRadius: 5,
  };

  const handleSubjectChange = (newValue) => {
    if (newValue === "") {
      subjectData = [];
      setSubjectValue("");
    } else {
      for (let index = 0; index < allSubjects.length; index++) {
        if (allSubjects[index].name) {
          if (allSubjects[index].name.toLowerCase().includes(newValue)) {
            subjectTemp = [...subjectTemp, allSubjects[index]];
          }
        }
      }

      if (subjectTemp.length > 0) {
        subjectTemp.forEach((newTemp) => {
          subjectData.push(
            <div
              key={newTemp._id}
              style={handleMouseOver}
              onClick={() => {
                handleSubjectSelect(newTemp);
              }}
              onMouseEnter={setHoverBackground("#dddddd")}
              onMouseOut={setHoverBackground("#fff")}
            >
              {`${newTemp.name}`}
            </div>
          );
        });
      } else {
        subjectData.push(
          <div style={{ paddingLeft: 10, paddingBottom: 10 }}>No subject found</div>
        );
      }
    }

    setSearchSubjectsDropdown(true);
    setSearchSubjects(subjectData);
    setSubjectValue(newValue);
  };

  const removeSubject = (value) => {
    var newSubjects = [];
    var newCurrentSubjects = [];
    for (let index = 0; index < currentSubjects.length; index++) {
      if (currentSubjects[index] !== value) {
        newSubjects = [...newSubjects, currentSubjects[index]];
      }
    }
    for (let index = 0; index < currentSubjectValues.length; index++) {
      if (currentSubjectValues[index]._id !== value) {
        newCurrentSubjects = [...newCurrentSubjects, currentSubjectValues[index]];
      }
    }
    setCurrentSubjects(newSubjects);
    console.log({ newCurrentSubjects });
    setCurrentSubjectValues(newCurrentSubjects);
  };

  const handleSubjectSelect = (newTemp) => {
    console.log(newTemp);
    setCurrentSubjects([
      ...currentSubjects,
      { id: newTemp._id, className: classNameValue, subjectName: newTemp.name },
    ]);
    setCurrentSubjectValues([...currentSubjectValues, newTemp]);
    setSubjectValue("");
    setSearchSubjects([]);
  };

  const getClassDatas = async () => {
    try {
      const response = await fetch(
        `${server1}/admins/get_current_class_data/${otherValuesToUse._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            sds: cookie.sds_auth_token,
          },
        }
      );

      if (response.ok) {
        // User creation succeeded
        const responseData = await response.json();
        setAlreadyPostedSubjects(responseData.data);
        setAlreadyPostedTeacher(responseData.teacher);
      } else {
        // User creation failed
        formError("could not get teachers");
      }
    } catch (error) {
      formError("could not get teachers");
    }
  };

  useEffect(() => {
    if (alreadyPostedTeacher) {
      setCurrentFormTeacher(alreadyPostedTeacher._id);
      setShowFormTeacherBlock(true);
      setFormTeacherBlock(
        `${alreadyPostedTeacher.firstName} ${
          alreadyPostedTeacher.middleName
            ? alreadyPostedTeacher.middleName
            : alreadyPostedTeacher.lastName
        } ${alreadyPostedTeacher.middleName ? alreadyPostedTeacher.lastName : ""}`
      );
    }
  }, [alreadyPostedTeacher]);

  useEffect(() => {
    getClassDatas();
  }, [otherValuesToUse]);

  const removePostedSubject = async (userData) => {
    try {
      var data = {
        subject: userData,
        class: otherValues._id,
      };
      const response = await fetch(`${server1}/admins/remove_class_subject`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // User creation succeeded
        getClassDatas();
        const responseData = await response.json();
      } else {
        // User creation failed
        formError("could not remove subject");
      }
    } catch (error) {
      formError("could not get teachers");
    }
  };

  const newPostedSubjects = [];
  const handleShowPostedSubjects = () => {
    for (let index = 0; index < alreadyPostedSubjects.length; index++) {
      newPostedSubjects.push(
        <div style={{ color: "#1E3F66", fontSize: 13, marginTop: 5 }}>
          <span style={{ marginRight: 20 }}>{`${alreadyPostedSubjects[index].name}`}</span>
          <button
            style={{
              background: "#1E3F66",
              color: "white",
              border: "none",
              padding: 3,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={() => {
              removePostedSubject(alreadyPostedSubjects[index]._id);
              console.log("Here:", alreadyPostedSubjects[index]._id);
            }}
          >
            remove
          </button>
        </div>
      );
    }

    setAlreadyPostedSubjectsDisplay(newPostedSubjects);
  };

  useEffect(() => {
    handleShowPostedSubjects();
  }, [alreadyPostedSubjects]);

  const getTeachers = async () => {
    try {
      const response = await fetch(`${server1}/admins/get_all_teachers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });

      if (response.ok) {
        // User creation succeeded
        const responseData = await response.json();
        console.log("teachers:", responseData.data);
        setAllTeachers(responseData.data);
      } else {
        // User creation failed
        formError("could not get teachers");
      }
    } catch (error) {
      formError("could not get teachers");
    }
  };

  const getSubjects = async () => {
    try {
      const response = await fetch(`${server1}/admins/get_all_subjects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setAllSubjects(responseData.data);
      } else {
        formError("could not get subjects");
      }
    } catch (error) {
      formError("could not get subjects");
    }
  };

  const selectedSubjects = [];
  const handleShowSelectedSubjects = () => {
    for (let index = 0; index < currentSubjectValues.length; index++) {
      selectedSubjects.push(
        <div style={{ color: "#1E3F66", fontSize: 13, marginTop: 5 }}>
          <span style={{ marginRight: 20 }}>{`${currentSubjectValues[index].name}`}</span>
          <button
            style={{
              background: "#1E3F66",
              color: "white",
              border: "none",
              padding: 3,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={() => {
              removeSubject(currentSubjectValues[index]._id);
            }}
          >
            remove
          </button>
        </div>
      );
    }

    setSelectedSubjectsState(selectedSubjects);
  };

  useEffect(() => setOpacity(1), []);
  useEffect(() => {
    handleShowSelectedSubjects();
  }, [currentSubjectValues]);

  useEffect(() => {
    getTeachers();
    getSubjects();
  }, [addedTeachers]);

  // useEffect(() => {
  //   console.log('done');

  // }, [allTeachers]);

  useEffect(() => {
    handleShowSelectedSubjects();
  }, [alreadyPostedSubjects]);

  const handleFormTeacherSelect = (newTemp) => {
    setCurrentFormTeacher(newTemp._id);
    setFormTeacherValue("");
    setShowFormTeacherBlock(true);
    setFormTeacherBlock(
      `${newTemp.firstName} ${newTemp.middleName ? newTemp.middleName : newTemp.lastName} ${
        newTemp.middleName ? newTemp.lastName : ""
      }`
    );
    setSearchTeachers([]);
  };

  const closeAndRemoveFormTeacher = () => {
    setFormTeacherValue("");
    setShowFormTeacherBlock(false);
    setFormTeacherBlock("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      listRef.current.focus();
    }
  };

  const [opacity, setOpacity] = useState(0);

  const handleClassNameChange = (value) => {
    setClassNameValue(value);
  };

  //Updating Teacher Inputs
  const handleFormTeacherChange = (newValue) => {
    if (newValue == "") {
      setSearchTeachers([]);
    } else {
      for (let index = 0; index < allTeachers.length; index++) {
        var firstSecond = `${allTeachers[index].firstName} ${allTeachers[index].middleName}`;
        var firstLast = `${allTeachers[index].firstName} ${allTeachers[index].lastName}`;
        var lastFirst = `${allTeachers[index].lastName} ${allTeachers[index].firstName}`;
        var lastMiddle = `${allTeachers[index].lastName} ${allTeachers[index].middleName}`;
        var middleFirst = `${allTeachers[index].middleName} ${allTeachers[index].firstName}`;
        var middleLast = `${allTeachers[index].middleName} ${allTeachers[index].lastName}`;
        var allOrder = `${allTeachers[index].firstName} ${allTeachers[index].middleName} ${allTeachers[index].lastName}`;
        var firstMiddleLast = `${allTeachers[index].firstName} ${allTeachers[index].middleName} ${allTeachers[index].lastName}`;
        var firstLastMiddle = `${allTeachers[index].firstName} ${allTeachers[index].lastName} ${allTeachers[index].middleName}`;
        var middleFirstLast = `${allTeachers[index].middleName} ${allTeachers[index].firstName} ${allTeachers[index].lastName}`;
        var middleLastFirst = `${allTeachers[index].middleName} ${allTeachers[index].lastName} ${allTeachers[index].firstName}`;
        var lastFirstMiddle = `${allTeachers[index].lastName} ${allTeachers[index].firstName} ${allTeachers[index].middleName}`;
        var lastMiddleFirst = `${allTeachers[index].lastName} ${allTeachers[index].middleName} ${allTeachers[index].firstName}`;
        var first = allTeachers[index].firstName.includes(newValue);
        var middle = allTeachers[index].middleName.includes(newValue);
        var last = allTeachers[index].lastName.includes(newValue);
        var firstSecondCheck = firstSecond.toLowerCase().includes(newValue);
        var firstLastCheck = firstLast.toLowerCase().includes(newValue);
        var lastFirstCheck = lastFirst.toLowerCase().includes(newValue);
        var lastMiddleCheck = lastMiddle.toLowerCase().includes(newValue);
        var middleFirstCheck = middleFirst.toLowerCase().includes(newValue);
        var middleLastCheck = middleLast.toLowerCase().includes(newValue);
        var allOrderCheck = allOrder.toLowerCase().includes(newValue);
        var firstMiddleLastCheck = firstMiddleLast.toLowerCase().includes(newValue);
        var firstLastMiddleCheck = firstLastMiddle.toLowerCase().includes(newValue);
        var middleFirstLastCheck = middleFirstLast.toLowerCase().includes(newValue);
        var middleLastFirstCheck = middleLastFirst.toLowerCase().includes(newValue);
        var lastFirstMiddleCheck = lastFirstMiddle.toLowerCase().includes(newValue);
        var lastMiddleFirstCheck = lastMiddleFirst.toLowerCase().includes(newValue);

        if (
          first ||
          middle ||
          last ||
          firstSecondCheck ||
          firstLastMiddleCheck ||
          middleFirstLastCheck ||
          middleLastFirstCheck ||
          lastFirstMiddleCheck ||
          lastMiddleFirstCheck ||
          firstLastCheck ||
          lastFirstCheck ||
          lastMiddleCheck ||
          middleFirstCheck ||
          middleLastCheck ||
          allOrderCheck ||
          firstMiddleLastCheck
        ) {
          temp = [...temp, allTeachers[index]];
        }
      }

      if (temp.length > 0) {
        temp.forEach((newTemp) => {
          teacherData.push(
            <div
              key={newTemp._id}
              style={handleMouseOver}
              onClick={() => {
                handleFormTeacherSelect(newTemp);
              }}
              onMouseEnter={setHoverBackground("#dddddd")}
              onMouseOut={setHoverBackground("#fff")}
            >
              {`${newTemp.firstName} ${
                newTemp.middleName ? newTemp.middleName : newTemp.lastName
              } ${newTemp.middleName ? newTemp.lastName : ""} ${
                newTemp.staffNumber ? newTemp.staffNumber : ""
              }`}
            </div>
          );
        });
      } else {
        teacherData.push(
          <div style={{ paddingLeft: 10, paddingBottom: 10 }}>No teacher found</div>
        );
      }
    }

    setSearchDropdown(true);
    setSearchTeachers(teacherData);
    setFormTeacherValue(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    submitted({
      name: classNameValue,
      teacher: currentFormTeacher,
      subjects: currentSubjects,
    });
  };

  useEffect(() => setOpacity(1), []);

  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ width: "430px", maxWidth: "100%" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <div style={{ padding: "0px 10px", marginBottom: "6px" }} className="formHeaderRow">
          <MDTypography variant="h6">Edit Class</MDTypography>
          <MDTypography onClick={cancelled}>
            <MDTypography onClick={cancelled}>
              <Icon style={{ cursor: "pointer" }}>cancel</Icon>
            </MDTypography>
          </MDTypography>
        </div>
        <form
          action=""
          method=""
          onSubmit={handleSubmit}
          className="form"
          style={{ paddingBottom: "15px" }}
        >
          <div className="rows">
            <MDInput
              type="text"
              value={classNameValue}
              onChange={(e) => handleClassNameChange(e.target.value)}
              label="Class Name"
              required
              fullWidth
            />
          </div>
          {formTeacherFormDisplay && (
            <div className="rows" style={{ paddingTop: "12px" }}>
              <div>
                {showFormTeacherBlock && (
                  <div
                    style={{
                      padding: 7,
                      fontSize: 13,
                      position: "absolute",
                      background: "blue",
                      borderRadius: 20,
                      color: "white",
                      zIndex: 2000,
                    }}
                  >
                    <span style={{ marginRight: 20 }}>{formTeacherBlock}</span>
                    <span
                      onClick={closeAndRemoveFormTeacher}
                      style={{
                        float: "right",
                        fontSize: 20,
                        marginTop: -6,
                        height: 12,
                        marginRight: 6,
                        cursor: "pointer",
                      }}
                    >
                      ×
                    </span>
                  </div>
                )}
                <MDInput
                  type={"text"}
                  label="search teacher"
                  value={formTeacherValue}
                  multiline={false}
                  rows={null}
                  onChange={(e) => handleFormTeacherChange(e.target.value)}
                  required={false}
                  fullWidth
                  autoComplete="off"
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div
                tabIndex={0}
                ref={listRef}
                style={{
                  outline: "none",
                  maxHeight: 500,
                  overflowY: "auto",
                  zIndex: 200,
                  background: "#ffffff",
                  fontSize: 14,
                  position: "absolute",
                  width: "91%",
                  marginTop: 90,
                  borderRadius: 5,
                }}
              >
                {temp.map((newTemp) => {
                  return (
                    <div key={newTemp._id} className="yh" style={{ position: "absolute" }}>
                      {`${newTemp.firstName} ${
                        newTemp.middleName ? newTemp.middleName : newTemp.lastName
                      } ${newTemp.middleName ? newTemp.lastName : ""}`}
                    </div>
                  );
                })}
                {searchTeachers}
              </div>
            </div>
          )}
          {classSubjectFormDisplay && (
            <div className="rows" style={{ paddingTop: "12px" }}>
              <div style={{ width: "100%" }}>
                <div
                  tabIndex={0}
                  ref={listRef}
                  style={{
                    outline: "none",
                    maxHeight: 500,
                    overflowY: "auto",
                    zIndex: 200,
                    background: "#ffffff",
                    fontSize: 14,
                    position: "absolute",
                    width: "91%",
                    marginTop: 45,
                    borderRadius: 5,
                  }}
                >
                  {/* {subjectTemp.map((newTemp)=>{
                  return <div key={newTemp._id} className="yh" style={{position: 'absolute'}}>
                     `${newTemp.name}`
                  </div>
                })} */}
                  {searchSubjects}
                </div>
                <MDInput
                  type={"text"}
                  label="search subject"
                  value={subjectValue}
                  multiline={false}
                  rows={null}
                  onChange={(e) => handleSubjectChange(e.target.value)}
                  required={false}
                  fullWidth
                  autoComplete="off"
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div style={{ color: "#702963", marginBottom: 50 }}>
                <h6>Selected subjects</h6>
                {currentSubjectValues.map((subject) => {
                  <div>
                    <div>{`${subject.name}`}</div>
                    <button
                      onClick={() => {
                        removeSubject(subject._id);
                      }}
                    >
                      remove
                    </button>
                  </div>;
                })}
                {selectedSubjectsState}
                <div style={{ color: "#702963", marginBottom: 50 }}>
                  <h6>class subjects</h6>
                  {alreadyPostedSubjectsDisplay}
                </div>
              </div>
            </div>
          )}
          <div className="rows">
            <MDButton
              type="submit"
              variant="contained"
              sx={{ mx: 1 }}
              color="dark"
              value={classNameValue}
              onChange={(e) => setClassNameValue(e.target.value)}
              fullWidth
            >
              <Icon>add</Icon>&nbsp;Submit
            </MDButton>
          </div>
        </form>
      </Card>
    </div>
  );
}

EditClassForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
  formError: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  otherValues: PropTypes.object.isRequired,
};
