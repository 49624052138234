/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import FormTeacherResults from "layouts/formTeacherResults";
import Home from "layouts/home";
import ManageAdmin from "layouts/settings/manageAdmin";
import ManageFormTeacher from "layouts/settings/manageFormTeacher";
import ManageParent from "layouts/settings/manageParents";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import CreateNews from "layouts/publishNews";

// @mui icons
import Icon from "@mui/material/Icon";
import News from "layouts/news";
import ManageSubject from "layouts/settings/manageSubject";
import ManageStudents from "layouts/settings/manageStudents";
import ManageClasses from "layouts/settings/manageClasses";
import Pass from "layouts/settings/pass";
import ManagePsychomotorSkills from "layouts/settings/managePsychomotorSkills";
import AcademicReports from "layouts/academicReports";
import MyChildrensResult from "layouts/myChildrensResults";
import ManageResultSubmission from "layouts/manageResultsSubmissions";
import TeachersClassStudents from "layouts/classStudents";
import TeacherSubjectScores from "layouts/teacherSubjectScores";
import AdminSettings from "layouts/adminSettings";

// Custome
import { StudentClass } from "layouts/settings/manageStudents/studentClass";
import ManagePin from "layouts/settings/managePin";
import ManageWithdrawnStudents from "layouts/settings/manageWithdrawnStudents";
import ManageWithdrawnParent from "layouts/settings/managaWithdrawnStudentParents";
import ManageAluminis from "layouts/settings/manageAluminis";
import { StudentAluminiClass } from "layouts/settings/manageAluminis/classdetails";

const routes = [
  {
    type: "home",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    component: <Home />,
  },
  // {
  //   type: "general",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  {
    type: "general",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "admin",
    name: "Term & session",
    key: "termsession",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/session",
    component: <AdminSettings />,
  },
  {
    type: "admin",
    name: "Publish News",
    key: "publishNews",
    icon: <Icon fontSize="small">publish news</Icon>,
    route: "/publishNews",
    component: <CreateNews />,
  },
  {
    type: "user",
    name: "News",
    key: "news",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/news",
    component: <News />,
  },
  {
    type: "parents",
    name: "My Children's Results",
    key: "myChildrensResults",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/myChildrensResults",
    component: <MyChildrensResult />,
  },
  // {
  //   type: "teacher",
  //   name: "My Class Pupils",
  //   key: "myClassPupils",
  //   icon: <Icon fontSize="small">persons</Icon>,
  //   route: "/my-class-pupils",
  //   component: <TeachersClassStudents />,
  // },
  {
    type: "teacher",
    name: "My Subjects Pupils",
    key: "mySubjectsPupils",
    icon: <Icon fontSize="small">persons</Icon>,
    route: "/my-subjects-pupils",
    component: <TeacherSubjectScores />,
  },
  {
    type: "pupil",
    name: "My Subjects Pupils",
    key: "mySubjectsPupils",
    icon: <Icon fontSize="small">students</Icon>,
    route: "/news",
    component: <Dashboard />,
  },
  {
    type: "pupil",
    name: "My Academic Results",
    key: "myAcademicResults",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/my-academic-results",
    component: <Dashboard />,
  },
  {
    type: "staff",
    name: "Academic Results",
    key: "academicResults",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/academic-results",
    component: <FormTeacherResults />,
  },
  {
    type: "admin",
    name: "Academic Reports",
    key: "academicReports",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/academic-reports",
    component: <AcademicReports />,
  },
  {
    type: "admin",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    caret: <Icon fontSize="small">expand_more</Icon>,
  },
  {
    type: "admin",
    name: "Manage Results",
    key: "manage-result-submission",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/manage-result-submission",
    component: <ManageResultSubmission />,
  },
  {
    type: "admin",
    name: "General Accounting",
    key: "settings",
    icon: <Icon fontSize="small">book</Icon>,
  },
  // {
  //   type: "admin",
  //   name: "all passwords",
  //   key: "allpasswords",
  //   icon: <Icon fontSize="small">passwords</Icon>,
  //   route: "/passes/",
  //   component: <Pass />,
  // },
  {
    type: "generalend",
    name: "Log Out",
    key: "log-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/",
    component: <SignIn />,
  },
  {
    type: "auth",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/",
    component: <SignIn />,
  },
  {
    type: "manage",
    name: "Manage Admins",
    key: "manage-Admins",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/manage-admins",
    component: <ManageAdmin />,
  },
  {
    type: "manage",
    name: "Manage Subjects",
    key: "manage-subject",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/manage-subjects",
    component: <ManageSubject />,
  },
  {
    type: "manage",
    name: "Manage Classes",
    key: "manage-classes",
    icon: <Icon fontSize="small">class</Icon>,
    route: "/manage-classes",
    component: <ManageClasses />,
  },
  {
    type: "manage",
    name: "Manage Teachers",
    key: "manage-form-teachers",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/manage-teachers",
    component: <ManageFormTeacher />,
  },
  {
    type: "manage",
    name: "Manage Parents",
    key: "manage-parents",
    icon: <Icon fontSize="small">persons</Icon>,
    route: "/manage-parents",
    component: <ManageParent />,
  },
  {
    type: "manage",
    name: "Manage Students",
    key: "manage-students",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/manage-students",
    component: <StudentClass />,
  },
  {
    type: "manage",
    name: "Manage students",
    key: "Manage all Students ",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/manage-students/:id",
    component: <ManageStudents />,
  },
  {
    type: "manage",
    name: "Console",
    key: "Manage Pins",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/console",
    component: <ManagePin />,
  },
  {
    type: "manage",
    name: "Withdrawn students",
    key: "Manage withdrawn students",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/withdrawn_students",
    component: <ManageWithdrawnStudents />,
  },
  {
    type: "manage",
    name: "Manage Aluminis",
    key: "Manage Aluminis",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/aluminis",
    component: <StudentAluminiClass />,
  },
  {
    type: "manage",
    name: "Manage Aluminis",
    key: "Manage all Aluminis",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/aluminis/:id",
    component: <ManageAluminis />,
  },
  // {
  //   type: "manage",
  //   name: "Withdrawn students parents",
  //   key: "Manage withdrawn Parents",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/withdrawn_students_parents",
  //   component: <ManageWithdrawnParent />,
  // },
  // {
  //   type: "manage",
  //   name: "Manage Skills",
  //   key: "manage-psychomotor-skills",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/manage-psychomotor-skills",
  //   component: <ManagePsychomotorSkills />,
  // },
];

export default routes;
