/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
  =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import Loading from "components/loadingComponent";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { LoginContext } from "../../App";

//Broadsheet Dummy datas
import { broadSheetDummyData } from "./data/broadSheetData";
import { server } from "utils/server";

import BroadSheet from "./components/broadSheet";

function FormTeacherResults() {
  const [isClicked, setIsClicked] = useState(0);
  const [adminState, setAdminState] = useState(false);
  const [teacherState, setTeacherState] = useState(true);
  const [staffState, setStaffState] = useState(false);
  const [parentState, setParentState] = useState(false);
  const [pupilState, setPupilState] = useState(false);
  const [pupilData, setPupilData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [userState, setUserState] = useState(true);
  const [generalEndState, setGeneralEndState] = useState(true);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [skills, setSkills] = useState([]);
 
  const [broadsheetRendering, setBroadsheetRendering] = useState([]);
  const [renderSheets, setRenderSheets] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [renderCreate, setRenderCreate] = useState(false);
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [num, setNum] = useState(0);
  const navigation = useNavigate();
  const [done, setDone] = useState(false);
  var isAdmin= true
  const { server1 } = server()

  const getClassResults = async () => {
    
     const response = await fetch(`${server1}/teacher/get_class_scores`, {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
         "sds": cookie.sds_auth_token



         
       },
     });
     const responseData = await response.json();
     if (response.ok) {
       setStudents(responseData.data)
       setSubjects(responseData.subjects)
       setClasses(responseData.classes)
       setSkills(responseData.skills)
       
       
     } else {
       console.log('else')
     }
   };
   

  const broadsheetsArray= []
  const renderBroadSheets= (classesData, studentsData, subjectsData)=>{
   
    // forCreate={renderCreate}
    for (let index = 0; index < classesData.length; index++) {
      var temp= {
        studentArr: studentsData[index],
        class: classesData[index],
        subjects: subjectsData[index],
        skills: skills,
      }
      broadsheetsArray.push(
       ( <BroadSheet data={temp} key={num} counter={index} showLoad={(par)=>{setIsLoading(par)}} reload={(num)=>{setNum(num)}} isClicked={isClicked} />)
      )
      
    }
    
    setBroadsheetRendering(broadsheetsArray)
    setRenderSheets(true)
    
  }

useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
    setIsLoading(true)
    getClassResults().then(()=>{
      setIsLoading(false)
    })
     
  //checkLogged();
}, []);

// useEffect(() => {
//   if(done){
//     setIsLoading(false)
//   }
// }, [done]);


useEffect(() => {
  getClassResults().then(()=>{
    setIsLoading(false)
  })
}, [num]);


useEffect(() => {
  
  renderBroadSheets(classes, students, subjects)
//checkLogged();
}, [classes]);

  return (
    <DashboardLayout >
      {isLoading && <Loading />}
        {alertDisplay && (
          <div
            style={{
              background: alertType === "error" ? "#d31a38" : "green",
              width: 350,
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}
      <DashboardNavbar />
      {teacherState && (
        <div style={{ width: "100%", height: "auto" }}>
          <MDTypography variant="h6">Class BroadSheet</MDTypography>
          <div>
          {renderSheets && broadsheetRendering}
          </div>
          {/* {classes.map((classDetail)=>{
            <BroadSheet data={classDetail} isClicked={isClicked} /> 
          })} */}
          {/* <BroadSheet data={broadSheetDummyData[0]} isClicked={isClicked} /> */}
          {/* <MDBox paddingTop="10px">
            <MDButton color="dark" onClick={() => setIsClicked(Math.random())}>
              <Icon>publish</Icon>&nbsp;Publish
            </MDButton>
          </MDBox> */}
        </div>
      )}
    </DashboardLayout>
  );
}

export default FormTeacherResults;
