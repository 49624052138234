import "../../index.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";

//routes
import routes from "routes";

import { useState, useEffect, useContext } from "react";

import { LoginContext } from "App";

export default function SettingsLinks() {
  //Filtering the admin subMenu routes
  const subMenus = routes.filter((route) => route.type === "manage");
  const [transform, setTransform] = useState("translateY(-12px)");
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const navigation = useNavigate();

  useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
    setTransform("translateY(0px)");
  }, []);

  return (
    <div style={{ transform: transform, transition: "0.5s", paddingLeft: "10px" }}>
      <MDBox
        sx={{ gap: 1, mt: 0.5 }}
        width="100%"
        display="flex"
        flexDirection="column"
        paddingLeft="30px"
      >
        {subMenus.map((menu) => {
          return (
            <NavLink key={menu.key} to={menu.route}>
              <div className="subMenuLinks">
              <div style={{ transform: "rotate(-90deg)" }}>
                  <MDTypography color="light">
                    <Icon fontSize="small">expand_more</Icon>
                  </MDTypography>
                </div>
                <MDTypography variant="overline" color="light" fontWeight="regular">
                  {menu.name}
                </MDTypography>
              </div>
            </NavLink>
          );
        })}
      </MDBox>
    </div>
  );
}
