import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import { useState } from "react";
import { server } from "utils/server";
import { Link } from "react-router-dom";

import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useCookies } from "react-cookie";

export default function PinInput({ id, cancel }) {
  const [pin, setPin] = useState("");
  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { server1 } = server();
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  // console.log({id})

  const handleCheckResult = async () => {
    try {
      setLoading(false);
      setError("");
      setSuccess("");
      setSuccessMessage("");
      if (!pin) return setError("Enter Pin");
      if (pin.length < 10) return setError(`Invalid Pin, Pin must be 10 digit not ${pin.length}`);
      if (pin.length > 10) return setError(`Invalid Pin, Pin must be 10 digit not ${pin.length}`);
      if (pin) {
        setLoading(true);
        const response = await fetch(`${server1}/admins/check_pin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            sds: cookie.sds_auth_token,
          },
          body: JSON.stringify({
            pin,
          }),
        });
        const responseData = await response.json();
        if (responseData.status === "success") {
          setSuccessMessage(responseData.message);
          setLoading(false);
          setSuccess(true);
        } else {
          setLoading(false);
          setError(responseData.message);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <form
      style={{
        position: "fixed",
        zIndex: "1000",
        width: "85%",
        height: "97%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
        paddingBottom: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: "500px",
          padding: "20px",
          position: "relative",
          top: "-50px",
        }}
      >
        <MDBox style={{ textAlign: "center" }}>
          <MDTypography variant="h6">Enter PIN</MDTypography>
          <p style={{ fontSize: "15px" }}>enter pin to view your child result</p>
          {error && <p style={{ color: "red", fontSize: "20px" }}>{error}</p>}
          {success && <p style={{ color: "green", fontSize: "20px" }}>{successMessage}</p>}
          {!success && (
            <MDBox style={{ marginBottom: "20px", marginTop: "20px" }}>
              <input
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid lightgray",
                  textIndent: "10px",
                }}
                type="number"
                onChange={(e) => setPin(e.target.value)}
                value={pin}
              />
            </MDBox>
          )}
          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {!success && !loading && (
              <MDButton variant="contained" color="info" onClick={() => handleCheckResult()}>
                Check Pin
              </MDButton>
            )}
            {success && (
              <Link to={`/myChildrensResults/${id}`}>
                <MDButton variant="contained" color="info">
                  Check Result
                </MDButton>
              </Link>
            )}
            {loading && (
              <MDButton variant="contained" color="info">
                Checking... Pin
              </MDButton>
            )}
            {!loading && (
              <MDButton variant="contained" color="error" onClick={() => cancel()}>
                cancel
              </MDButton>
            )}
            {loading && (
              <MDButton variant="contained" color="error" onClick={() => cancel()}>
                ...
              </MDButton>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </form>
  );
}

PinInput.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
