import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import Loading from "components/loadingComponent";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";

import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormTeacherForm from "./components/formTeacherForm";
import EditTeacher from "./components/editTeacher";
import { LoginContext } from "../../../App";
import { server } from "utils/server";

export default function ManageFormTeacher() {
  const [formDisplay, setFormDisplay] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [teacherData, setTeacherData] = useState(null);
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [allTeachers, setAllTeachers] = useState([]);
  const [valueToEdit, setValueToEdit] = useState("");
  const [idToEdit, setIdToEdit] = useState("");
  const [otherInfosForEdit, setOtherInfosForEdit] = useState(null);
  const [allClassesSubjects, setAllClassesSubjects] = useState(null);
  const [goFrontEnabled, setGoFrontEnabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [goBackEnabled, setGoBackEnabled] = useState(false);
  const [paginationCounter, setPaginationCounter] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [pages, setPages] = useState(1);
  const [serialNo, setSerianNo] = useState(null);
  const { server1 } = server();

  const [renderEdit, setRenderEdit] = useState(false);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);

  const navigation = useNavigate();

  const getTeachers = async (index) => {
    setIsLoading(true);
    const response = await fetch(`${server1}/admins/get_all_teachers/` + index, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setSerianNo(index * 20 - 20);
      // setPaginationCounter(Math.ceil(responseData.pages/5))
      setPages(responseData.pages);
      var newPaginationCounter = Math.ceil(responseData.pages / 5);
      setAllTeachers(responseData.data);
      setOtherInfosForEdit(responseData.infos);
      setAllClassesSubjects(responseData.allClassesSubjects);
    } else {
      // User creation failed
      setAlertMessage("could not get classes");
      setAlertType("error");
      setAlertDisplay(true);
      //console.error("User creation failed:", errorData.error);
    }
  };

  const editUser = async (data, data1, data2) => {
    setRenderEdit(false);
    var newData = {
      data: data,
      subjects: data1,
      toRemove: data2,
    };
    const response = await fetch(`${server1}/admins/update_teacher/${idToEdit}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
      body: JSON.stringify(newData),
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded
      setAlertMessage("User(s) updated successfully");
      setAlertType("success");
      setTeacherData(msg.body);
      setAlertDisplay(true);
      getTeachers()
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
      //const responseData = await response.json();
    } else {
      // User creation failed
      setAlertMessage(msg.message);
      setAlertType("error");
      setAlertDisplay(true);
      // const errorData = await response.json();
      // console.log(errorData);
      // console.error("User creation failed:", errorData.error);
    }
  };

  const deleteTeacher = async (id) => {
    setRenderEdit(false);
    const response = await fetch(`${server1}/admins/delete_user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded
      setAlertMessage("User deleted successfully");
      setAlertType("success");
      setTeacherData(msg.body);
      setAlertDisplay(true);

      getTeachers()
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
      //const responseData = await response.json();
    } else {
      // User creation failed
      setAlertMessage(msg.message);
      setAlertType("error");
      setAlertDisplay(true);

      // const errorData = await response.json();
      // console.log(errorData);
      // console.error("User creation failed:", errorData.error);
    }
  };

  const displayDropDownMenu = (id) => {
    const elem = document.getElementById(id);
    elem.style.display = elem.style.display === "none" ? "flex" : "none";
  };

  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
    getTeachers()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    valueToEdit !== "" && setRenderEdit(true);
  }, [valueToEdit]);

  useEffect(() => {
    !renderEdit && setValueToEdit("");
  }, [renderEdit]);

  const createUser = async (userData) => {
    setFormDisplay(false);
    setIsLoading(true);
    try {
      const response = await fetch(`${server1}/admins/create_teacher`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify({
          username: userData.username,
          password: userData.password,
          firstName: userData.firstName,
          middleName: userData.middleName,
          lastName: userData.lastName,
          email: userData.email,
          staffNumber: userData.staffNumber,
          address: userData.address,
          permanentAddress: userData.permanentAddress,
          phone: userData.phone,
          dateOfbirth: userData.dateOfbirth,
          state: userData.state,
          lga: userData.lga,
          class: userData.class,
          guarantorA: userData.guarantorA,
          guarantorAOccupation: userData.guarantorAOccupation,
          guarantorAAdress: userData.guarantorAAdress,
          guarantorAPhone: userData.guarantorAPhone,
          guarantorB: userData.guarantorB,
          guarantorBOccupation: userData.guarantorBOccupation,
          guarantorBAdress: userData.guarantorBAdress,
          guarantorBPhone: userData.guarantorBPhone,
          subjects: userData.subjects,
          passport: userData.passport,
          idCard: userData.idCard,
        }),
      });
      var msg = await response.json();
      if (response.ok) {
        // User creation succeeded
        setAlertMessage("Teacher created successfully");
        setAlertType("success");
        setTeacherData(msg.body);
        setAlertDisplay(true);
        getTeachers()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
        setFormDisplay(false);
        //const responseData = await response.json();
      } else {
        // User creation failed
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
        // const errorData = await response.json();
        // console.log(errorData);
        // console.error("User creation failed:", errorData.error);
      }
      setIsLoading(false);
      // setFormDisplay(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setAlertMessage("Could not create Teacher");
      setAlertType("error");
      setAlertDisplay(true);
      setIsLoading(false);
    }
  };

  const handleCounter = (index) => {
    setPaginationCounter(index);
    showPagination(index);
  };

  const handlePagination = (index) => {
    setCurrentPage(index);
    setIsLoading(true);
    getTeachers(index).then(() => {
      // showPagination(paginationCounter)
      setIsLoading(false);
    });
  };

  useEffect(() => {
    showPagination(paginationCounter);
  }, [currentPage]);
  useEffect(() => {
    showPagination(paginationCounter);
  }, [paginationCounter, pages]);
  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
    getTeachers(currentPage)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  const showPagination = (counter) => {
    var paginationArray = [];
    if (counter == 1) {
      setGoBackEnabled(false);
    } else {
      setGoBackEnabled(true);
    }
    var paginationCounterTemp = Math.ceil(pages / 5);
    if (counter == paginationCounterTemp) {
      setGoFrontEnabled(false);
    } else {
      setGoFrontEnabled(true);
    }
    var start = counter * 5 - 5;
    var stop = counter * 5;
    if (pages < counter * 5) {
      var stop = pages;
    }
    for (let index = start; index < stop; index++) {
      if (index + 1 == currentPage) {
        paginationArray.push(<div style={{ fontSize: 13, margin: 5 }}>{index + 1}</div>);
      } else {
        paginationArray.push(
          <button
            style={{
              borderColor: "#344767",
              color: "#344767",
              borderStyle: "solid",
              width: 30,
              height: 30,
              margin: 3,
              cursor: "pointer",
            }}
            onClick={() => {
              handlePagination(index + 1);
            }}
          >
            {index + 1}
          </button>
        );
      }
    }
    setPagination(paginationArray);
  };

  return (
    <>
      {isLoading && <Loading />}
      <DashboardLayout>
        {alertDisplay && (
          <div
            style={{
              background: alertType === "error" ? "#d31a38" : "green",
              width: 300,
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}
        <DashboardNavbar zIndex={formDisplay ? -1 : 1} />
        <MDBox sx={{ mx: 2 }}>
          <MDButton variant="contained" color="dark" onClick={() => setFormDisplay(true)}>
            <Icon>add</Icon> Add Teacher
          </MDButton>
        </MDBox>
        {formDisplay && (
          <FormTeacherForm
            cancelled={() => setFormDisplay(false)}
            submitted={createUser}
            allClassesSubjects={allClassesSubjects}
          />
        )}
        {renderEdit && (
          <EditTeacher
            initialData={valueToEdit}
            cancelled={() => setRenderEdit(false)}
            submitted={editUser}
            otherInfosForEdit={otherInfosForEdit}
            allClassesSubjects={allClassesSubjects}
          />
        )}
        {allTeachers.length > 0 && (
          <Card sx={{ p: 2, mt: 2 }}>
            <MDBox display="flex" flexDirection="column">
              <MDBox
                width="100%"
                // maxWidth="450px"
                display="flex"
                justifyContent="space-between"
                marginBottom="4px"
                padding="0px 0px 8px 14px"
                borderBottom="1px solid gray"
              >
                <MDTypography variant="h6">Descriptions</MDTypography>
                <MDTypography variant="h6">Actions</MDTypography>
              </MDBox>
              {allTeachers &&
                allTeachers.map((data) => {
                  const id = `${data.firstName}${data.lastName}${Math.random()}`;
                  return (
                    <div className="itemList" key={data._id}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <MDTypography variant="button" fontWeight="regular">
                          {allTeachers.indexOf(data) + 1}.
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="medium">
                          &nbsp;&nbsp;&nbsp;
                          {`${data.firstName} ${
                            data.middleName ? data.middleName : data.lastName
                          } ${data.middleName ? data.lastName : ""}`}
                        </MDTypography>
                      </div>
                      <div className="itemListDropdown">
                        <button onClick={() => displayDropDownMenu(id)}>
                          <MDTypography variant="button" fontWeight="medium">
                            <Icon>more_vert</Icon>
                          </MDTypography>
                        </button>
                        <div className="itemListMenu" id={id}>
                          <Card
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              overflow: "hidden",
                              zIndex: 5,
                            }}
                          >
                            <div
                              className="itemListContent"
                              onClick={() => {
                                displayDropDownMenu(id);
                                setValueToEdit(data);
                                setIdToEdit(data._id);
                              }}
                            >
                              <MDTypography
                                variant="overline"
                                fontWeight="medium"
                                color="dark"
                                display="flex"
                                alignItems="center"
                              >
                                <Icon>edit</Icon>&nbsp;Edit
                              </MDTypography>
                            </div>
                            <div
                              className="itemListContent"
                              onClick={() => {
                                displayDropDownMenu(id);
                                setValueToEdit(data);
                              }}
                            >
                              <MDTypography
                                variant="overline"
                                fontWeight="medium"
                                color="dark"
                                display="flex"
                                alignItems="center"
                              >
                                <Icon>manage</Icon>&nbsp;View
                              </MDTypography>
                            </div>
                            <div
                              className="itemListContent"
                              onClick={() => {
                                deleteTeacher(data._id);
                              }}
                            >
                              <MDTypography
                                variant="overline"
                                fontWeight="medium"
                                color="error"
                                display="flex"
                                alignItems="center"
                              >
                                <Icon>delete</Icon>&nbsp;Delete
                              </MDTypography>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </MDBox>
          </Card>
        )}
        {pagination && (
          <div style={{ display: "flex", marginTop: 20 }}>
            {goBackEnabled && (
              <button
                style={{
                  borderColor: "#344767",
                  borderStyle: "solid",
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                  margin: 3,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleCounter(paginationCounter - 1);
                }}
              >
                {"<<"}
              </button>
            )}
            {!goBackEnabled && (
              <button
                disabled
                style={{
                  borderColor: "grey",
                  borderStyle: "solid",
                  width: 30,
                  height: 30,
                  color: "grey",
                  margin: 3,
                }}
              >
                {"<<"}
              </button>
            )}
            {pagination && pagination}
            {goFrontEnabled && (
              <button
                style={{
                  borderColor: "#344767",
                  borderStyle: "solid",
                  width: 30,
                  height: 30,
                  margin: 3,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleCounter(paginationCounter + 1);
                }}
              >
                {">>"}
              </button>
            )}
            {!goFrontEnabled && (
              <button
                disabled
                style={{
                  borderColor: "grey",
                  borderStyle: "solid",
                  width: 30,
                  height: 30,
                  margin: 3,
                }}
              >
                {">>"}
              </button>
            )}
          </div>
        )}
        <div style={{ textAlign: "center" }}>page {currentPage}</div>
      </DashboardLayout>
    </>
  );
}
