import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useParams } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import MDTypography from "components/MDTypography";

//dummy data
import ResultComponent from "layouts/myChildrensResults/components/resultComponent";
import { resultDummyData } from "layouts/myChildrensResults/data";
import schoolImage from "assets/images/sdslogo.png";
//import { LoginContext } from "../../App";

export default function ChildResult() {
  const { result } = useParams();
  const resultData = resultDummyData.filter((data) => data.id === result);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  //const [isLoggedIn, setIsLoggedIn]= useContext(LoginContext)
  const navigation= useNavigate()

  useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
  }, []);

  return (
    <DashboardLayout>
    <img src={schoolImage} style={{ width: "100px", height: "100px"}}/>
      <MDTypography variant="h6">Sundream International School</MDTypography>
      <div style={{fontSize: 14}}>
       #8, Hon Malachy Ngozika Duru Avenue, <br/> 
       Area H, By Saga Suite, New Owerri, <br/>
       Imo State.
      </div>
      <div style={{fontSize: 14}}>
        info@sundreaminternational.sch.ng
      </div>
      <div style={{fontSize: 14, marginBottom: 25}}>
        +2348083522224
      </div>
      <ResultComponent resultData={result} />
    </DashboardLayout>
  );
}
