/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
  =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { LoginContext } from "../../App";

//Broadsheet Dummy datas
import { broadSheetDummyData } from "./data/broadSheetData";

import BroadSheet from "./components/broadSheet";

function Dashboard() {
  const [isClicked, setIsClicked] = useState(0);
  const [adminState, setAdminState] = useState(false);
  const [teacherState, setTeacherState] = useState(false);
  const [staffState, setStaffState] = useState(false);
  const [parentState, setParentState] = useState(false);
  const [pupilState, setPupilState] = useState(false);
  const [pupilData, setPupilData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [userState, setUserState] = useState(true);
  const [generalEndState, setGeneralEndState] = useState(true);
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const navigation = useNavigate();

  // const checkLogged = async () => {
  //   if(!cookie.sds_auth_token){
  //     navigation('/')
  //   } else {
  //     const response = await fetch("http://localhost:10000/v1/user/get_user", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         id: isLoggedIn,
  //       }),
  //     });
  //     const responseData = await response.json();
  //     if (response.ok) {
  //       if (responseData.data.student) {
  //         setPupilState(true);
  //         setPupilData(responseData.data.student);
  //       }
  //       if (responseData.data.admin) {
  //         setAdminState(true);
  //         setAdminData(responseData.data.admin);
  //       }
  //       if (responseData.data.teacher) {
  //         setTeacherState(true);
  //         setTeacherData(responseData.data.teacher);
  //       }
  //       if (responseData.data.parent) {
  //         setParentState(true);
  //         setParentData(responseData.data.parent);
  //       }

  //     }
  //   }
  // };

useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
  //checkLogged();
}, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>Coming soon...</div>
      {teacherState && (
        <div style={{ width: "100%", height: "auto" }}>
          <MDTypography variant="h6">Class BroadSheet</MDTypography>
          <BroadSheet data={broadSheetDummyData[0]} isClicked={isClicked} />
          <MDBox paddingTop="10px">
            <MDButton color="dark" onClick={() => setIsClicked(Math.random())}>
              <Icon>publish</Icon>&nbsp;Publish
            </MDButton>
          </MDBox>
        </div>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
