import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"


export const StudentInfo = ({ action, cancel ,text, buttonText }) => {
    return (
        <div className="actions-components">
          <MDTypography variant='h6'>{text}</MDTypography>
          <div
                style={{ display:'flex', justifyContent:'space-between', marginTop:'10px' }}
              >
                <MDButton color='success' 
                onClick={() => action()}>
                    {buttonText ? buttonText : 'OK'}
                    </MDButton>
                <MDButton color='error' 
                  onClick={() => cancel()}
                >Cancel</MDButton>
              </div>
        </div>
    )
}