import PropTypes from "prop-types";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Cookies, useCookies } from "react-cookie";

import { useState, useRef } from "react";

import { initialParentData } from "layouts/settings/data";

import Icon from "@mui/material/Icon";
import { server } from "utils/server";

export default function Form({ InfoType, saved }) {
  const [buttonElements, setButtonElements] = useState(["add", `Save ${InfoType}`, "dark"]);
  const [parentForm, setParentForm] = useState(initialParentData);
  const [searchChildren, setSearchChildren] = useState([]);
  const [allChildren, setAllChildren] = useState([]);
  const [childValue, setChildValue] = useState("");
  const [childArray, setChildArray] = useState([]);
  const [currentChild, setCurrentChild] = useState("");
  const [hoverBackground, setHoverBackground] = useState("#fff");
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const listRef = useRef(null);
  const { server1 } = server()

  const handleOneChildAdd = () => {
    var newChild = [...childArray, currentChild];
    setChildArray(newChild);
  };

  const handleChildSelect = (newTemp) => {
    setCurrentChild(newTemp._id);
    setChildValue(
      `${newTemp.firstName} ${newTemp.middleName ? newTemp.middleName : newTemp.lastName} ${
        newTemp.middleName ? newTemp.lastName : ""
      }`
    );
    setSearchChildren([]);
  };

  //Handling the form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataOne = { Parent: InfoType };
    parentForm.forEach((value) => {
      dataOne[value.apiLabel] = value.value;
    });

    if (InfoType === "Father") {
      saved("Father", dataOne);
    } else {
      const dataTwo = { Parent: InfoType };
      parentForm.forEach((value) => {
        dataTwo[value.apiLabel] = value.value;
      });

      saved("Mother", dataTwo);
    }
  };

  const handleParentFormChange = (id, newValue) => {
    setParentForm((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item))
    );
  };
  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      listRef.current.focus();
    }
  };

  const teacherData = [];
  var temp = [];
  const handleMouseOver = {
    paddingLeft: 10,
    background: hoverBackground,
    cursor: "pointer",
    borderRadius: 5,
  };
  const handleChildChange = (newValue) => {
    if (newValue == "") {
      setSearchChildren([]);
    } else {
      
      for (let index = 0; index < allChildren.length; index++) {
        var firstSecond = `${allChildren[index].firstName} ${allChildren[index].middleName}`;
        var firstLast = `${allChildren[index].firstName} ${allChildren[index].lastName}`;
        var lastFirst = `${allChildren[index].lastName} ${allChildren[index].firstName}`;
        var lastMiddle = `${allChildren[index].lastName} ${allChildren[index].middleName}`;
        var middleFirst = `${allChildren[index].middleName} ${allChildren[index].firstName}`;
        var middleLast = `${allChildren[index].middleName} ${allChildren[index].lastName}`;
        var allOrder = `${allChildren[index].firstName} ${allChildren[index].middleName} ${allChildren[index].lastName}`;
        var firstMiddleLast = `${allChildren[index].firstName} ${allChildren[index].middleName} ${allChildren[index].lastName}`;
        var firstLastMiddle = `${allChildren[index].firstName} ${allChildren[index].lastName} ${allChildren[index].middleName}`;
        var middleFirstLast = `${allChildren[index].middleName} ${allChildren[index].firstName} ${allChildren[index].lastName}`;
        var middleLastFirst = `${allChildren[index].middleName} ${allChildren[index].lastName} ${allChildren[index].firstName}`;
        var lastFirstMiddle = `${allChildren[index].lastName} ${allChildren[index].firstName} ${allChildren[index].middleName}`;
        var lastMiddleFirst = `${allChildren[index].lastName} ${allChildren[index].middleName} ${allChildren[index].firstName}`;
        var first = allChildren[index].firstName.includes(newValue);
        var middle = allChildren[index].middleName.includes(newValue);
        var last = allChildren[index].lastName.includes(newValue);
        var firstSecondCheck = firstSecond.includes(newValue);
        var firstLastCheck = firstLast.includes(newValue);
        var lastFirstCheck = lastFirst.includes(newValue);
        var lastMiddleCheck = lastMiddle.includes(newValue);
        var middleFirstCheck = middleFirst.includes(newValue);
        var middleLastCheck = middleLast.includes(newValue);
        var allOrderCheck = allOrder.includes(newValue);
        var firstMiddleLastCheck = firstMiddleLast.includes(newValue);
        var firstLastMiddleCheck = firstLastMiddle.includes(newValue);
        var middleFirstLastCheck = middleFirstLast.includes(newValue);
        var middleLastFirstCheck = middleLastFirst.includes(newValue);
        var lastFirstMiddleCheck = lastFirstMiddle.includes(newValue);
        var lastMiddleFirstCheck = lastMiddleFirst.includes(newValue);

        if (
          first ||
          middle ||
          last ||
          firstSecondCheck ||
          firstLastMiddleCheck ||
          middleFirstLastCheck ||
          middleLastFirstCheck ||
          lastFirstMiddleCheck ||
          lastMiddleFirstCheck ||
          firstLastCheck ||
          lastFirstCheck ||
          lastMiddleCheck ||
          middleFirstCheck ||
          middleLastCheck ||
          allOrderCheck ||
          firstMiddleLastCheck
        ) {
          temp = [...temp, allChildren[index]];
        }
      }

      if (temp.length > 0) {
        temp.forEach((newTemp) => {
          teacherData.push(
            <div
              key={newTemp._id}
              style={handleMouseOver}
              onClick={() => {
                handleChildSelect(newTemp);
              }}
              onMouseEnter={setHoverBackground("#dddddd")}
              onMouseOut={setHoverBackground("#fff")}
            >
              {`${newTemp.firstName} ${
                newTemp.middleName ? newTemp.middleName : newTemp.lastName
              } ${newTemp.middleName ? newTemp.lastName : ""}`}
            </div>
          );
        });
      } else {
        teacherData.push(<div style={{ paddingLeft: 10, paddingBottom: 10 }}>No child found</div>);
      }
    }

    
    setSearchDropdown(true);
    setSearchChildren(teacherData);
    setChildValue(newValue);
  };

  const getChildren = async () => {
    try {
      const response = await fetch(`${server1}/admins/get_all_students`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "sds": cookie.sds_auth_token
        },
      });
      
      if (response.ok) {
        // User creation succeeded
        const responseData = await response.json();
        setAllChildren(responseData.data);
        
      } else {
        // User creation failed
        const errorData = await response.json();
        console.error("User creation failed:", errorData.error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: "flex", flexDirection: "column", gap: "12px", paddingBottom: "10px" }}
      action=""
      method="post"
    >
      <MDTypography variant="h6">{InfoType}</MDTypography>
      <div className="rows">
        {parentForm.map((data) => {
          return (
            <>
              {data.type !== "file" && data.type !== "date" && (
                <div style={{ width: `${data.fullWidth ? "100%" : "240px"}` }}>
                  <MDInput
                    key={data.id}
                    type={data.type}
                    label={data.label}
                    value={data.value}
                    multiline={data.multiline}
                    rows={data.multiline ? 5 : null}
                    fullWidth
                    onChange={(e) => handleParentFormChange(data.id, e.target.value)}
                    required={data.required}
                  />
                </div>
              )}

              {data.type === "file" && (
                <div key={data.id} className="rows" style={{ padding: "0px 0px 0px 40px" }}>
                  <MDTypography variant="button" display="flex" alignItems="center">
                    <Icon>upload</Icon>&nbsp; {data.label}
                  </MDTypography>
                  <div style={{ width: "240px" }}>
                    <input
                      type="file"
                      onChange={(e) => handleParentFormChange(data.id, e.target.files[0])}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              )}

              {data.type === "date" && (
                <div style={{ width: "100%" }}>
                  <MDTypography variant="button">Date Of Birth</MDTypography>
                  <MDInput
                    key={data.id}
                    type={data.type}
                    label={data.label}
                    value={data.value}
                    onChange={(e) => handleParentFormChange(data.id, e.target.value)}
                    fullWidth
                  />
                </div>
              )}
            </>
          );
        })}
        <div className="saveButton">
          <MDButton type="submit" variant="contained" color={buttonElements[2]} size="small">
            <MDTypography
              variant="button"
              fontWeight="medium"
              color="light"
              display="flex"
              alignItems="center"
            >
              <Icon>{buttonElements[0]}</Icon>&nbsp;{buttonElements[1]}
            </MDTypography>
          </MDButton>
        </div>
      </div>
    </form>
  );
}

Form.propTypes = {
  InfoType: PropTypes.string.isRequired,
  saved: PropTypes.func.isRequired,
};
