import PropTypes from "prop-types";
import { Cookies, useCookies } from "react-cookie";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { useEffect, useState } from "react";

import { initialStudentData } from "layouts/settings/data";

import Icon from "@mui/material/Icon";
import { server } from "utils/server";

export default function ChildrenForm({ pushChild, childNo }) {
  const [childrenForm, setChildrenForm] = useState(initialStudentData);
  const [buttonElements, setButtonElements] = useState(["add", "Save Child", "dark"]);
  const [allClasses, setAllClasses] = useState([]);
  const [classValue, setClassValue] = useState("");
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const { server1 } = server()
  const saveChild = (e) => {
    e.preventDefault();
    if (classValue === "" || classValue === "Select Class") {
      setButtonElements(["cancel", "Classes must be allocated", "error"]);
      setTimeout(() => {
        setButtonElements(["add", "Save Child", "dark"]);
      }, 1000);
      return;
    } else {
      setButtonElements(["check", "Saved", "success"]);
      const data = { Person: "Child", class: classValue };

      childrenForm.forEach((value) => {
        data[value.apiLabel] = value.value;
      });
      
      setTimeout(() => {
        pushChild(data);
      }, 500);
    }
  };

  const getClasses = async () => {
    const response = await fetch(`${server1}/admins/get_all_classes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setAllClasses(responseData.body);
      
    } else {
      // User creation failed
      const errorData = await response.json();
      console.error("User creation failed:", errorData.error);
    }
  };

  //Updating an Input
  const handleChildrenFormChange = (id, newValue) => {
    setChildrenForm((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item))
    );
  };

  useEffect(() => {
    getClasses();
  }, []);

  return (
    <>
      <MDTypography variant="h6">{"Child" + " " + childNo}</MDTypography>
      <form action="" method="post" className="rows" onSubmit={saveChild}>
        {childrenForm.map((data) => {
          return (
            <>
              {data.type !== "file" && data.type !== "date" && (
                <div style={{ width: `${data.fullWidth ? "100%" : "240px"}` }}>
                  <MDInput
                    key={data.id}
                    type={data.type}
                    label={data.label}
                    value={data.value}
                    required={data.required}
                    multiline={data.multiline}
                    rows={data.multiline ? 5 : null}
                    fullWidth
                    onChange={(e) => handleChildrenFormChange(data.id, e.target.value)}
                    disabled={data.disable}
                  />
                </div>
              )}
              {data.type === "file" && (
                <div key={data.id} className="rows" style={{ paddingLeft: "40px" }}>
                  <MDTypography variant="button" display="flex" alignItems="center">
                    <Icon>upload</Icon>&nbsp; {data.label}
                  </MDTypography>
                  <div style={{ width: "240px" }}>
                    <input
                      type="file"
                      onChange={(e) => handleChildrenFormChange(data.id, e.target.files[0])}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              )}
              {data.type === "date" && (
                <div style={{ width: "100%" }}>
                  <MDTypography variant="button">Date Of Birth</MDTypography>
                  <MDInput
                    key={data.id}
                    type={data.type}
                    label={data.label}
                    value={data.value}
                    onChange={(e) => handleChildrenFormChange(data.id, e.target.value)}
                    fullWidth
                  />
                </div>
              )}
            </>
          );
        })}
        <div className="rows" style={{ padding: "0px 0px 10px 20px" }}>
          <MDTypography variant="button">Select Class</MDTypography>
          <select
            style={{
              width: "240px",
              padding: "8px 4px",
              border: "1px solid gray",
              outline: "none",
              cursor: "pointer",
            }}
            onChange={(e) => setClassValue(e.target.value)}
          >
            <option style={{ color: "#666" }}>Select Class</option>
            {allClasses.map((classObject) => {
              return <option value={classObject._id}>{classObject.className}</option>;
            })}
          </select>
        </div>
        <div className="saveButton">
          <MDButton type="submit" variant="contained" color={buttonElements[2]} size="small">
            <MDTypography
              variant="button"
              fontWeight="medium"
              color="light"
              display="flex"
              alignItems="center"
            >
              <Icon>{buttonElements[0]}</Icon>&nbsp;{buttonElements[1]}
            </MDTypography>
          </MDButton>
        </div>
      </form>
    </>
  );
}

ChildrenForm.propTypes = {
  pushChild: PropTypes.func,
  childNo: PropTypes.number,
};
