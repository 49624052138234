/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import NotificationItem from "examples/Items/NotificationItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import coverImage from "assets/images/bg-profile.jpeg";
import backgroundImage from "assets/images/user.jpg";

function Header({ children, info }) {
  const [userDetails, setUserDetails] = useState({
    fullName: "Support Team",
    image: backgroundImage,
    userType: "Admin",
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const fileInputRef = useRef(null);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  //render menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>account_circle</Icon>} title="View Profie Picture" />
      <div style={{ position: "relative" }}>
        <NotificationItem
          icon={<Icon>upload</Icon>}
          title="Upload Profile Picture"
          onClick={() => fileInputRef.current.click()}
        />
        <input
          type="file"
          style={{
            width: "100%",
            position: "absolute",
            top: 0,
            zIndex: -1,
            opacity: 0,
          }}
          ref={fileInputRef}
          onChange={(e) => console.log('done')}
        />
      </div>
    </Menu>
  );

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${coverImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {/* <div style={{ cursor: "pointer" }} onClick={handleOpenMenu}>
              <MDAvatar src={userDetails.image} alt="profile-image" size="xl" shadow="sm" />
            </div> */}
            <div style={{ cursor: "pointer" }}>
              <MDAvatar src={userDetails.image} alt="profile-image" size="xl" shadow="sm" />
            </div>
            {renderMenu()}
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {info.fullName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {info.type}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  info: PropTypes.object,
};

export default Header;
