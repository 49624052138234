import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";

import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ParentForm from "./components/parentForm";
import EditParent from "./components/editParent";
import { LoginContext } from "../../../App";
import Loading from "components/loadingComponent";
import { server } from "utils/server";

// ///////
import Info from "components/ALERT/Info";

export default function ManageParent() {
  const [formDisplay, setFormDisplay] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [parentData, setParentData] = useState(null);
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [allParents, setAllParents] = useState([]);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [valueToEdit, setValueToEdit] = useState("");
  const [idToEdit, setIdToEdit] = useState("");
  const [goFrontEnabled, setGoFrontEnabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [goBackEnabled, setGoBackEnabled] = useState(false);
  const [paginationCounter, setPaginationCounter] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [pages, setPages] = useState(1);
  const [serialNo, setSerianNo] = useState(null);
  const { server1 } = server();
  const [showEnableFeeAction, setShowEnableFeeAction] = useState(false);
  const [showDisableFeeAction, setShowDisableFeeAction] = useState(false);
  const [showDeleteInfo, setShowDeletInfo] = useState(false);
  const [parentId, setParentId] = useState("");

  const [renderEdit, setRenderEdit] = useState(false);
  const navigation = useNavigate();

  const showenableFee = () => {
    showEnableFeeAction ? setShowEnableFeeAction(false) : setShowEnableFeeAction(true);
  };

  const showDisableFee = () => {
    showDisableFeeAction ? setShowDisableFeeAction(false) : setShowDisableFeeAction(true);
  };

  const handleShowDeleteInfo = () => {
    showDeleteInfo ? setShowDeletInfo(false) : setShowDeletInfo(true);
  };

  //Creating childrens
  const createStudent = async (userData) => {
    try {
      const response = await fetch(`${server1}/admins/create_student`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify({
          firstName: userData.firstName,
          middleName: userData.middleName,
          lastName: userData.lastName,
          admissionNumber: userData.admissionNumber,
          address: userData.address,
          permanentAddress: userData.permanentAddress,
          phone: userData.phone,
          dateOfbirth: userData.dateOfbirth,
          state: userData.state,
          lga: userData.lga,
          class: userData.class,
          bloodGroup: userData.bloodGroup,
          genoType: userData.genoType,
          allergies: userData.allergies,
          specialNeeds: userData.specialNeeds,
          schoolGuardian: userData.schoolGuardian,
          awards: userData.awards,
          disciplinaryReports: userData.disciplinaryReports,
          emergencyPhone: userData.emergencyPhone,
          pickUp: userData.pickUp,
          disciplinaryReports: userData.disciplinaryReports,
          passport: userData.passport,
          idCard: userData.idCard,
        }),
      });
      var msg = await response.json();
      if (response.ok) {
        console.log(msg);
      } else {
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setAlertMessage("could not create student");
      setAlertType("error");
      setAlertDisplay(true);
    }
  };

  useEffect(() => {
    !renderEdit && setValueToEdit("");
  }, [renderEdit]);

  const createUsers = async (userData) => {
    setIsLoading(true);
    try {
      var finalData = {};
      var newData = {};

      for (let child of userData.children) {
        await createStudent(child);
      }

      const response = await fetch(`${server1}/admins/create_parent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify(userData),
      });
      var msg = await response.json();
      if (response.ok) {
        // User creation succeeded
        setAlertMessage("User(s) created successfully");
        setAlertType("success");
        setParentData(msg.body);
        setAlertDisplay(true);
        getParents()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
      } else {
        // User creation failed
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  };

  const editUser = async (data) => {
    setRenderEdit(false);
    const response = await fetch(`${server1}/admins/update_parent/${idToEdit}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded
      setAlertMessage("User(s) updated successfully");
      setAlertType("success");
      setParentData(msg.body);
      setAlertDisplay(true);
      getParents()
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
      //const responseData = await response.json();
    } else {
      // User creation failed
      setAlertMessage(msg.message);
      setAlertType("error");
      setAlertDisplay(true);
      // const errorData = await response.json();
      // console.log(errorData);
      // console.error("User creation failed:", errorData.error);
    }
  };

  const handlePagination = (index) => {
    setCurrentPage(index);
    setIsLoading(true);
    getParents(index).then(() => {
      // showPagination(paginationCounter)
      setIsLoading(false);
    });
  };

  const showPagination = (counter) => {
    var paginationArray = [];
    if (counter == 1) {
      setGoBackEnabled(false);
    } else {
      setGoBackEnabled(true);
    }
    var paginationCounterTemp = Math.ceil(pages / 5);
    if (counter == paginationCounterTemp) {
      setGoFrontEnabled(false);
    } else {
      setGoFrontEnabled(true);
    }
    var start = counter * 5 - 5;
    var stop = counter * 5;
    if (pages < counter * 5) {
      var stop = pages;
    }
    for (let index = start; index < stop; index++) {
      if (index + 1 == currentPage) {
        paginationArray.push(<div style={{ fontSize: 13, margin: 5 }}>{index + 1}</div>);
      } else {
        paginationArray.push(
          <button
            style={{
              borderColor: "#344767",
              color: "#344767",
              borderStyle: "solid",
              width: 30,
              height: 30,
              margin: 3,
              cursor: "pointer",
            }}
            onClick={() => {
              handlePagination(index + 1);
            }}
          >
            {index + 1}
          </button>
        );
      }
    }
    setPagination(paginationArray);
  };

  const deleteParent = async (id) => {
    setRenderEdit(false);
    const response = await fetch(`${server1}/admins/delete_user/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded
      setAlertMessage("Parent deleted successfully");
      setAlertType("success");
      setParentData(msg.body);
      setAlertDisplay(true);
      getParents()
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
      //const responseData = await response.json();
      //console.log(responseData);
    } else {
      // User creation failed
      setAlertMessage(msg.message);
      setAlertType("error");
      setAlertDisplay(true);
      // const errorData = await response.json();
      // console.log(errorData);
      // console.error("User creation failed:", errorData.error);
    }
  };

  const getParents = async (index) => {
    try {
      setCurrentPage(index);
      setIsLoading(true);
      const response = await fetch(`${server1}/admins/get_all_parents/` + index, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        // User creation succeeded
        setSerianNo(index * 20 - 20);
        // setPaginationCounter(Math.ceil(responseData.pages/5))
        setPages(responseData.pages);
        var newPaginationCounter = Math.ceil(responseData.pages / 5);
        // showPagination(paginationCounter)
        setAllParents(responseData.data);
        // console.log(allParents)
      } else {
        // User creation failed
        setAlertMessage("could not get students");
        setAlertType("error");
        setAlertDisplay(true);
        const errorData = await response.json();
      }
    } catch (error) {
      console.log(error);
      setAlertMessage("could not get students");
      setAlertType("error");
      setAlertDisplay(true);
    }
  };

  const enableFees = async () => {
    try {
      showenableFee();
      const response = await fetch(`${server1}/admins/enable_fees_for_all_parents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        setAlertMessage(responseData.message);
        setAlertType("success");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.log(error);
      setAlertMessage("Something went wrong try again");
      setAlertType("error");
      setAlertDisplay(true);
    }
  };

  const disableFees = async () => {
    try {
      showDisableFee();
      const response = await fetch(`${server1}/admins/disable_fees_for_all_parents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        setAlertMessage(responseData.message);
        setAlertType("success");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.log(error);
      setAlertMessage("Something went wrong try again");
      setAlertType("error");
      setAlertDisplay(true);
    }
  };

  const displayDropDownMenu = (id) => {
    const elem = document.getElementById(id);
    elem.style.display = elem.style.display === "none" ? "flex" : "none";
  };

  const savedUser = (person) => {
    setAlertMessage(`${person} saved`);
    setAlertType("success");
    setAlertDisplay(true);

    setTimeout(() => {
      setAlertDisplay(false);
    }, 4000);
  };

  useEffect(() => {
    valueToEdit !== "" && setRenderEdit(true);
  }, [valueToEdit]);
  useEffect(() => {
    showPagination(paginationCounter);
  }, [currentPage]);
  useEffect(() => {
    showPagination(paginationCounter);
  }, [paginationCounter, pages]);

  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
    getParents(currentPage)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    valueToEdit !== "" && setRenderEdit(true);
  }, [valueToEdit]);

  useEffect(() => {
    !renderEdit && setValueToEdit("");
  }, [renderEdit]);

  const handleCounter = (index) => {
    setPaginationCounter(index);
    showPagination(index);
  };

  return (
    <>
      {isLoading && <Loading />}
      {alertDisplay && (
        <div
          style={{
            background: alertType === "error" ? "#d31a38" : "green",
            width: 350,
            position: "absolute",
            zIndex: 10,
            right: 40,
            color: "white",
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: 14, marginLeft: 20 }}>{alertMessage}</div>
          <div
            style={{
              height: 40,
              borderRadius: "50%",
              marginRight: 18,
              cursor: "pointer",
            }}
            onClick={() => setAlertDisplay(false)}
          >
            ×
          </div>
        </div>
      )}
      <DashboardLayout>
        <DashboardNavbar zIndex={formDisplay ? -1 : 1} />
        {showEnableFeeAction && (
          <Info
            text="Are you sure you want to Enable fees for all parents"
            action={enableFees}
            cancel={showenableFee}
          />
        )}
        {showDisableFeeAction && (
          <Info
            text="Are you sure you want to Disable fees for all parents"
            cancel={showDisableFee}
            action={disableFees}
          />
        )}
        {showDeleteInfo && (
          <Info
            text={`Are sure you want to delete ${parentId.firstName} ${
              parentId.middleName ? parentId.middleName : parentId.lastName
            }
            ${parentId.middleName ? parentId.lastName : ""} from the parent list. \n
            note: This action can not be reversed`}
            action={() => deleteParent(parentId._id)}
            cancel={handleShowDeleteInfo}
          />
        )}
        <div style={{ display: "flex" }}>
          <MDBox sx={{ mx: 2 }}>
            <MDButton variant="contained" color="dark" onClick={() => setFormDisplay(true)}>
              <Icon>add</Icon> Add Parent
            </MDButton>
          </MDBox>
          <MDBox sx={{ mx: 2 }}>
            <MDButton variant="contained" color="info" onClick={() => showenableFee()}>
              Enable fees
            </MDButton>
          </MDBox>
          <MDBox sx={{ mx: 2 }}>
            <MDButton
              variant="contained"
              color="primary"
              // onClick={() => setFormDisplay(true)}
              onClick={() => showDisableFee()}
            >
              Disable fees
            </MDButton>
          </MDBox>
        </div>
        {formDisplay && (
          <ParentForm
            submitted={(data) => {
              setFormDisplay(false);
              createUsers(data);
            }}
            cancelled={() => setFormDisplay(false)}
            saved={savedUser}
          />
        )}
        {renderEdit && (
          <EditParent
            initialData={valueToEdit}
            cancelled={() => setRenderEdit(false)}
            submitted={editUser}
          />
        )}

        {allParents.length > 0 && (
          <Card sx={{ p: 2, mt: 2 }}>
            <MDBox display="flex" flexDirection="column">
              <MDBox
                width="100%"
                // maxWidth="450px"
                display="flex"
                justifyContent="space-between"
                marginBottom="4px"
                padding="0px 0px 8px 14px"
                borderBottom="1px solid gray"
              >
                <MDTypography variant="h6">Descriptions</MDTypography>
                <MDTypography variant="h6">Actions</MDTypography>
              </MDBox>
              {allParents.map((data) => {
                const id = `${data.firstName}${data.lastName}${Math.random()}`;
                return (
                  <div className="itemList" key={data._id}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <MDTypography variant="button" fontWeight="regular">
                        {allParents.indexOf(data) + 1}.
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="medium">
                        {/* &nbsp;&nbsp;&nbsp; {`${data.userName}`} */}
                        {`${data.firstName} ${data.middleName ? data.middleName : data.lastName}
                         ${data.middleName ? data.lastName : ""}`}
                      </MDTypography>
                    </div>
                    <div className="itemListDropdown">
                      <button onClick={() => displayDropDownMenu(id)}>
                        <MDTypography variant="button" fontWeight="medium">
                          <Icon>more_vert</Icon>
                        </MDTypography>
                      </button>
                      <div className="itemListMenu" id={id}>
                        <Card
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                            zIndex: 5,
                          }}
                        >
                          <div
                            className="itemListContent"
                            onClick={() => {
                              displayDropDownMenu(id);
                              setValueToEdit(data);
                              setIdToEdit(data._id);
                            }}
                          >
                            <MDTypography
                              variant="overline"
                              fontWeight="medium"
                              color="dark"
                              display="flex"
                              alignItems="center"
                            >
                              <Icon>edit</Icon>&nbsp;Edit
                            </MDTypography>
                          </div>
                          <div
                            className="itemListContent"
                            onClick={() => {
                              // deleteParent(data._id);
                              handleShowDeleteInfo();
                              setParentId(data);
                            }}
                          >
                            <MDTypography
                              variant="overline"
                              fontWeight="medium"
                              color="error"
                              display="flex"
                              alignItems="center"
                            >
                              <Icon>delete</Icon>&nbsp;Delete
                            </MDTypography>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                );
              })}
            </MDBox>
          </Card>
        )}
        {pagination && (
          <div style={{ display: "flex", marginTop: 20 }}>
            {goBackEnabled && (
              <button
                style={{
                  borderColor: "#344767",
                  borderStyle: "solid",
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                  margin: 3,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleCounter(paginationCounter - 1);
                }}
              >
                {"<<"}
              </button>
            )}
            {!goBackEnabled && (
              <button
                disabled
                style={{
                  borderColor: "grey",
                  borderStyle: "solid",
                  width: 30,
                  height: 30,
                  color: "grey",
                  margin: 3,
                }}
              >
                {"<<"}
              </button>
            )}
            {pagination && pagination}
            {goFrontEnabled && (
              <button
                style={{
                  borderColor: "#344767",
                  borderStyle: "solid",
                  width: 30,
                  height: 30,
                  margin: 3,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleCounter(paginationCounter + 1);
                }}
              >
                {">>"}
              </button>
            )}
            {!goFrontEnabled && (
              <button
                disabled
                style={{
                  borderColor: "grey",
                  borderStyle: "solid",
                  width: 30,
                  height: 30,
                  margin: 3,
                }}
              >
                {">>"}
              </button>
            )}
          </div>
        )}
        <div style={{ textAlign: "center" }}>page {currentPage}</div>
      </DashboardLayout>
    </>
  );
}
