export const initialStudentData = [
  {
    id: 1,
    type: "text",
    label: "Admission Number",
    apiLabel: "admissionNumber",
    value: "",
    disable: true,
  },
  {
    id: 2,
    type: "text",
    label: "First Name",
    apiLabel: "firstName",
    value: "",
    required: true,
  },
  {
    id: 3,
    label: "Middle Name",
    type: "text",
    apiLabel: "middleName",
    value: "",
    required: false,
  },
  {
    id: 4,
    type: "text",
    label: "Last Name",
    apiLabel: "lastName",
    value: "",
    required: true,
  },
  {
    id: 5,
    type: "date",
    label: "",
    apiLabel: "dateOfBirth",
    value: "",
  },
  {
    id: 8,
    type: "text",
    label: "Blood Group",
    apiLabel: "bloodGroup",
    value: "",
    required: false,
    fullWidth: true,
  },
  {
    id: 9,
    type: "text",
    label: "GenoType",
    apiLabel: "genoType",
    value: "",
    required: false,
    fullWidth: true,
  },
  {
    id: 10,
    type: "text",
    label: "Allergies",
    apiLabel: "allergies",
    value: "",
    required: false,
    fullWidth: true,
  },
  {
    id: 11,
    type: "text",
    label: "Any special needs? (optional)",
    apiLabel: "specialNeeds",
    value: "",
    required: false,
    fullWidth: true,
    multiline: true,
  },
  {
    id: 12,
    type: "text",
    label: "Address",
    apiLabel: "address",
    value: "",
  },
  {
    id: 13,
    type: "text",
    label: "Permanent Address",
    apiLabel: "permanentAddress",
    value: "",
    required: false,
  },
  {
    id: 14,
    type: "text",
    label: "State Of Origin",
    apiLabel: "stateOfOrigin",
    value: "",
  },
  {
    id: 15,
    type: "text",
    label: "LGA",
    apiLabel: "lga",
    value: "",
  },
  {
    id: 16,
    type: "text",
    label: "School Guardian",
    apiLabel: "schoolGuardian",
    value: "",

    fullWidth: true,
  },
  {
    id: 17,
    type: "text",
    label: "Awards (optional)",
    apiLabel: "awards",
    value: "",
    required: false,
    fullWidth: true,
  },
  {
    id: 18,
    type: "text",
    label: "Disciplinary Reports",
    apiLabel: "disciplinaryReports",
    value: "",
    required: false,
    fullWidth: true,
    multiline: true,
  },
  {
    id: 19,
    type: "text",
    label: "Emergency Phone",
    apiLabel: "emergencyPhone",
    value: "",
    required: false,
    fullWidth: true,
  },
  {
    id: 20,
    type: "text",
    label: "Pick-up Arrangements (optional)",
    apiLabel: "pickUp",
    value: "",
    required: false,
    fullWidth: true,
    multiline: true,
  },
  {
    id: 21,
    type: "file",
    label: "Upload Passport",
    apiLabel: "passport",
    value: "",
  },
  {
    id: 22,
    type: "file",
    label: "Upload ID Card",
    apiLabel: "idCard",
    value: "",
  },
];

export const initialParentData = [
  {
    id: 1,
    type: "text",
    label: "First Name",
    apiLabel: "firstName",
    value: "",
    required: true,
  },
  {
    id: 2,
    type: "text",
    label: "Middle Name (optional)",
    apiLabel: "middleName",
    value: "",
    required: false,
  },
  {
    id: 3,
    type: "text",
    label: "Last Name",
    apiLabel: "lastName",
    value: "",
    required: true,
  },
  {
    id: 4,
    type: "text",
    label: "Address",
    apiLabel: "address",
    value: "",
  },
  {
    id: 5,
    type: "text",
    label: "Permanent Address",
    apiLabel: "permanentAddress",
    value: "",
    required: false,
  },
  {
    id: 6,
    type: "text",
    label: "Phone Number",
    apiLabel: "phone",
    value: "",
  },
  {
    id: 7,
    type: "email",
    label: "Email",
    apiLabel: "email",
    value: "",
    fullWidth: true,
  },
  {
    id: 8,
    type: "date",
    apiLabel: "dateOfBirth",
    value: "",
  },
  {
    id: 9,
    type: "text",
    label: "State Of Origin",
    apiLabel: "state",
    value: "",
  },
  {
    id: 10,
    type: "text",
    label: "LGA",
    apiLabel: "lga",
    value: "",
  },
  {
    id: 11,
    type: "text",
    label: "Occupation",
    apiLabel: "ocuppation",
    value: "",

    fullWidth: true,
  },
  {
    id: 12,
    type: "file",
    label: "Upload Passport",
    apiLabel: "passport",
    value: "",
  },
  {
    id: 13,
    type: "file",
    label: "Upload ID Card",
    apiLabel: "idCard",
    value: "",
  },
];

export const formTeacherData = [
  {
    id: 1,
    type: "text",
    label: "Staff Number",
    apiLabel: "staffNumber",
    value: "",
    disable: true,
  },
  {
    id: 2,
    type: "text",
    label: "First Name",
    apiLabel: "firstName",
    value: "",
    required: true,
  },
  {
    id: 3,
    type: "text",
    label: "Middle Name (optional)",
    apiLabel: "middleName",
    value: "",
    required: false,
  },
  {
    id: 4,
    type: "text",
    label: "Last Name",
    apiLabel: "lastName",
    value: "",
    required: true,
  },
  {
    id: 5,
    type: "text",
    label: "Address",
    apiLabel: "address",
    value: "",
  },
  // {
  //   id: 25,
  //   type: "select",
  //   label: "select new subject",
  //   apiLabel: "subject",
  //   value: "",
  //   required: true,
  // },
  {
    id: 6,
    type: "text",
    label: "Permanent Address",
    apiLabel: "permanentAddress",
    value: "",
  },
  {
    id: 7,
    type: "text",
    label: "Phone Number",
    apiLabel: "phone",
    value: "",
  },
  {
    id: 8,
    type: "email",
    label: "Email",
    apiLabel: "email",
    value: "",
  },
  {
    id: 9,
    type: "date",
    apiLabel: "dateOfBirth",
    value: "",
  },
  {
    id: 10,
    type: "text",
    label: "State Of Origin",
    apiLabel: "state",
    value: "",
  },
  {
    id: 11,
    type: "text",
    label: "LGA",
    apiLabel: "lga",
    value: "",
  },
  {
    id: 12,
    type: "text",
    label: "Class",
    apiLabel: "class",
    value: "",
  },
  {
    id: 15,
    type: "file",
    label: "Upload Passport",
    apiLabel: "passport",
    value: "",
  },
  {
    id: 16,
    type: "file",
    label: "Upload ID Card",
    apiLabel: "idCard",
    value: "",
  },
];

export const initialAdminData = [
  {
    id: 1,
    type: "text",
    label: "Staff Number",
    apiLabel: "staffNumber",
    value: "",
  },
  {
    id: 2,
    type: "text",
    label: "First Name",
    apiLabel: "firstName",
    value: "",
    required: true,
  },
  {
    id: 3,
    type: "text",
    label: "Middle Name (optional)",
    apiLabel: "middleName",
    value: "",
    required: false,
  },
  {
    id: 4,
    type: "text",
    label: "Last Name",
    apiLabel: "lastName",
    value: "",
    required: true,
  },
  {
    id: 5,
    type: "text",
    label: "Address",
    apiLabel: "address",
    value: "",
  },
  {
    id: 6,
    type: "text",
    label: "Permanent Address",
    apiLabel: "permanentAddress",
    value: "",
  },
  {
    id: 7,
    type: "text",
    label: "Phone Number",
    apiLabel: "phone",
    value: "",
  },
  {
    id: 8,
    type: "email",
    label: "Email",
    apiLabel: "email",
    value: "",
  },
  {
    id: 9,
    type: "date",
    apiLabel: "dateOfbirth",
    value: "",
  },
  {
    id: 10,
    type: "text",
    label: "State Of Origin",
    apiLabel: "state",
    value: "",
  },
  {
    id: 11,
    type: "text",
    label: "LGA",
    apiLabel: "lga",
    value: "",
  },
  {
    id: 12,
    type: "text",
    label: "Role",
    apiLabel: "role",
    value: "",
    required: false,
    fullWidth: true,
  },
  {
    id: 15,
    type: "file",
    label: "Upload Passport",
    apiLabel: "passport",
    value: "",
  },
  {
    id: 16,
    type: "file",
    label: "Upload ID Card",
    apiLabel: "idCard",
    value: "",
  },
];

const guarantorForm = [
  {
    id: 1,
    type: "text",
    label: "Full Name",
    apiLabel: "guarantorA",
    value: "",
  },
  {
    id: 2,
    type: "text",
    label: "Address",
    apiLabel: "guarantorAAddress",
    value: "",
  },
  {
    id: 3,
    type: "text",
    label: "Phone Number",
    apiLabel: "guarantorAPhone",
    value: "",
  },
  {
    id: 4,
    type: "text",
    label: "Occupation",
    apiLabel: "guarantorAOccupation",
    value: "",
  },
];

const guarantorFormB = [
  {
    id: 1,
    type: "text",
    label: "Full Name",
    apiLabel: "guarantorB",
    value: "",
  },
  {
    id: 2,
    type: "text",
    label: "Address",
    apiLabel: "guarantorBAddress",
    value: "",
  },
  {
    id: 3,
    type: "text",
    label: "Phone Number",
    apiLabel: "guarantorBPhone",
    value: "",
  },
  {
    id: 4,
    type: "text",
    label: "Occupation",
    apiLabel: "guarantorBOccupation",
    value: "",
  },
];

export const guarantorsData = [guarantorForm, guarantorFormB];
