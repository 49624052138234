import SidenavCollapse from "./SidenavCollapse";
import Icon from "@mui/material/Icon";

import { useState, useEffect } from "react";

export default function UpgradeToEnterprice() {
  const [transform, setTransform] = useState(-10);
  useEffect(() => {
    setTransform(0);
  }, []);
  return (
    <div style={{ transform: `translateY(${transform}px)`, transition: "0.4s" }}>
      <SidenavCollapse name="Upgrade To Enterprice" icon={<Icon fontSize="small">upgrade</Icon>} />
    </div>
  );
}
