import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useCookies } from "react-cookie";
import { server } from "utils/server";
import PasswordCheck from "./components/pin";
import Loading from "components/loadingComponent";

// components
import PinView from "./components";
import { useState } from "react";

export default function ManagePin() {
  const [loading, setLoading] = useState("");
  const [pin, setPin] = useState("34444");
  const [showPin, setShowPin] = useState(false);
  const [error, setError] = useState("");
  const { server1 } = server();
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [showPassword, setShowPassword] = useState(true);

  const handleShowPin = () => {
    showPin ? setShowPin(false) : setShowPin(true);
  };

  const generatePin = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${server1}/admins/create_pin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });
      const responseData = await response.json();
      if (responseData.status === "success") {
        setLoading(false);
        setPin(responseData.pin.pin);
        handleShowPin();
      } else {
        setLoading(false);
        setError(responseData.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowPasswordCheck = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  return (
    <>
      {loading && <Loading />}
      <DashboardLayout>
        {showPin && <PinView pin={pin} cancel={handleShowPin} />}
        <DashboardNavbar />
        {showPassword && <PasswordCheck cancel={handleShowPasswordCheck} />}
        {!showPassword && (
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h5">Generate pin for result checking</MDTypography>
            <MDButton
              variant="contained"
              color="success"
              style={{ marginTop: "20px" }}
              onClick={() => generatePin()}
            >
              Generate Pin
            </MDButton>
          </MDBox>
        )}
      </DashboardLayout>
    </>
  );
}
