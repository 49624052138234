import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";

import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import Loading from "components/loadingComponent";
import EditSubjectForm from "./editSubjectForm";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import SubjectForm from "./subjectForm";
import { LoginContext } from "../../../App";
import MDTypography from "components/MDTypography";
import { server } from "utils/server";

export default function ManageSubject() {
  const [formDisplay, setFormDisplay] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [subjectData, setSubjectData] = useState(null);
  const [allSubjects, setAllSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const { server1 } = server()

  const [valueToEdit, setValueToEdit] = useState("");
  const [idToEdit, setIdToEdit] = useState("");
  const [renderEdit, setRenderEdit] = useState(false);

  const navigation = useNavigate();
  const createSubject = async (userData) => {
    setIsLoading(true);
    try {
      setFormDisplay(false);
     
      const response = await fetch(`${server1}/admins/create_subject`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "sds": cookie.sds_auth_token
        },
        body: JSON.stringify({
          subject: userData.subject,
          class: userData.class,
        }),
      });
      
      const responseData = await response.json();
      if (response.ok) {
        // User creation succeeded
        setAlertMessage("Subject created successfully");
        setAlertType("success");
        setSubjectData(responseData.body);
        setAlertDisplay(true);
        getSubjects()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
        getSubjects()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
      } else {
        // User creation failed
        setAlertMessage(responseData.message);
        setAlertType("error");
        setAlertDisplay(true);
        // const errorData = await response.json();
        // console.error("User creation failed:", errorData.error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  };

    //Get subjects
    const handleCook = async () => {
      setIsLoading(true);
      const response = await fetch(`${server1}/admins/handle_cook`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
          "sds": cookie.sds_auth_token
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        // User creation succeeded
        console.log('success');
      } else {
        // User creation failed
        console.log('failed');
      }
    };

  //Get subjects
  const getSubjects = async () => {
    setIsLoading(true);
    const response = await fetch(`${server1}/admins/get_all_subjects`, {
      method: "GET",
      origin: 'http://localhost:10000',
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setAllSubjects(responseData.data);
      
    } else {
      // User creation failed
      const errorData = await response.json();
      console.error("User creation failed:", errorData.error);
    }
  };

  const editSubject = async (data) => {
    setRenderEdit(false);
    const response = await fetch(`${server1}/admins/update_subject/${idToEdit}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded
      
      setAlertMessage("Class updated successfully");
      setAlertType("success");
      setAlertDisplay(true);
      getSubjects()
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
      //const responseData = await response.json();
      //console.log(responseData);
    } else {
      // User creation failed
      setAlertMessage(msg.message);
      setAlertType("error");
      setAlertDisplay(true);
      // const errorData = await response.json();
     
      // console.error("User creation failed:", errorData.error);
    }
  };

  const deleteSubject = async (id) => {
    setRenderEdit(false);
    const response = await fetch(`${server1}/admins/delete_subject/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded
      setAlertMessage("Class updated successfully");
      setAlertType("success");
      setAlertDisplay(true);
      getSubjects()
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
      //const responseData = await response.json();
      //console.log(responseData);
    } else {
      // User creation failed
      setAlertMessage(msg.message);
      setAlertType("error");
      setAlertDisplay(true);
      // const errorData = await response.json();
      // console.log(errorData);
      // console.error("User creation failed:", errorData.error);
    }
  };

  const displayDropDownMenu = (id) => {
    const elem = document.getElementById(id);
    elem.style.display = elem.style.display === "none" ? "flex" : "none";
  };

  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
    handleCook()
    //console.log(cookie.sds_auth_token)
    getSubjects()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    valueToEdit !== "" && setRenderEdit(true);
  }, [valueToEdit]);

  useEffect(() => {
    !renderEdit && setValueToEdit("");
  }, [renderEdit]);

  return (
    <>
      {isLoading && <Loading />}
      <DashboardLayout>
        {alertDisplay && (
          <div
            style={{
              background: alertType === "error" ? "#d31a38" : "green",
              width: 350,
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}
        <DashboardNavbar zIndex={formDisplay ? -1 : 1} />
        <MDBox sx={{ mx: 2 }}>
          <MDButton variant="contained" color="dark" onClick={() => setFormDisplay(true)}>
            <Icon>add</Icon> Add Subject
          </MDButton>
        </MDBox>
        {formDisplay && (
          <SubjectForm cancelled={() => setFormDisplay(false)} submitted={createSubject} />
        )}
        {renderEdit && (
          <EditSubjectForm
            initialValue={valueToEdit}
            cancelled={() => setRenderEdit(false)}
            submitted={editSubject}
          />
        )}
        {allSubjects.length > 0 && (
          <Card sx={{ p: 2, mt: 2 }}>
            <MDBox display="flex" flexDirection="column">
              <MDBox
                width="100%"
                // maxWidth="450px"
                display="flex"
                justifyContent="space-between"
                marginBottom="4px"
                padding="0px 0px 8px 14px"
                borderBottom="1px solid gray"
              >
                <MDTypography variant="h6">Descriptions</MDTypography>
                <MDTypography variant="h6">Actions</MDTypography>
              </MDBox>
              {allSubjects &&
                allSubjects.map((subject) => {
                  const id = `${subject.name}${Math.random()}`;
                  return (
                    <div className="itemList" key={subject._id}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <MDTypography variant="button" fontWeight="regular">
                          {allSubjects.indexOf(subject) + 1}.
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="medium">
                          &nbsp;&nbsp;&nbsp;
                          {subject.name ? subject.name.toUpperCase() : ""}
                        </MDTypography>
                      </div>
                      <div className="itemListDropdown">
                        <button onClick={() => displayDropDownMenu(id)}>
                          <MDTypography variant="button" fontWeight="medium">
                            <Icon>more_vert</Icon>
                          </MDTypography>
                        </button>
                        <div className="itemListMenu" id={id}>
                          <Card
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              overflow: "hidden",
                              zIndex: 5,
                            }}
                          >
                            <div
                              className="itemListContent"
                              onClick={() => {
                                displayDropDownMenu(id);
                                setValueToEdit(subject.name);
                                setIdToEdit(subject._id);
                              }}
                            >
                              <MDTypography
                                variant="overline"
                                fontWeight="medium"
                                color="dark"
                                display="flex"
                                alignItems="center"
                              >
                                <Icon>edit</Icon>&nbsp;Edit
                              </MDTypography>
                            </div>
                            <div
                              className="itemListContent"
                              onClick={() => {
                                deleteSubject(subject._id);
                              }}
                            >
                              <MDTypography
                                variant="overline"
                                fontWeight="medium"
                                color="error"
                                display="flex"
                                alignItems="center"
                              >
                                <Icon>delete</Icon>&nbsp;Delete
                              </MDTypography>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </MDBox>
          </Card>
        )}
      </DashboardLayout>
    </>
  );
}
