import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { LoginContext } from "../../App";

import { resultDummyData } from "./data";
import Loading from "components/loadingComponent";
import { server } from "utils/server";
import PinInput from "./components/pinComponent";

export default function MyChildrensResult() {
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [childrens, setChildrens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstTermState, setFirstTermState] = useState(false);
  const [secondTermState, setSecondTermState] = useState(false);
  const [thirdTermState, setThirdTermState] = useState(false);
  const [ id, setId ] = useState('')
  const [ showPin, setShowPin ] = useState(false)
  const { server1 } = server()
  const navigation = useNavigate();

  const getStudents = async () => {
    setIsLoading(true);
    const response = await fetch(`${server1}/parent/get_children`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      //Filter the response.data to get the parent's children
      setChildrens(responseData.data);
      // console.log(responseData.data)
     
    } else {
      const errorData = await response.json();
      //console.error("User creation failed:", errorData.error);
    }
  };

  const getSession = async () => {
    
    const response = await fetch(`${server1}/admins/get_current_session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token
      },
    });
    const responseData = await response.json();
    if (response.ok) { 
      if(responseData.data.term==1){
        setFirstTermState(true)
      }
      if(responseData.data.term==2){
        setSecondTermState(true)
      }
      if(responseData.data.term==3){
        setThirdTermState(true)
      }
      
    } else {
      console.log('failed')
    }
  };

  const handleShowPin = (id) => {
    showPin ? setShowPin(false) : setShowPin(true);
    setId(id)
  }

  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
    getSession()
    getStudents()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <DashboardLayout>
        {showPin && <PinInput id={id} cancel={handleShowPin}/>}
        <DashboardNavbar />
        <MDTypography variant="h6">Children Result</MDTypography>
        <MDBox paddingTop="15px" display="flex" flexDirection="column" gap="15px">
          {childrens && childrens.map((data) => {
            console.log(data)
            return (
              <div key={firstTermState?'first term':secondTermState?'second term':'third term'} style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <MDTypography variant="button" fontWeight="bold">
                  {`${data.user.firstName} ${data.user.lastName}`}
                </MDTypography>

                <MDTypography variant="button" fontWeight="bold">
                  |
                </MDTypography>

                {/* Term */}
                <MDTypography variant="button" fontWeight="bold">
                  {firstTermState?'First Term':secondTermState?'Second Term':'Third Term'}
                </MDTypography>

                {data.Student.paidFees ? 
                  <MDButton color="success" size="small" variant="contained"
                  onClick={() => handleShowPin(data.user._id)}
                  >
                    View Result
                  </MDButton>: 
                  <MDButton color="error" size="small" variant="contained">
                  Fees not paid
                </MDButton>
                }
              </div>
            );
          })}
        </MDBox>
      </DashboardLayout>
    </>
  );
}
