/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function EnrollmentDetails({ studentID, admissionDate, gradeLevel }) {
  const [details, setDetails] = useState([
    ["Student ID Number", studentID],
    ["Admission Date", admissionDate],
    ["Grade Level", gradeLevel],
  ]);

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Enrollment Details
        </MDTypography>
      </MDBox>
      {details.map((detail) => {
        return (
          <MDBox key={detail[0]} display="flex" py={1} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {detail[0]}: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{detail[1]}
            </MDTypography>
          </MDBox>
        );
      })}
    </Card>
  );
}

EnrollmentDetails.propTypes = {
  studentID: PropTypes.string.isRequired,
  admissionDate: PropTypes.string.isRequired,
  gradeLevel: PropTypes.string.isRequired,
};
