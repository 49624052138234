import PropTypes from "prop-types";
import Card from "@mui/material/Card";

import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import ChildrenForm from "./children";

import { useState, useEffect } from "react";

import Form from "./form";

export default function ParentForm({ submitted, cancelled, saved }) {
  const [parentsUserName, setParentsUserName] = useState("");
  const [parentsPassword, setParentsPassword] = useState("");
  const [fatherFormDisplay, setFatherFormDisplay] = useState(true);
  const [motherFormDisplay, setMotherFormDisplay] = useState(false);
  const [editButtonDisplay, setEditButtonDisplay] = useState("none");
  const [editMotherButtonDisplay, setEditMotherButtonDisplay] = useState("none");
  const [addMotherButtonDisplay, setAddMotherButtonDisplay] = useState(false);
  const [fatherInfo, setFatherInfo] = useState(null);
  const [motherInfo, setMotherInfo] = useState(null);

  const [parentsInfoDisplay, setParentsInfoDisplay] = useState(false);
  const [buttonVisibility, setButtonVisibility] = useState(true);

  const [studentFormDisplay, setStudentFormDisplay] = useState(false);
  const [addChildrenButton, setAddChildrenButton] = useState(false);
  const [childrens, setChildren] = useState([]);

  const [opacity, setOpacity] = useState(0);

  useEffect(() => setOpacity(1), []);

  //Displaying mother's form
  const handleAddMotherForm = () => {
    setAddMotherButtonDisplay(false);
    setMotherFormDisplay(true);
  };

  const handleSaved = (parent, data) => {
    if (parent === "Father") {
      setFatherFormDisplay(false);
      setEditButtonDisplay("flex");
      setAddMotherButtonDisplay(true);
      setFatherInfo(data);
    }
    if (parent === "Mother") {
      setMotherFormDisplay(false);
      setEditMotherButtonDisplay("flex");
      setAddMotherButtonDisplay(false);
      setAddChildrenButton(true);
      setMotherInfo(data);
    }
    saved(parent);
  };

  //Handling Edit
  const handleEdit = () => {
    setEditButtonDisplay("none");
    setFatherFormDisplay(true);
  };

  const handleEditMother = () => {
    setEditMotherButtonDisplay("none");
    setMotherFormDisplay(true);
  };

  const addChild = (child) => {
    // setChildren((previous) =>{
    //   console.log(previous)
    //   if(Array.isArray(previous)){
    //    return  [...previous, child]
    //   } else {
    //     return [previous, child]
    //   }

    // });

    setChildren([...childrens, child]);
    saved("Child");
    setStudentFormDisplay(false);
    setAddChildrenButton(true);
    setButtonVisibility(true);
  };

  const handleSubmit = () => {
    submitted({
      father: fatherInfo,
      mother: motherInfo,
      parentsUserName: parentsUserName,
      parentsPassword: parentsPassword,
      children: childrens,
    });
  };

  useEffect(() => {
    if (childrens.length > 0) {
      setParentsInfoDisplay(true);
    }
  }, [childrens]);

  useEffect(() => {
    if (parentsUserName.length > 5 && parentsPassword.length > 5) {
      setButtonVisibility(false);
    } else {
      setButtonVisibility(true);
    }
  }, [parentsUserName, parentsPassword]);

  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ width: "100%", maxWidth: "530px" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <div style={{ padding: "0px 10px", marginBottom: "6px" }} className="formHeaderRow">
          <MDTypography variant="h6">Parent's Form</MDTypography>
          <MDTypography onClick={cancelled}>
            <Icon style={{ cursor: "pointer" }}>cancel</Icon>
          </MDTypography>
        </div>

        <div style={{ paddingLeft: "10px", paddingBottom: "10px", display: "flex", gap: "10px" }}>
          <div style={{ display: editButtonDisplay }}>
            <button
              style={{ border: "none", cursor: "pointer", background: "transparent" }}
              onClick={handleEdit}
            >
              <MDTypography variant="button" fontWeight="medium" display="flex" alignItems="center">
                <Icon>edit</Icon>&nbsp;Edit Father's Form
              </MDTypography>
            </button>
          </div>

          <div style={{ display: editMotherButtonDisplay }}>
            <button
              style={{ border: "none", cursor: "pointer", background: "transparent" }}
              onClick={handleEditMother}
            >
              <MDTypography variant="button" fontWeight="medium" display="flex" alignItems="center">
                <Icon>edit</Icon>&nbsp;Edit Mother's Form
              </MDTypography>
            </button>
          </div>
        </div>

        <div className="form">
          <div style={{ display: `${fatherFormDisplay ? "flex" : "none"}` }}>
            <Form InfoType="Father" saved={handleSaved} />
          </div>
          {addMotherButtonDisplay && (
            <div style={{ width: "100%" }}>
              <button
                style={{ border: "none", cursor: "pointer", background: "transparent" }}
                onClick={handleAddMotherForm}
              >
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  display="flex"
                  alignItems="center"
                >
                  <Icon>add</Icon>&nbsp;Add Mother
                </MDTypography>
              </button>
            </div>
          )}
          <div style={{ display: `${motherFormDisplay ? "flex" : "none"}` }}>
            <Form InfoType="Mother" saved={handleSaved} />
          </div>

          {addChildrenButton && (
            <div style={{ width: "100%" }}>
              <button
                style={{ border: "none", cursor: "pointer", background: "transparent" }}
                onClick={() => {
                  setAddChildrenButton(false);
                  setStudentFormDisplay(true);
                }}
              >
                <MDTypography variant="h6" display="flex" alignItems="center">
                  <Icon>add</Icon>&nbsp;Add Child
                </MDTypography>
              </button>
            </div>
          )}
          {studentFormDisplay && (
            <div style={{ margin: "8px 0px" }}>
              <ChildrenForm pushChild={addChild} childNo={childrens.length + 1} />
            </div>
          )}
          {parentsInfoDisplay && (
            <div className="rows" style={{ marginBottom: "10px" }}>
              <MDInput
                label="Parents UserName"
                value={parentsUserName}
                onChange={(e) => setParentsUserName(e.target.value)}
                fullWidth={true}
              />
              <MDInput
                type="password"
                label="Parents Password"
                value={parentsPassword}
                onChange={(e) => setParentsPassword(e.target.value)}
                fullWidth={true}
              />
            </div>
          )}
        </div>
        <div className="rows">
          <MDButton
            onClick={handleSubmit}
            variant="contained"
            sx={{ mx: 2 }}
            color="dark"
            fullWidth
            disabled={buttonVisibility}
          >
            <Icon>add</Icon>&nbsp;Submit
          </MDButton>
        </div>
      </Card>
    </div>
  );
}

ParentForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
  saved: PropTypes.func,
};
