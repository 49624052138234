/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function Home() {
  return (
    <BasicLayout image={bgImage}>
      <nav
        style={{ color: "rgba(255,255,255,0.9)", fontSize: "16px", display: "flex", gap: "10px" }}
      >
        <Link to="/authentication/sign-in/">
          <p style={{ color: "rgba(255,255,255,0.9)" }}>Sign-in</p>
        </Link>
        <p>To continue</p>
      </nav>
    </BasicLayout>
  );
}

export default Home;
