/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useLayoutEffect, useContext } from "react";
import { Cookies, useCookies } from "react-cookie";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { LoginContext } from "../../../App";

import Loading from "components/loadingComponent";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
// SERVER
import { server } from "utils/server";

function Basic() {
  const [cardPosition, setCardPosition] = useState(15);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([false, false]);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [classData, setClassData] = useState(null);
  const [cookie, setCookie] = useCookies();
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const { server1 } = server();

  //Updating States
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  //Handling SignIn
  const handleSubmit = async () => {
    if (email === "" || password === "") {
      email === "" && setErrors((previous) => [true, previous[1]]);
      password === "" && setErrors((previous) => [previous[0], true]);
      return;
    }
    setIsLoading(true);
    // const formData = new FormData();
    // var datum = {
    //   email: email,
    //   password: password,
    // };
    // formData.append("login", datum.login);
    //  axios({
    //   url: "http://localhost:10000/v1/auth",
    //   method: "POST",
    //   headers: {
    //     'Authorization': '*'
    //   },
    //   data: formData
    //  }).then((res, err)=>{
    //   if(err){
    //     setAlertMessage(res.error);
    //     setAlertType("error");
    //     setAlertDisplay(true);
    //   } else {

    //     setAlertType("success");
    //     setAlertMessage('logged in');
    //     setIsLoggedIn(res.data.id)
    //     setClassData(res.data);
    //     setAlertDisplay(true);
    //     setAlertDisplay(true);
    //     navigation('/dashboard')
    //   }
    // })
    // axios
    //   .post(
    //     "http://localhost:10000/v1/auth",
    //     {
    //       email: email,
    //       password: password,
    //     }
    //     //  {
    //     //     headers: {
    //     //       'Content-Type': 'multipart/form-data'
    //     //     }
    //     //   }
    //   )
    //   .then(
    //     (res) => {

    //       if (!res.data.data) {
    //         console.log(res.data.message)
    //         console.log(res.data.error)
    //         setAlertMessage(res.data.message);
    //         setAlertType("error");
    //         setAlertMessage("invalid username/password");
    //         setAlertDisplay(true);
    //         setIsLoading(false);
    //         return;
    //       }
    //       let expires = new Date();
    //       setClassData(res.data.data);
    //       expires.setTime(expires.getTime() + 86400 * 1000);
    //       setCookie("sds_auth_token", res.data.data.idToken, { path: "/", expires,secure: false });

    //       setAlertType("success");
    //       setAlertMessage("logged in");
    //       setClassData(res.data.data);

    //       setTimeout(() => {
    //         setIsLoading(false);
    //         setAlertDisplay(true);
    //         setTimeout(() => {
    //           navigation("/profile");
    //         }, 200);
    //       }, 1000);
    //     },
    //     () => setIsLoading(false)
    //   );

    const data = {
      email: email,
      password: password,
    };

    const response = await fetch(`${server1}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setAlertMessage("logged in");
      setAlertType("success");
      setClassData(responseData.data);
      let expires = new Date();
      expires.setTime(expires.getTime() + 86400 * 1000);
      setCookie("sds_auth_token", responseData.data.idToken, { path: "/", expires, secure: false });
      setIsLoading(false);
      setAlertDisplay(true);
      setAlertDisplay(true);
      navigation("/profile");
      // setTimeout(() => {
      //   setIsLoading(false);
      //   setAlertDisplay(true);
      //   setTimeout(() => {
      //     navigation("/profile");
      //   }, 200);
      // }, 1000);
      // setAlertDisplay(true);
    } else {
      // User creation failed
      setAlertMessage("username or password invalid");
      setAlertType("error");
      setIsLoading(false);
      setAlertDisplay(true);
      // const errorData = await response.json();
      // console.error("User creation failed:", errorData.error);
    }
  };

  useEffect(() => {
    email !== "" && setErrors((previous) => [false, previous[1]]);
    password !== "" && setErrors((previous) => [previous[0], false]);
  }, [email, password]);

  useLayoutEffect(() => {
    setCardPosition(0);
  }, []);

  return (
    <BasicLayout image={bgImage}>
      {isLoading && <Loading />}
      {alertDisplay && (
        <div
          style={{
            background: alertType === "error" ? "#d31a38" : "green",
            width: 300,
            position: "absolute",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            zIndex: 10,
            right: 40,
            color: "white",
            borderRadius: 20,
          }}
        >
          <div
            style={{
              height: 40,
              borderRadius: "50%",
              float: "right",
              marginRight: 18,
              cursor: "pointer",
              marginTop: 2,
            }}
            onClick={() => setAlertDisplay(false)}
          >
            ×
          </div>
          <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
        </div>
      )}
      <div style={{ transform: `translateY(${cardPosition}px)`, transition: "0.5s" }}>
        <img style={{ width: "100%", marginBottom: "40px" }} src="/logo.png" alt="" />
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  value={email}
                  label="Email or Username"
                  fullWidth
                  onChange={handleEmailChange}
                  error={errors[0]}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  error={errors[1]}
                  fullWidth
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={2}>
                <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                  sign in
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </div>
    </BasicLayout>
  );
}

export default Basic;
