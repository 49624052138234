import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import { Cookies, useCookies } from "react-cookie";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import ChildrenForm from "./children";

import picture from "assets/images/user.jpg";

import PropTypes from "prop-types";

import { useState, useEffect, useRef } from "react";
import { server } from "utils/server";

export default function EditParent({ submitted, cancelled, initialData }) {
  const [opacity, setOpacity] = useState(0);
  const [ParentData, setParentData] = useState([]);
  const [profile, setProfile] = useState(picture);
  const [childrens, setChildren] = useState([]);
  const [kids, setKids] = useState(null);
  const [studentFormDisplay, setStudentFormDisplay] = useState(false);
  const [addChildrenButton, setAddChildrenButton] = useState(true);
  const [buttonVisibility, setButtonVisibility] = useState(true);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const profileRef = useRef(null);
  const { server1 } = server()

  const addChild = (child) => {
    // setChildren((previous) =>{
    //   console.log(previous)
    //   if(Array.isArray(previous)){
    //    return  [...previous, child]
    //   } else {
    //     return [previous, child]
    //   }

    // });

    setChildren([...childrens, child]);
    // saved("Child");
    setStudentFormDisplay(false);
    setAddChildrenButton(true);
    setButtonVisibility(true);
  };

  // const saved = (person) => {
  //   setAlertMessage(`${person} saved`);
  //   setAlertType("success");
  //   setAlertDisplay(true);

  //   setTimeout(() => {
  //     setAlertDisplay(false);
  //   }, 4000);
  // };
  const getKids= async ()=>{
    const response = await fetch(`${server1}/admins/get_kids`+initialData._id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setKids(responseData.data);
      
    }
  }

  useEffect(() => {
    getKids()
    setParentData([]);
    for (let key in initialData) {
      if (
        key !== "signupDate" &&
        key !== "status" &&
        key !== "_id" &&
        key !== "updatedAt" &&
        key !== "isVerified" &&
        key !== "createdAt" &&
        key !== "userId" &&
        key !== "isActive"
      ) {
        setParentData((prev) => [
          ...prev,
          {
            label: key,
            value: initialData[key] !== "" ? initialData[key] : "N/A",
            enability: true,
            type: `${key === "dateOfBirth" && "date"}`,
          },
        ]);
      }
    }
  }, []);

  const changeValue = (label, newValue) => {
    setParentData((prevData) =>
      prevData.map((item) => (item.label === label ? { ...item, value: newValue } : item))
    );
    
  };

  const changeEnability = (label, newValue) => {
    setParentData((prevData) =>
      prevData.map((item) => (item.label === label ? { ...item, enability: newValue } : item))
    );
  };

  //Handling Submit
  const handleSubmit = () => {
    var datum= {
      label: 'children',
      value: childrens,
      enability: true,
      type: `children`,
    }
    setParentData([...ParentData, datum])
    submitted([...ParentData, datum]);
  };

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ maxWidth: "600px", width: "88%" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <div className="editFormLayer">
          <div className="formHeaderRow">
            <MDTypography variant="h6">Parent</MDTypography>
            <MDTypography onClick={cancelled}>
              <Icon style={{ cursor: "pointer" }}>cancel</Icon>
            </MDTypography>
          </div>

          {ParentData && (
            <div style={{ display: "flex", padding: "15px 10px 10px 10px", flexWrap: "wrap" }}>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "15px",
                  padding: "20px 20px",
                  minWidth: "100px",
                }}
              >
                <img
                  alt=""
                  src={profile}
                  style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                />
                <input
                  ref={profileRef}
                  type="file"
                  style={{ position: "absolute", zIndex: "-1", opacity: 0 }}
                />
                <MDButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={() => profileRef.current.click()}
                >
                  Change
                </MDButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                  gap: "10px",
                  height: "300px",
                  overflowY: "auto",
                  minWidth: "200px",
                }}
              >
                {ParentData.map((data) => {
                  return (
                    <div
                      key={data.label}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        padding: "0px 10px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <MDTypography variant="button" fontWeight="medium">
                          {data.label === "firstName" && "First Name"}
                          {data.label === "middleName" && "Middle Name"}
                          {data.label === "lastName" && "Last Name"}
                          {data.label === "dateOfBirth" && "Date Of Birth"}
                          {data.label === "userName" && "User Name"}
                          {data.label === "permanentAddress" && "Permanent Address"}
                          {data.label !== "firstName" &&
                            data.label !== "middleName" &&
                            data.label !== "lastName" &&
                            data.label !== "dateOfBirth" &&
                            data.label !== "permanentAddress" &&
                            data.label !== "userName" &&
                            data.label}
                        </MDTypography>
                        <button
                          style={{
                            cursor: "pointer",
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => {
                            if (data.enability) {
                              changeEnability(data.label, false);
                            } else {
                              changeEnability(data.label, true);
                            }
                          }}
                        >
                          <MDTypography variant="overline" fontWeight="regular">
                            <Icon>{data.enability ? "edit" : "check"}</Icon>&nbsp;
                            {data.enability ? "Edit" : "Save"}
                          </MDTypography>
                        </button>
                      </div>
                      <MDInput
                        size="small"
                        value={data.value}
                        disabled={data.enability}
                        onChange={(e) => changeValue(data.label, e.target.value)}
                        type={data.type}
                      />         
                    </div>
                  );
                })}
              </div>
              <div>
              <div>
              <MDTypography variant="h5" display="flex" alignItems="center">
              CHILDREN
                  </MDTypography>
              </div>
              <div>
              {kids && kids.map((kid)=>{
                return(
                  <div style={{fontSize: 14}}>
                  <MDTypography variant="h7" display="flex" alignItems="center">
                  {kid.firstName} {kid.middleName} {kid.lastName}
                  </MDTypography>
                  </div>
                ) 
              })}
              </div>
              </div>
              {addChildrenButton && (
                <div style={{ width: "100%" }}>
                  <button
                    style={{ border: "none", cursor: "pointer", background: "transparent" }}
                    onClick={() => {
                      setAddChildrenButton(false);
                      setStudentFormDisplay(true);
                    }}
                  >
                    <MDTypography variant="h6" display="flex" alignItems="center">
                      <Icon>add</Icon>&nbsp;Add Child
                    </MDTypography>
                  </button>
                </div>
              )}
              {studentFormDisplay && (
                <div style={{ margin: "8px 0px" }}>
                  <ChildrenForm pushChild={addChild} childNo={childrens.length + 1} />
                </div>
              )}
            </div>
          )}
          <div className="rows">
            <MDButton onClick={handleSubmit} variant="contained" color="dark" fullWidth>
              <Icon>add</Icon>
              Submit
            </MDButton>
          </div>
        </div>
      </Card>
    </div>
  );
}

EditParent.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
};
