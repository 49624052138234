import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import Loading from "components/loadingComponent";
import { server } from "utils/server";

export const StudentAluminiClass = () => {
  const [classes, setClasses] = useState([]);
  const [justClasses, setJustClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const { server1 } = server();

  const getClasses = async () => {
    try {
      const response = await fetch(`${server1}/admins/get_all_classes`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        // User creation succeeded
        setClasses(responseData.body);
        setJustClasses(responseData.justClass);
      } else {
        // User creation failed
        const errorData = await response.json();
        console.error("User creation failed:", errorData.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClasses();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <DashboardLayout>
        <DashboardNavbar />
        {/* <MDTypography variant="h4">Manage Students</MDTypography> */}
        <div className="student-class-box-container">
          {classes.map((el) => (
            <Link to={`/aluminis/${el._id}`} key={el._id}>
              <Card className="Card-min-box">
                <MDBox>
                  <h4>{el.specificClass}</h4>
                </MDBox>
              </Card>
            </Link>
          ))}
        </div>
      </DashboardLayout>
    </>
  );
};
