import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { LoginContext } from "../../App";
import Card from "@mui/material/Card";
import { server } from "utils/server";

export default function News() {
  //const [isLoggedIn, setIsLoggedIn]= useContext(LoginContext)
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [news, setNews] = useState('');
  const navigation= useNavigate()
  const { server1 } = server()

  const getNews= async ()=>{
       const response = await fetch(`${server1}1/admins/get_news`, {
         method: "GET",
         headers: {
           "Content-Type": "application/json",
           "sds": cookie.sds_auth_token
         },
       });
       const responseData = await response.json();
       if (response.ok) {
        setNews(responseData.data) 
        console.log('success')
       } else {
        console.log('get_news_failed')
       }
  }


  useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
    getNews()
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h3>All News</h3>
      <div>
        {news && news.map(article=>{
          var date= new Date(article.createdAt)
          var day= date.getDate()
          var month= date.getMonth()
          var year= date.getFullYear()
          var hours= date.getHours()
          var mins= date.getMinutes()
        return  <Card
          sx={{
            width: "100%",
            mt: 3,
            p: 2,
          }}
        >
          <div style={{fontSize: 13, fontWeight: 'bold'}}>
          {`${day}-${month}-${year}`}
          </div>
          <div style={{fontSize: 12}}>
          {`${hours}:${mins}`}
          </div>
          <div>
          {article.news}
          </div>
        </Card>
        })}
      </div>
    </DashboardLayout>
  );
}
