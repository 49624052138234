import PropTypes from "prop-types";

import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { Cookies, useCookies } from "react-cookie";

import { useState, useEffect } from "react";
import { server } from "utils/server";

export default function SubjectForm({ submitted, cancelled }) {
  const [opacity, setOpacity] = useState(0);
  const [subjectValue, setSubjectValue] = useState("");
  const [classValue, setClassValue] = useState("");
  const [classes, setClasses] = useState([]);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const { server1 } = server()
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      subject: subjectValue,
      class: classValue,
    };
    submitted(data);
  };

  const getClasses = async () => {
    const response = await fetch(`${server1}/admins/get_all_classes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setClasses(responseData.body);
      
    } else {
      // User creation failed
      const errorData = await response.json();
      console.error("User creation failed:", errorData.error);
    }
  };

  useEffect(() => {
    getClasses();
    setOpacity(1);
  }, []);
  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ width: `${window.innerWidth - 60 + "px"}`, maxWidth: "430px" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <form className="form" action="" method="post" onSubmit={handleSubmit}>
          <div className="formHeaderRow">
            <MDTypography variant="h6">Create Subject</MDTypography>
            <Icon style={{ cursor: "pointer" }} onClick={cancelled}>
              cancel
            </Icon>
          </div>
          <div className="rows">
            <MDInput
              type="text"
              label="Subject Name"
              value={subjectValue}
              onChange={(e) => setSubjectValue(e.target.value)}
              fullWidth
              required
            ></MDInput>
          </div>
          {/*  <div className="rows">
            <MDTypography variant="button" fontWeight="regular">
              Select class for subject
            </MDTypography>
            <select
              style={{ padding: "8px 4px 8px 4px" }}
              required
              onChange={({ target: { value } }) => setClassValue(value)}
            >
              <option style={{ color: "#666" }}>select class</option>
              {classes.map((classObj) => {
                return <option value={classObj}>{classObj}</option>;
              })}
            </select>
          </div>
            */}
          <div className="rows">
            <MDButton type="submit" variant="contained" color="dark" fullWidth>
              <Icon>add</Icon>
              Add Subject
            </MDButton>
          </div>
        </form>
      </Card>
    </div>
  );
}

SubjectForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
};
