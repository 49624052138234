//BroadSheet Dummy data

export const broadSheetDummyData = [
  {
    class: "Basic 1",
    id: "class1",
    subjectsAndScores: [
      {
        subject: "English Language",
        headers: [
          "CA 20%",
          "MID-TERM 30%",
          "EXAM 50%",
          "TOTAL",
          "Subject Average",
          "Subject Position",
        ],
        studentResults: [
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
        ],
      },
      {
        subject: "Mathematics",
        headers: [
          "CA 20%",
          "MID-TERM 30%",
          "EXAM 50%",
          "TOTAL",
          "Subject Average",
          "Subject Position",
        ],
        studentResults: [
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
        ],
      },
    ],
    students: {
      headers: ["S/NO", "NAME"],
      students: [
        {
          No: 1,
          name: "Ben",
          isEnabled: false,
        },
        {
          No: 2,
          name: "John",
          isEnabled: false,
        },
        {
          No: 3,
          name: "Evans",
          isEnabled: false,
        },
      ],
    },
    overAll: {
      headers: ["Overall Average", "Overall Total", "Overall Position"],
      overAllScores: [
        {
          OverallAverage: "76%",
          OverallTotal: 80,
          OverallPosition: "2nd",
        },
        {
          OverallAverage: "76%",
          OverallTotal: 80,
          OverallPosition: "2nd",
        },
        {
          OverallAverage: "76%",
          OverallTotal: 80,
          OverallPosition: "2nd",
        },
      ],
    },
    psychomotorEvaluation: [
      { student: "Ben", legibility: "", accuracy: "", neatness: "", dexterity: "" },
      { student: "John", legibility: "", accuracy: "", neatness: "", dexterity: "" },
      { student: "Evans", legibility: "", accuracy: "", neatness: "", dexterity: "" },
    ],
  },
  {
    id: "class2",
    class: "Basic 2",
    subjectsAndScores: [
      {
        subject: "English Language",
        headers: [
          "CA 20%",
          "MID-TERM 30%",
          "EXAM 50%",
          "TOTAL",
          "Subject Average",
          "Subject Position",
        ],
        studentResults: [
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
        ],
      },
      {
        subject: "Mathematics",
        headers: [
          "CA 20%",
          "MID-TERM 30%",
          "EXAM 50%",
          "TOTAL",
          "Subject Average",
          "Subject Position",
        ],
        studentResults: [
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
          {
            CA: 15,
            MidTerm: 20,
            Exam: 45,
            Total: 80,
            SubjectAverage: 73.33,
            SubjectPosition: "2nd",
          },
        ],
      },
    ],
    students: {
      headers: ["S/NO", "NAME"],
      students: [
        {
          No: 1,
          name: "Ben",
        },
        {
          No: 2,
          name: "John",
        },
        {
          No: 3,
          name: "Evans",
        },
        {
          No: 4,
          name: "Destiny",
        },
        {
          No: 5,
          name: "James",
        },
      ],
    },
    overAll: {
      headers: ["Overall Average", "Overall Total", "Overall Position"],
      overAllScores: [
        {
          OverallAverage: "76%",
          OverallTotal: 80,
          OverallPosition: "2nd",
        },
        {
          OverallAverage: "76%",
          OverallTotal: 80,
          OverallPosition: "2nd",
        },
        {
          OverallAverage: "76%",
          OverallTotal: 80,
          OverallPosition: "2nd",
        },
        {
          OverallAverage: "76%",
          OverallTotal: 80,
          OverallPosition: "2nd",
        },
        {
          OverallAverage: "76%",
          OverallTotal: 80,
          OverallPosition: "2nd",
        },
      ],
    },
    psychomotorEvaluation: [
      { student: "Ben", legibility: "", accuracy: "", neatness: "", dexterity: "" },
      { student: "John", legibility: "", accuracy: "", neatness: "", dexterity: "" },
      { student: "Evans", legibility: "", accuracy: "", neatness: "", dexterity: "" },
      { student: "Destiny", legibility: "", accuracy: "", neatness: "", dexterity: "" },
      { student: "James", legibility: "", accuracy: "", neatness: "", dexterity: "" },
    ],
  },
];
