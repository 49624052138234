import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { Cookies, useCookies } from "react-cookie";

import picture from "assets/images/user.jpg";

import PropTypes from "prop-types";

import { useState, useEffect, useRef } from "react";
import { server } from "utils/server";
import axios from 'axios';

export default function EditTeacher({
  submitted,
  cancelled,
  initialData,
  otherInfosForEdit,
  allClassesSubjects,
}) {
  const [opacity, setOpacity] = useState(0);
  const [teacherData, setTeacherData] = useState([]);
  const [profile, setProfile] = useState(picture);
  const [othersForEdit, setOthersForEdit] = useState([]);
  const [subjectsForSelection, setSubjectsForSelection] = useState([]);
  const [newSubjectForAddition, setNewSubjectForAddition] = useState(null);
  const [subjectsForAddition, setSubjectsForAddition] = useState([]);
  const [subjectsForRemoval, setSubjectsForRemoval] = useState([]);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const profileRef = useRef(null);
  const { server1 } = server();
  
  const handleSelectSubject = (index) => {
    // var temp= {
    // //   classDetail: classDetail,
    // //   subject: subject
    // // }
    setNewSubjectForAddition(subjectsForSelection[index]);
  };

  const handleAddSelectedSubject = () => {
    if (newSubjectForAddition != null) {
      var temp = [...subjectsForAddition, newSubjectForAddition];
      setSubjectsForAddition(temp);
    }
  };

  const settleSubjects = (data) => {
    var subjectsArray = [];
    for (let index = 0; index < data.value.length; index++) {
      subjectsArray.push(
        <div>
          {data.value[index].subject.name} {data.value[index].class.className}
        </div>
      );
    }
  };

  const removeSubject1 = (subject) => {
    
    var temp = [];
    var found = false;
    for (let index = 0; index < subjectsForAddition.length; index++) {
      if (
        subjectsForAddition[index].subject != subject.subject ||
        subjectsForAddition[index].class != subject.class
      ) {
        temp = [...temp, subjectsForAddition[index]];
      } else {
        if (found) {
          temp = [...temp, subjectsForAddition[index]];
        } else {
          found = true;
        }
      }
    }
    setSubjectsForAddition(temp);
  };

  const removeSubject2 = (subject) => {
    
    var temp = [];
    var temp1 = [...subjectsForRemoval, subject.teacherInfo];
    setSubjectsForRemoval(temp1);
    console.log('info', subject);
    console.log('teacher info', initialData);
    axios.post(
      `${server1}/admins/delete_teacher_subject`, 
      {
        user: initialData._id,
        subjectId: subject._id, 
        
      },
      {
        headers: {
          'Content-Type': 'application/json',
          sds: cookie.sds_auth_token,
        },
      }
    )
      .then(response => {
        console.log('Success:', response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    

    const subject_id = subject._id && subject._id !== null ? subject._id : subject.subject._id;
    // axios.post(`${server1}/admins/delete_subject/${subject_id}`}`)
    for (let index = 0; index < othersForEdit.length; index++) {
      let currentItem = othersForEdit[index];
      
      if (
          currentItem && 
          currentItem.subject && 
          currentItem.class && 
          (currentItem.subject._id != subject.subject._id || 
           currentItem.class._id != subject.class._id)
      ) {
          temp = [...temp, currentItem];
      }
  }
  
  
    setOthersForEdit(temp);
    
  };

  const getTeacherSubjects = async () => {
    const response = await fetch(`${server1}/admins/get_teacher_subjects/${initialData._id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setOthersForEdit(responseData.data);
      console.log('teacher subject', responseData.data);
    } else {
      // User creation failed
      // setAlertMessage("could not get classes");
      // setAlertType("error");
      // setAlertDisplay(true);
      //console.error("User creation failed:", errorData.error);
    }
  };

  useEffect(() => {
    getTeacherSubjects();
    setSubjectsForSelection(allClassesSubjects);
    setTeacherData([]);
    for (let key in initialData) {
      if (
        key !== "signupDate" &&
        key !== "status" &&
        key !== "_id" &&
        key !== "updatedAt" &&
        key !== "isVerified" &&
        key !== "createdAt" &&
        key !== "userId" &&
        key !== "isActive" &&
        key !== "type"
      ) {
        setTeacherData((prev) => [
          ...prev,
          {
            label: key,
            value: initialData[key] !== "" ? initialData[key] : "N/A",
            enability: true,
            type: `${key === "dateOfBirth" && "date"}`,
          },
        ]);
      }
    }
  }, []);

  const changeValue = (label, newValue) => {
    setTeacherData((prevData) =>
      prevData.map((item) => (item.label === label ? { ...item, value: newValue } : item))
    );
  };

  const changeEnability = (label, newValue) => {
    setTeacherData((prevData) =>
      prevData.map((item) => (item.label === label ? { ...item, enability: newValue } : item))
    );
  };

  //Handling Submit
  const handleSubmit = () => {
    submitted(teacherData, subjectsForAddition, subjectsForRemoval);
  };

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ maxWidth: "600px", width: "88%" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <div className="editFormLayer">
          <div className="formHeaderRow">
            <MDTypography variant="h6">Teacher</MDTypography>
            <MDTypography onClick={cancelled}>
              <Icon style={{ cursor: "pointer" }}>cancel</Icon>
            </MDTypography>
          </div>

          {teacherData && (
            <div style={{ display: "flex", padding: "15px 10px 10px 10px", flexWrap: "wrap" }}>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "15px",
                  padding: "20px 20px",
                  minWidth: "100px",
                }}
              >
                <img
                  alt=""
                  src={profile}
                  style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                />
                <input
                  ref={profileRef}
                  type="file"
                  style={{ position: "absolute", zIndex: "-1", opacity: 0 }}
                />
                <MDButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={() => profileRef.current.click()}
                >
                  Change
                </MDButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                  gap: "10px",
                  height: "300px",
                  overflowY: "auto",
                  minWidth: "200px",
                }}
              >
                {teacherData.map((data) => {
                  return (
                    <div
                      key={data.label}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        padding: "0px 10px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <MDTypography variant="button" fontWeight="medium">
                          {data.label === "firstName" && "First Name"}
                          {data.label === "middleName" && "Middle Name"}
                          {data.label === "lastName" && "Last Name"}
                          {data.label === "dateOfBirth" && "Date Of Birth"}
                          {data.label === "username" && "User Name"}
                          {data.label === "subjectAndClass" && "Subjects"}
                          {data.label === "subjectAndClass" && settleSubjects(data)}
                          {data.label === "permanentAddress" && "Permanent Address"}
                          {data.label !== "firstName" &&
                            data.label !== "middleName" &&
                            data.label !== "lastName" &&
                            data.label !== "dateOfBirth" &&
                            data.label !== "permanentAddress" &&
                            data.label !== "username" &&
                            data.label}
                        </MDTypography>
                        <button
                          style={{
                            cursor: "pointer",
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => {
                            if (data.enability) {
                              changeEnability(data.label, false);
                            } else {
                              changeEnability(data.label, true);
                            }
                          }}
                        >
                          <MDTypography variant="overline" fontWeight="regular">
                            <Icon>{data.enability ? "edit" : "check"}</Icon>&nbsp;
                            {data.enability ? "Edit" : "Save"}
                          </MDTypography>
                        </button>
                      </div>
                      <MDInput
                        size="small"
                        value={data.value}
                        disabled={data.enability}
                        onChange={(e) => changeValue(data.label, e.target.value)}
                        type={data.type}
                      />
                    </div>
                  );
                })}
                <div style={{ fontSize: 15 }}>Subjects</div>
                <div>
                  <select onChange={(e) => handleSelectSubject(e.target.value)}>
                    <option>select subject</option>
                    {subjectsForSelection.map((subject, index) => {
                      return (
                        <option
                          value={index}
                          // onClick={()=>handleSelectSubject(subject)}
                        >
                          {subject.subjectName ? subject.subjectName : "test"}{" "}
                          {subject.className ? subject.className : "pp"}
                        </option>
                      );
                    })}
                  </select>
                  <span>
                    <button
                      style={{
                        padding: "3px 10px 3px 10px",
                        background: "#344767",
                        outline: "none",
                        border: "none",
                        borderRadius: "3px",
                        marginLeft: "20px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={handleAddSelectedSubject}
                    >
                      add
                    </button>
                  </span>
                </div>
                <div style={{ fontSize: 15 }}> Added Subjects </div>
                <div>
                  {subjectsForAddition &&
                    subjectsForAddition.map((subject) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <div style={{ fontSize: 13, paddingTop: 4 }}>
                            {subject.subjectName} {subject.className ? subject.className : "pp"}
                          </div>
                          <div>
                            <button
                              style={{
                                padding: "3px 10px 3px 10px",
                                background: "#344767",
                                outline: "none",
                                border: "none",
                                borderRadius: "3px",
                                marginLeft: "20px",
                                color: "#fff",
                                cursor: "pointer",
                              }}
                              onClick={() => removeSubject1(subject)}
                            >
                              remove
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div style={{ fontSize: 15 }}> Teacher's Subjects </div>
                <div>
                  {othersForEdit &&
                    othersForEdit.filter((data) => data.subject !== null).map((info) => {
                      
                      return (
                        <div style={{ display: "flex" }}>
                          <div style={{ fontSize: 13, paddingTop: 4 }}>
                            {info.subject.name} {info.class ? info.class.className : ""}
                          </div>
                          <div>
                            <button
                              style={{
                                padding: "3px 10px 3px 10px",
                                background: "#344767",
                                outline: "none",
                                border: "none",
                                borderRadius: "3px",
                                marginLeft: "20px",
                                color: "#fff",
                                cursor: "pointer",
                              }}
                              onClick={() => removeSubject2(info)}
                            >
                              remove
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          <div className="rows">
            <MDButton onClick={handleSubmit} variant="contained" color="dark" fullWidth>
              <Icon>add</Icon>
              Submit
            </MDButton>
          </div>
        </div>
      </Card>
    </div>
  );
}

EditTeacher.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
  otherInfosForEdit: PropTypes.object.isRequired,
  allClassesSubjects: PropTypes.object.isRequired,
};
