import { useState, useContext, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Cookies, useCookies } from "react-cookie";
import Loading from "components/loadingComponent";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";

import { Icon } from "@mui/material";
import { LoginContext } from "../../App";

//images
import backgroundImage from "../../assets/images/bg-reset-cover.jpeg";
import { server } from "utils/server";

export default function CreateNews() {
  //const [isLoggedIn, setIsLoggedIn]= useContext(LoginContext)
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [news, setNews] = useState('');
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigation= useNavigate()
  const { server1 } = server()

  const presetNews= (newNews)=>{
    setNews(newNews)
  }

  const publishNews= async () => {
    if(!news){
      return
    }
    setIsLoading(true)
    var data= {
      news: news
    }

    
     const response = await fetch(`${server1}/admins/publish_news`, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
         "sds": cookie.sds_auth_token
       },
       body: JSON.stringify(data),
     });
     const responseData = await response.json();
     setIsLoading(false)
     if (response.ok) {
      // User creation succeeded
      setAlertMessage("news published");
      setAlertType("success");
      setAlertDisplay(true);
    } else {
      // User creation failed
      setAlertMessage("could not publish news");
      setAlertType("error");
      setAlertDisplay(true);
      //console.error("User creation failed:", errorData.error);
    }
   };


  useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
  }, []);
  return (
    <DashboardLayout>
      {isLoading && <Loading />}
      <DashboardNavbar />
      {alertDisplay && (
          <div
            style={{
              background: alertType === "error" ? "#d31a38" : "green",
              width: 300,
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          style={{ boxShadow: "none", borderRadius: "8px", opacity: "0.8" }}
          sx={{
            position: "relative",
            mt: -30,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDInput onChange={(e)=>{presetNews(e.target.value)}} label="Type here..." multiline rows={6} />
        </Card>
        <MDBox sx={{ mt: 10 }}>
          <MDButton onClick={publishNews} variant="outlined" color="info" size="medium">
            <Icon>publish</Icon>&nbsp; Publish
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
