import PropTypes from "prop-types";

import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { Cookies, useCookies } from "react-cookie";

//Datas
import { initialStudentData } from "../data";

import { useState, useEffect } from "react";
import { server } from "utils/server";

export default function StudentForm({ submitted, cancelled }) {
  const [opacity, setOpacity] = useState(0);
  const [classValue, setClassValue] = useState("");
  const [classArmValue, setClassArmValue] = useState("");
  const [generalClassValue, setGeneralClassValue] = useState("");
  const [justClasses, setJustClasses] = useState([]);
  const [justArms, setJustArms] = useState([]);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [classes, setClasses] = useState([]);
  const [studentForm, setStudentForm] = useState(initialStudentData);
  const { server1 } = server();

  //Updating Inputs
  const handleStudentFormChange = (id, newValue) => {
    setStudentForm((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item))
    );
  };

  const getClasses = async () => {
    const response = await fetch(`${server1}/admins/get_all_classes_arms`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setClasses(responseData.body);
      setJustClasses(responseData.justClass);
    } else {
      // User creation failed
      const errorData = await response.json();
      console.error("User creation failed:", errorData.error);
    }
  };

  const handleChangeClass = (value) => {
    setClassArmValue(value);
    var temp = [];
    for (let index = 0; index < classes.length; index++) {
      if (classes[index].className === value) {
        temp = [
          ...temp,
          {
            arm: classes[index].classArm,
            id: classes[index]._id,
          },
        ];
      }
    }
    setJustArms(temp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { class: classValue /*classArm: classArmValue */ };

    studentForm.forEach((value) => {
      data[value.apiLabel] = value.value;
    });
    data["class"] = classArmValue;

    submitted(data);
  };

  const getStudentForm = () => {
    for (let index = 0; index < studentForm.length; index++) {}
  };

  useEffect(() => {
    getClasses();
    setOpacity(1);
    setStudentForm(initialStudentData);
  }, []);
  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ width: "100%", maxWidth: "530px" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <div className="formHeaderRow" style={{ padding: "0px 10px 6px 10px" }}>
          <MDTypography variant="h6">Create Student</MDTypography>
          <Icon style={{ cursor: "pointer" }} onClick={cancelled}>
            cancel
          </Icon>
        </div>
        <form className="form" action="" method="post" onSubmit={handleSubmit}>
          <div className="rows">
            {studentForm.map((data) => {
              return (
                <>
                  {data.type !== "file" && data.type !== "date" && (
                    <div style={{ width: `${data.fullWidth ? "100%" : "240px"}` }}>
                      <MDInput
                        key={data.id}
                        type={data.type}
                        label={data.label}
                        value={data.value}
                        required={data.required}
                        multiline={data.multiline}
                        rows={data.multiline ? 5 : null}
                        fullWidth
                        onChange={(e) => handleStudentFormChange(data.id, e.target.value)}
                        disabled={data.disable}
                      />
                    </div>
                  )}
                  {data.type === "file" && (
                    <div key={data.id} className="rows" style={{ paddingLeft: "40px" }}>
                      <MDTypography variant="button" display="flex" alignItems="center">
                        <Icon>upload</Icon>&nbsp; {data.label}
                      </MDTypography>
                      <div style={{ width: "240px" }}>
                        <input
                          type="file"
                          onChange={(e) => handleStudentFormChange(data.id, e.target.files[0])}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  )}
                  {data.type === "date" && (
                    <div style={{ width: "100%" }}>
                      <MDTypography variant="button">Date Of Birth</MDTypography>
                      <MDInput
                        key={data.id}
                        type={data.type}
                        label={data.label}
                        value={data.value}
                        onChange={(e) => handleStudentFormChange(data.id, e.target.value)}
                        fullWidth
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
          <div style={{ margin: "15px 0px", width: "100%" }}>
            <MDTypography variant="h6">Student Class</MDTypography>
            <div className="rows" style={{ padding: "0px 0px 10px 20px" }}>
              <MDTypography variant="button">Select class for student</MDTypography>
              <select
                style={{ width: "240px", padding: "8px 4px" }}
                required
                onChange={({ target: { value } }) => handleChangeClass(value)}
              >
                <option style={{ color: "#666" }}>select class</option>
                {classes.map((classDetail) => {
                  return <option value={classDetail._id}>{classDetail.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="rows">
            <MDButton type="submit" variant="contained" color="dark" fullWidth>
              <Icon>add</Icon>
              Add Student
            </MDButton>
          </div>
        </form>
      </Card>
    </div>
  );
}

StudentForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
};
