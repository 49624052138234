import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { Cookies, useCookies } from "react-cookie";

import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Switch from "@mui/material/Switch";
import { useState, useEffect } from "react";
import { broadSheetDummyData } from "../data/broadSheetData";
import EditScoreForm from "./editSCoreForm";
import Loading from "components/loadingComponent";
import CreateScoreForm from "./createScoreForm";
import { server } from "utils/server";






const psychomotorEvaluationOptions = ["Select", "Legibility", "Accuracy", "Neatness", "Dexterity"];

export default function BroadSheet({ data, isClicked, reload, showLoad, counter}) {
  const [selectedEvaluation, setSelectedEvaluation] = useState('');
  const [studentsState, setStudentsState] = useState(data.psychomotorEvaluation);
  const [evaluationDropDownDisplay, setEvaluationDropDownDisplay] = useState(false);
  const [students, setStudents] = useState(data);
  const [scoreHeaders, setScoreHeaders] = useState([]);
  const [scoreMain, setScoreMain] = useState([]);
  const [showScores, setShowScores] = useState(false);
  const [firstTermState, setFirstTermState] = useState(false);
  const [secondTermState, setSecondTermState] = useState(false);
  const [thirdTermState, setThirdTermState] = useState(false);
  const [term, setTerm] = useState('first');
  const [renderEdit, setRenderEdit] = useState(false);
  const [valueToEdit, setValueToEdit] = useState(0);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [arrCounter, setArrCounter] = useState(0);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [idToEdit, setIdToEdit] = useState("");
  const [idToCreate, setIdToCreate] = useState("");
  const [classToCreate, setClassToCreate] = useState("");
  const [subjectToCreate, setSubjectToCreate] = useState("");
  const [renderCreate, setRenderCreate] = useState(false);
  const [selectedEvaluationName, setSelectedEvaluationName] = useState('');
  const [testToEdit, setTestToEdit] = useState("");
  const [showComponent, setShowComponent] = useState(true);
  const [studentsTemp, setStudentsTemp] = useState([]);
  const [subjectsTemp, setSubjectsTemp] = useState([]);
  const [classesTemp, setClassesTemp] = useState([]);
  const [firstIndex, setFirstIndex] = useState([]);
  const [secondIndex, setSecondIndex] = useState([]);
  const [getTemp, setGetTemp] = useState(2);
  const [done, setDone] = useState(0);
  const [overAlls, setOverAlls] = useState([]);
  const { server1 } = server()
  
  const [key, setKey] = useState(0);
  const navigation = useNavigate()

  const getClassResults = async () => {
    // console.log(isLoggedIn);
     
     const response = await fetch(`${server1}/teacher/get_class_scores`, {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
         "sds": cookie.sds_auth_token
       },
     });
     const responseData = await response.json();
     if (response.ok) {
      var temp= {
        studentArr: responseData.data[counter],
        class: responseData.classes[counter],
        subjects: responseData.subjects[counter],
        skills: responseData.skills,
      }
       setStudents(temp)
     } else {
       console.log('failed')
     }
   };

   const getSession = async () => {
    
    const response = await fetch(`${server1}/admins/get_current_session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token
      },
    });
    const responseData = await response.json();
    if (response.ok) { 
      if(responseData.data.term==1){
        setFirstTermState(true)
      }
      if(responseData.data.term==2){
        setSecondTermState(true)
      }
      if(responseData.data.term==3){
        setThirdTermState(true)
      }
      
    } else {
      console.log('failed')
    }
  };

  useEffect(()=>{
    getSession()
  }, [])

  const sendRating= async (id, rating)=>{
    showLoad(true)
    const data= {
      studentId: id,
      classId: students.class._id,
      skill: selectedEvaluation,
      rating: rating
    }
    const response = await fetch(`${server1}/teacher/update_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
      // User creation succeeded
      console.log('success')
      reload(getTemp+1)
      //const responseData = await response.json();
    } else {
      // User creation failed
      // setAlertMessage(msg.message);
      // setAlertType("error");
      // setAlertDisplay(true);
      console.log('failed')
      // const errorData = await response.json();
      // console.error("User creation failed:", errorData.error);
    }
  }

  const submitClass= async ()=>{
    showLoad(true)
    var term= ''
    if(firstTermState){
      term= 'first'
    }
    if(secondTermState){
      term= 'second'
    }
    if(thirdTermState){
      term= 'third'
    }

    const data= {
      classId: students.class._id,
      term: term
    }
    const response = await fetch(`${server1}/teacher/submit_class`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
  
      reload(getTemp+1)
      //const responseData = await response.json();
      //console.log(responseData);
    } else {
      // setAlertMessage(msg.message);
      // setAlertType("error");
      // setAlertDisplay(true);
      console.log('failed')
      // const errorData = await response.json();
      // console.log(errorData);
      // console.error("User creation failed:", errorData.error);
    }
  }

  //Updating Students PsychomotorEvaluation
  const handleStudentPsychomotorEvaluationChange = (name, newValue) => {
    switch (selectedEvaluation) {
      case "Legibility":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  legibility: item.legibility === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Accuracy":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  accuracy: item.accuracy === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Neatness":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  neatness: item.neatness === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Dexterity":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  dexterity: item.dexterity === newValue ? "" : newValue,
                }
              : item
          )
        );
      default:
        return;
    }
  };


  //Updating StudentEnability
  const updateStudentEnability = (no) => {
    setStudents((prevData) =>
      prevData.map((item) =>
        item.No === no ? { ...item, isEnabled: item.isEnabled ? false : true } : item
      )
    );
  };
  var setCapitals= (string)=>{
    var subHolder= string.split(' ')
    var mainSubject= ''
    for(let i=0; i<subHolder.length; i++){
      
      if(subHolder[i]){
        if(subHolder[i].length>0){
          var first= subHolder[i][0].toUpperCase()
          var others= subHolder[i].slice(1) 
          var full= first+others.toLowerCase()
          if(i===0){
            mainSubject= full
          } else {
            mainSubject= mainSubject+' '+full
          }  
        }
      }
    
    }
    return mainSubject
  }

  const handleSelectPsych=(data, student)=>{
    var skillsArr= []
    var found= false
    for (let index = 0; index < student.skills.length; index++) {
      if(selectedEvaluation===student.skills[index].skill){
        skillsArr.push(
          (
            <input
            style={{ cursor: "pointer" }}
            onClick={() =>
              sendRating(student.studentDetails._id, data)
            }
            type="checkbox"
            checked={student.skills[index].rating==data?true: false}
          />
          )
        )
      }
      
    }
      if(!found){
        skillsArr.push(
          (
            <input
            style={{ cursor: "pointer" }}
            onClick={() =>
              sendRating(student.studentDetails._id, data)
            }
            type="checkbox"
            checked={false}
          />
          )
        )
      }
      return skillsArr
  }
  
  const handleScoreEdit= (studentIndex, scoreIndex, test, score)=> {
   
    var scoreId= students.studentArr[studentIndex].scoreDetails[scoreIndex]._id
    setIdToEdit(scoreId);
    setRenderEdit(true)
    setTestToEdit(test)
    setFirstIndex(studentIndex)
    setSecondIndex(scoreIndex)
    // displayDropDownMenu(id);
    setValueToEdit(score);
    
  }

  const editScore = async (data) => {
    showLoad(true)
    setRenderEdit(false);
    const response = await fetch(`${server1}/teacher/update_score/${idToEdit}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
      getClassResults().then(()=>{
        setDone(done+2)
      }).then(()=>{
        showLoad(false)
      })
      //reload(getTemp+1)
    } else {
      // User creation failed
      setAlertMessage(msg.message);
      setAlertType("error");
      setAlertDisplay(true);
      // const errorData = await response.json();
      // console.log(errorData);
      // console.error("User creation failed:", errorData.error);
    }
  };

  const createScore= async (data)=>{
    showLoad(true)
    setRenderCreate(false);
    const response = await fetch(`${server1}/teacher/create_score`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
      body: JSON.stringify(data),
    });
    var msg = await response.json();
    if (response.ok) {
      getClassResults().then(()=>{
        setDone(done+2)
      }).then(()=>{
        showLoad(false)
      })
      // reload(getTemp+1)
    } else {
      setAlertMessage(msg.message);
     
      setAlertType("error");
      setAlertDisplay(true);
    }
  }

  useEffect(()=>{
    showLoad(false)
  }, [])
  useEffect(()=>{
    reload(getTemp+2)
  }, [getTemp])
  
  var handleOverAlls= ()=>{
    var output=[]
    var totals=[]
    var averages=[]
    var allOverAlls=[]
    for (let i = 0; i < students.studentArr.length; i++) {
      var studentTotal= 0
      var available= false
      for (let index = 0; index < students.studentArr[i].scoreDetails.length; index++) {
        available= true
        var scores= students.studentArr[i].scoreDetails[index]
        if(firstTermState){
          var score= scores.test1a+scores.test1b+scores.exam1
          studentTotal+= score
        }
        if(secondTermState){
          var score= scores.test2a+scores.test2b+scores.exam2
          studentTotal+= score
        }
        if(thirdTermState){
          var score= scores.test3a+scores.test3b+scores.exam3
          studentTotal+= score
        } 
      }
      if(available){
        var studentAverage= studentTotal/students.studentArr[i].scoreDetails.length
        var temp= {
          average: studentAverage,
          total: studentTotal,
          available: available
        }
        allOverAlls= [...allOverAlls, temp]
        averages= [...averages, studentAverage]
        totals= [...totals, studentTotal]
      } else {
        var temp= {
          average: 0,
          total: 0
        }
        allOverAlls= [...allOverAlls, temp]
        averages= [...averages, 0]
        totals= [...totals, 0]
      }
    }
    averages.sort()
    averages.reverse()

    for (let index = 0; index < allOverAlls.length; index++) {
      const subjectPositionFn = (element) => element == allOverAlls[index].average;
      
      const classPosition= averages.findIndex(subjectPositionFn)
     
      output.push(
        (
          <tr key={Math.random()}>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">{allOverAlls[index].available?allOverAlls[index].average.toFixed(1):'N/A'}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">{allOverAlls[index].available?allOverAlls[index].total:'N/A'}</MDTypography>
            </td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="overline">{allOverAlls[index].available?positionArrange(classPosition+1):'N/A'}</MDTypography>
            </td>
          </tr>
        )
      )
    }
    return output
   
  }
  
 const positionArrange=(num)=>{
  var newNum= num.toString()
  var suffix= 'th'
  var resultString= newNum
  if(newNum[newNum.length-1]=='1' && newNum[newNum.length-2]=='1'){
    suffix= 'th'
  } else if(newNum[newNum.length-1]=='1' && newNum[newNum.length-2]!='1'){
    suffix= 'st'
  } else if(newNum[newNum.length-1]=='3' && newNum[newNum.length-2]!='1'){
    suffix= 'rd'
  } else if(newNum[newNum.length-1]=='2' && newNum[newNum.length-2]!='1'){
    suffix= 'nd'
  }
  return resultString+suffix
 }
  const showSubjectInfos= (id)=>{
    var infosArray=[]

    var subjectPositionArray= []
    for (let i = 0; i < students.studentArr.length; i++) {
      //const subjectPosition = (element) => element == score;
      
      for (let index = 0; index < students.studentArr[i].scoreDetails.length; index++) {
        if(students.studentArr[i].scoreDetails[index].subjectId===id){
          var scores= students.studentArr[i].scoreDetails[index]
          if(firstTermState){
            var score= scores.test1a+scores.test1b+scores.exam1
            subjectPositionArray= [...subjectPositionArray, score]
          }
          if(secondTermState){
            var score= scores.test2a+scores.test2b+scores.exam2
            subjectPositionArray= [...subjectPositionArray, score]
          }
          if(thirdTermState){
            var score= scores.test3a+scores.test3b+scores.exam3
            subjectPositionArray= [...subjectPositionArray, score]
          }  
        }
      }
    }
    subjectPositionArray.sort()
    subjectPositionArray.reverse()
    var subjectTotal= subjectPositionArray.reduce((a, b) => a + b, 0)
    var subjectAverage= subjectTotal/subjectPositionArray.length
    
    for (let i = 0; i < students.studentArr.length; i++) {
     
      var flag= false
      var studentId= students.studentArr[i].studentDetails._id
    
      var classId= students.studentArr[i].classDetails._id
      for (let index = 0; index < students.studentArr[i].scoreDetails.length; index++) {
        if(students.studentArr[i].scoreDetails[index].subjectId===id){
          var scores= students.studentArr[i].scoreDetails[index]
          flag= true

          if(firstTermState){
            // setOverAlls([...overAlls, ])
            var score= scores.test1a+scores.test1b+scores.exam1
            const subjectPositionFn = (element) => element == score;
            const subjectPosition= subjectPositionArray.findIndex(subjectPositionFn)
            infosArray.push(
              <tr key={Math.random()} className="tableHeaderRow">
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    {!students.class.first_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute' }} onClick={()=>{handleScoreEdit(i, index, 'test1a', scores.test1a)}}>edit</Icon>}
                    <MDTypography variant="overline">{scores.test1a}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    {!students.class.first_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute' }}  onClick={()=>{handleScoreEdit(i, index, 'test1b', scores.test1b)}}>edit</Icon>}
                    <MDTypography variant="overline">{scores.test1b}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                  {!students.class.first_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute' }}  onClick={()=>{handleScoreEdit(i, index, 'exam1', scores.exam1)}}>edit</Icon>}
                    <MDTypography variant="overline">{scores.exam1}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">
                      {score}
                    </MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{subjectAverage.toFixed(1)}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{positionArrange(subjectPosition+1)}</MDTypography>
                  </td>
              </tr>
            )
          }
          if(secondTermState){
            var score= scores.test2a+scores.test2b+scores.exam2
            const subjectPositionFn = (element) => element == score;
            const subjectPosition= subjectPositionArray.findIndex(subjectPositionFn)
            infosArray.push(
              <tr key={Math.random()} className="tableHeaderRow">
                <td style={{ display: "flex", justifyContent: "center" }}>
                  {!students.class.second_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute' }}  onClick={()=>{handleScoreEdit(i, index, 'test2a', scores.test2a)}}>edit</Icon>}
                  <MDTypography variant="overline">{scores.test2a}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                {!students.class.second_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute' }}  onClick={()=>{handleScoreEdit(i, index, 'test2b', scores.test2b)}}>edit</Icon>}
                  <MDTypography variant="overline">{scores.test2b}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                {!students.class.second_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute' }}  onClick={()=>{handleScoreEdit(i, index, 'exam2', scores.exam2)}}>edit</Icon>}
                  <MDTypography variant="overline">{scores.exam2}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">
                    {score}
                  </MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{subjectAverage.toFixed(1)}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{positionArrange(subjectPosition+1)}</MDTypography>
                </td>
            </tr>
            )
          }
          if(thirdTermState){
            var score= scores.test3a+scores.test3b+scores.exam3
            const subjectPositionFn = (element) => element == score;
            const subjectPosition= subjectPositionArray.findIndex(subjectPositionFn)
            infosArray.push(
              <tr key={Math.random()} className="tableHeaderRow">
                <td style={{ display: "flex", justifyContent: "center" }}>
                {!students.class.third_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute' }}  onClick={()=>{handleScoreEdit(i, index, 'test3a', scores.test3a)}}>edit</Icon>}
                  <MDTypography variant="overline">{scores.test3a}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                {!students.class.third_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute' }}  onClick={()=>{handleScoreEdit(i, index, 'test3b', scores.test3b)}}>edit</Icon>}
                  <MDTypography variant="overline">{scores.test3b}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                {!students.class.third_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute' }}  onClick={()=>{handleScoreEdit(i, index, 'exam3', scores.exam3)}}>edit</Icon>}
                  <MDTypography variant="overline">{scores.exam3}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">
                    {score}
                  </MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{subjectAverage.toFixed(1)}</MDTypography>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="overline">{positionArrange(subjectPosition+1)}</MDTypography>
                </td>
            </tr>
            )
          
        }  
      }   
    }
   
    const handleNewScore= (test, studentId, classId, subjectId)=>{
      setTestToEdit(test)
      setIdToCreate(studentId)
      setClassToCreate(classId)
      setSubjectToCreate(subjectId)
   
      setRenderCreate(true)
      // displayDropDownMenu(id);
    }
    if(!flag){
      var first= ''
      var second= ''
      var third= ''
      if(firstTermState){
        first= 'test1a'
        second= 'test1b'
        third= 'exam1'
      }
      if (secondTermState) {
        first= 'test2a'
        second= 'test2b'
        third= 'exam2'
      }
      if (thirdTermState) {
        first= 'test3a'
        second= 'test3b'
        third= 'exam3'
      }
      infosArray.push(
        <tr key={Math.random()} className="tableHeaderRow">
          <td style={{ display: "flex", justifyContent: "center" }}>
          {thirdTermState && !students.class.third_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(first, i, classId, id)}}>edit</Icon>}
          {secondTermState && !students.class.second_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(first, i, classId, id)}}>edit</Icon>}
          {firstTermState && !students.class.first_locked_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(first, i, classId, id)}}>edit</Icon>}
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
          {thirdTermState && !students.class.third_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(second, i, classId, id)}}>edit</Icon>}
          {secondTermState && !students.class.second_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(second, i, classId, id)}}>edit</Icon>}
          {firstTermState && !students.class.first_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(second, i, classId, id)}}>edit</Icon>}
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
          {thirdTermState && !students.class.third_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(third, i, classId, id)}}>edit</Icon>}
          {secondTermState && !students.class.second_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(third, i, classId, id)}}>edit</Icon>}
          {firstTermState && !students.class.first_locked && <Icon style={{ cursor: "pointer", marginLeft: 55, marginTop: -4, position: 'absolute'}}  onClick={()=>{handleNewScore(third, i, classId, id)}}>edit</Icon>}
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <MDTypography variant="overline">N/A</MDTypography>
          </td>
        </tr>
      )
    }
  }
  return infosArray
  }

  var scoreTable= []
  var scoreTableHeaders= []
  var scoreBySubjects= []
  const handleScoreHeaderView= ()=>{

    for (let index = 0; index < students.subjects.length; index++) {
      scoreTableHeaders.push(
        <th key={students.subjects[index]._id}>
          <MDTypography variant="h6">
            {students.subjects[index].name}
          </MDTypography>
        </th>
      )
    }
    setScoreHeaders(scoreTableHeaders)
  }
  const cancelled= ()=>{

  }

  //When ever the Publish button on the teacher's dashboard is Clicked
  useEffect(() => {
    isClicked > 0 && console.log(data);
  }, []);

  useEffect(() => {
    setKey(key+1)
    //setScoreMain(scoreMain)
  
    setShowScores(false)
  }, [scoreHeaders]);

  return (
   <>
   <MDTypography variant="h6">
    {`${students.class.className.toUpperCase()} ${firstTermState?'FIRST TERM':secondTermState?'SECOND TERM':'THIRD TERM'}`}
    </MDTypography>
    <Card
      sx={{
        width: "100%",
        mt: 3,
        p: 2,
      }}
    >
      {isLoading && <Loading />}
        {alertDisplay && (
          <div
            style={{
              background: alertType === "error" ? "#d31a38" : "green",
              width: 350,
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}

      {showComponent && <div className="broadSheetBox" key={key}>
        {/* {showScores} */}
        {/* The table that displays students name and number */}
        <div className="studentsTable">
          <MDTypography variant="h6">Students</MDTypography>
          <table>
            <thead>
              <tr>
                {/* {data.students.headers.map((header) => ( */}
                  <th >
                    <MDTypography variant="button" fontWeight="bold">
                      S/NO
                    </MDTypography>
                  </th>
                  <th >
                    <MDTypography variant="button" fontWeight="bold">
                      NAME
                    </MDTypography>
                  </th>
                {/* ))} */}
              </tr>
            </thead>

            {students.studentArr && students.studentArr.map((student, index) => {
              return (
                <tr key={student.name}>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{index+1}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">
                      {`${student.studentDetails.firstName} ${student.studentDetails.lastName}`}
                    </MDTypography>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
        {/* {scoreMain} */}
        {students.subjects && students.subjects.map((subject)=>{
        return(  <div  className="tableBox">
          <MDTypography fontSize="16px" variant="button" fontWeight="bold">
            {setCapitals(subject.name)}
              {/* {classSubjects[index].name} */}
            </MDTypography>
              <table>
                <thead style={{ width: "100%" }}>
                  <tr className="tableHeaderRow">                 
                    <th key={`1ca${term}`}>
                      <MDTypography variant="button" fontWeight="bold">
                        CA 20%
                      </MDTypography>
                    </th>
                    <th key={`2ca${term}`}>
                      <MDTypography variant="button" fontWeight="bold">
                        MID-TERM 20%
                      </MDTypography>
                    </th>
                    <th key={`exam${term}`}>
                      <MDTypography variant="button" fontWeight="bold">
                        EXAM 60%
                      </MDTypography>
                    </th>
                    <th key={`total${term}`}>
                      <MDTypography variant="button" fontWeight="bold">
                        TOTAL
                      </MDTypography>
                    </th>
                    <th key={`avg${term}`}>
                      <MDTypography variant="button" fontWeight="bold">
                      Subject Average
                      </MDTypography>
                    </th>
                    
                    <th key={`pos${term}`}>
                      <MDTypography variant="button" fontWeight="bold">
                        Subject Position
                      </MDTypography>
                    </th> 
                  </tr>
                </thead>
                <tbody style={{ width: "100%" }} key={done}>
                  {showComponent && showSubjectInfos(subject._id)}
                </tbody>
              </table>
              </div>)
            }
          )}
          <div className="overallTable">
          <MDTypography variant="h6">OverAll</MDTypography>
          <table>
            <thead>
              <tr>
                {/* {broadSheetDummyData.overAll.headers.map((header) => (
                  <th key={header}>
                    <MDTypography variant="button" fontWeight="bold">
                      {header}
                    </MDTypography>
                  </th>
                ))} */}
                <th key={'Overall Average'}>
                  <MDTypography variant="button" fontWeight="bold">
                    Overall Average
                  </MDTypography>
                </th>
                <th key={'Overall Total'}>
                  <MDTypography variant="button" fontWeight="bold">
                    Overall Total
                  </MDTypography>
                </th>
                <th key={'Overall Position'}>
                  <MDTypography variant="button" fontWeight="bold">
                    Overall Position
                  </MDTypography>
                </th>
              </tr>
            </thead>
             {showComponent && handleOverAlls()}
            {/* {broadSheetDummyData.overAll.overAllScores.map((score) => {
              return (
                <tr key={Math.random()}>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{score.OverallAverage}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{score.OverallTotal}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{score.OverallPosition}</MDTypography>
                  </td>
                </tr>
              );
            })} */}
          </table>
        </div>
        {/* <div className="psychomotorEvaluationTable">
          <div className="psychomotorHeader">
            <MDTypography variant="h6">Psychomotor Evaluation</MDTypography>
          </div>
          <table>
            <thead>
              <tr>
                <div className="psychomotorDropdownBox">
                  <div
                    className="selectedEvaluation"
                    onClick={() => {
                      setEvaluationDropDownDisplay(true);
                    }}
                  >
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      display="flex"
                      alignItems="center"
                    >
                      {selectedEvaluationName}&nbsp;<Icon>expand_more</Icon>
                    </MDTypography>
                  </div>
                  <div
                    style={{
                      display: `${evaluationDropDownDisplay ? "flex" : "none"}`,
                    }}
                    className="evaluationMenuBox"
                  >
                    <Card sx={{ width: "100%", height: "100%", p: 1 }}>
                      <ul>
                        {students.skills.map((option) => {
                          return (
                            <li
                              key={option._id}
                              onClick={() => {
                                setEvaluationDropDownDisplay(false);
                                setSelectedEvaluation(option._id);
                                setSelectedEvaluationName(option.name)
                              }}
                            >
                              <MDTypography variant="button" fontWeight="bold">
                                {option.name}
                              </MDTypography>
                            </li>
                          );
                        })}
                      </ul>
                    </Card>
                  </div>
                </div>
                <div>
                  {[5, 4, 3, 2, 1].map((value) => {
                    return (
                      <th key={value}>
                        <MDTypography variant="button" fontWeight="bold">
                          {value}
                        </MDTypography>
                      </th>
                    );
                  })}
                </div>
              </tr>
            </thead>
            {students.studentArr.map((student) => {
              return (
                <tr key={student.studentDetails._id}>
                  {[5, 4, 3, 2, 1].map((data) => {
                    return (
                      <td key={data}>
                        {selectedEvaluation.length > 0 && (
                          <> */}
                          { /* {!isAdmin ? (
                              <input
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleStudentPsychomotorEvaluationChange(
                                    student.student,
                                    data.toString()
                                  )
                                }
                                type="checkbox"
                                checked={
                                  (selectedEvaluation === "Legibility" &&
                                    student.legibility === data.toString()) ||
                                  (selectedEvaluation === "Accuracy" &&
                                    student.accuracy === data.toString()) ||
                                  (selectedEvaluation === "Neatness" &&
                                    student.neatness === data.toString()) ||
                                  (selectedEvaluation === "Dexterity" &&
                                    student.dexterity === data.toString())
                                    ? true
                                    : false
                                }
                              />
                            ) : (*/}
                            {/* {handleSelectPsych(data, student)} */}
                              {/* <MDTypography variant="button" >
                                <Icon onClick={()=>{
                                    sendRating(student.studentDetails._id, data)
                                  }
                                }>
                                  {student.skills.map((skill)=>{
                                    {selectedEvaluation === skill.skill &&
                                        skill.rating === data
                                        ? "check"
                                        : ""
                                     }})
                                  } */}
                                  {/* {(selectedEvaluation === "Legibility" &&
                                    student.legibility === data.toString()) ||
                                  (selectedEvaluation === "Accuracy" &&
                                    student.accuracy === data.toString()) ||
                                  (selectedEvaluation === "Neatness" &&
                                    student.neatness === data.toString()) ||
                                  (selectedEvaluation === "Dexterity" &&
                                    student.dexterity === data.toString())
                                    ? "check"
                                    : ""} */}
                                {/* </Icon>
                              </MDTypography> */}
                           {/* )}*/}
                          {/* </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </table> */}
          {/* <MDBox paddingTop="10px">
            <MDButton color="dark" onClick={() => reload(getTemp+1)}>
              <Icon>publish</Icon>&nbsp;Set
            </MDButton>
          </MDBox> */}
        {/* </div> */}
       {/* {isAdmin && (*/}
          {/* <div className="enabilityTable">
            <MDTypography variant="h6">_</MDTypography>
            <table>
              <thead>
                <tr>
                  <th>
                    <MDTypography variant="h6">Enability</MDTypography>
                  </th>
                </tr>
              </thead>
              {students.map((student) => {
                return (
                  <tr key={student.No}>
                    <Switch
                      checked={student.isEnabled}
                      onChange={() => updateStudentEnability(student.No)}
                    />
                  </tr>
                );
              })}
            </table>
          </div> */}
       {/* )}*/}
        {renderEdit && 
        <EditScoreForm
          initialValue={valueToEdit}
          cancelled={() => setRenderEdit(false)}
          newScoreSubmit={createScore}
          submitted={editScore}
          test={testToEdit}
          students={students}
          index1={firstIndex}
          index2={secondIndex}
        />
        }
        {renderCreate && 
        <CreateScoreForm
          initialValue={valueToEdit}
          cancelled={() => setRenderCreate(false)}
          submitted={createScore}
          test={testToEdit}
          idToCreate={idToCreate}
          classToCreate={classToCreate}
          subjectToCreate={subjectToCreate}
          students={students}
        />
        }
      </div>}
    </Card>   
      {students.class.disApproved && <small style={{color: 'red'}}>**last submission was not approved</small>}     
    {thirdTermState && !students.class.third_locked && 
      <MDBox paddingTop="10px">
        <MDButton color="dark" onClick={() => submitClass()}>
          <Icon>publish</Icon>&nbsp;Submit
        </MDButton>
      </MDBox>}
    {secondTermState && !students.class.second_locked && 
      <MDBox paddingTop="10px">
        <MDButton color="dark" onClick={() => submitClass()}>
          <Icon>publish</Icon>&nbsp;Submit
        </MDButton>
      </MDBox>}
    {firstTermState && !students.class.first_locked && 
      <MDBox paddingTop="10px">
        <MDButton color="dark" onClick={() => submitClass()}>
          <Icon>publish</Icon>&nbsp;Submit
        </MDButton>
      </MDBox>}
      {thirdTermState && students.class.third_locked && 
      <MDBox paddingTop="10px">
        <MDButton color="dark" disabled>
          <Icon>publish</Icon>&nbsp;Submitted
        </MDButton>
      </MDBox>}
    {secondTermState && students.class.second_locked && 
      <MDBox paddingTop="10px">
        <MDButton color="dark" disabled>
          <Icon>publish</Icon>&nbsp;Submitted
        </MDButton>
      </MDBox>}
    {firstTermState && students.class.first_locked && 
      <MDBox paddingTop="10px">
        <MDButton color="dark" disabled>
          <Icon>publish</Icon>&nbsp;Submitted
        </MDButton>
      </MDBox>}
    </> 
  );
}

BroadSheet.propTypes = {
  data: PropTypes.object.isRequired,
  isClicked: PropTypes.number,
  reload: PropTypes.func.isRequired,
  showLoad: PropTypes.func.isRequired,
  counter: PropTypes.number,
};
