import Card from "@mui/material/Card";

import PropTypes from "prop-types";

import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Switch from "@mui/material/Switch";
import { useState, useEffect } from "react";

const psychomotorEvaluationOptions = ["Select", "Legibility", "Accuracy", "Neatness", "Dexterity"];

export default function BroadSheet({ data, isClicked, isAdmin }) {
  const [selectedEvaluation, setSelectedEvaluation] = useState(psychomotorEvaluationOptions[0]);
  const [studentsState, setStudentsState] = useState(data.psychomotorEvaluation);
  const [evaluationDropDownDisplay, setEvaluationDropDownDisplay] = useState(false);
  const [students, setStudents] = useState(data.students.students);

  //Updating Students PsychomotorEvaluation
  const handleStudentPsychomotorEvaluationChange = (name, newValue) => {
    switch (selectedEvaluation) {
      case "Legibility":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  legibility: item.legibility === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Accuracy":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  accuracy: item.accuracy === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Neatness":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  neatness: item.neatness === newValue ? "" : newValue,
                }
              : item
          )
        );
      case "Dexterity":
        return setStudentsState((prevData) =>
          prevData.map((item) =>
            item.student === name
              ? {
                  ...item,
                  dexterity: item.dexterity === newValue ? "" : newValue,
                }
              : item
          )
        );
      default:
        return;
    }
  };

  //Updating StudentEnability
  const updateStudentEnability = (no) => {
    setStudents((prevData) =>
      prevData.map((item) =>
        item.No === no ? { ...item, isEnabled: item.isEnabled ? false : true } : item
      )
    );
  };

  //When ever the Publish button on the teacher's dashboard is Clicked
  useEffect(() => {
    isClicked > 0 && console.log('clicked');
  }, [isClicked]);

  return (
    <Card
      sx={{
        width: "100%",
        mt: 3,
        p: 2,
      }}
    >
      <div className="broadSheetBox">
        {/* The table that displays students name and number */}
        <div className="studentsTable">
          <MDTypography variant="h6">Students</MDTypography>
          <table>
            <thead>
              <tr>
                {data.students.headers.map((header) => (
                  <th key={header}>
                    <MDTypography variant="button" fontWeight="bold">
                      {header}
                    </MDTypography>
                  </th>
                ))}
              </tr>
            </thead>

            {data.students.students.map((student) => {
              return (
                <tr key={student.name}>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{student.No}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{student.name}</MDTypography>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        {/* Mapping all subjects and scores to a table */}
        {data.subjectsAndScores.map((data) => {
          return (
            <div key={data.subject} className="tableBox">
              <MDTypography variant="h6">{data.subject}</MDTypography>
              <table>
                <thead style={{ width: "100%" }}>
                  <tr className="tableHeaderRow">
                    {data.headers.map((header) => {
                      return (
                        <th key={header}>
                          <MDTypography variant="button" fontWeight="bold">
                            {header}
                          </MDTypography>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody style={{ width: "100%" }}>
                  {data.studentResults.map((student) => {
                    return (
                      <tr key={Math.random()} className="tableHeaderRow">
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">{student.CA}</MDTypography>
                        </td>
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">{student.MidTerm}</MDTypography>
                        </td>
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">{student.Exam}</MDTypography>
                        </td>
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">{student.Total}</MDTypography>
                        </td>
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">{student.SubjectAverage}</MDTypography>
                        </td>
                        <td style={{ display: "flex", justifyContent: "center" }}>
                          <MDTypography variant="overline">{student.SubjectPosition}</MDTypography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
        <div className="overallTable">
          <MDTypography variant="h6">OverAll</MDTypography>
          <table>
            <thead>
              <tr>
                {data.overAll.headers.map((header) => (
                  <th key={header}>
                    <MDTypography variant="button" fontWeight="bold">
                      {header}
                    </MDTypography>
                  </th>
                ))}
              </tr>
            </thead>

            {data.overAll.overAllScores.map((score) => {
              return (
                <tr key={Math.random()}>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{score.OverallAverage}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{score.OverallTotal}</MDTypography>
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography variant="overline">{score.OverallPosition}</MDTypography>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
        <div className="psychomotorEvaluationTable">
          <div className="psychomotorHeader">
            <MDTypography variant="h6">Psychomotor Evaluation</MDTypography>
          </div>
          <table>
            <thead>
              <tr>
                <div className="psychomotorDropdownBox">
                  <div
                    className="selectedEvaluation"
                    onClick={() => {
                      setEvaluationDropDownDisplay(true);
                    }}
                  >
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      display="flex"
                      alignItems="center"
                    >
                      {selectedEvaluation}&nbsp;<Icon>expand_more</Icon>
                    </MDTypography>
                  </div>
                  <div
                    style={{
                      display: `${evaluationDropDownDisplay ? "flex" : "none"}`,
                    }}
                    className="evaluationMenuBox"
                  >
                    <Card sx={{ width: "100%", height: "100%", p: 1 }}>
                      <ul>
                        {psychomotorEvaluationOptions.map((option) => {
                          return (
                            <li
                              key={option}
                              onClick={() => {
                                setEvaluationDropDownDisplay(false);
                                setSelectedEvaluation(option);
                              }}
                            >
                              <MDTypography variant="button" fontWeight="bold">
                                {option}
                              </MDTypography>
                            </li>
                          );
                        })}
                      </ul>
                    </Card>
                  </div>
                </div>
                <div>
                  {[5, 4, 3, 2, 1].map((value) => {
                    return (
                      <th key={value}>
                        <MDTypography variant="button" fontWeight="bold">
                          {value}
                        </MDTypography>
                      </th>
                    );
                  })}
                </div>
              </tr>
            </thead>
            {studentsState.map((student) => {
              return (
                <tr key={student.student}>
                  {[5, 4, 3, 2, 1].map((data) => {
                    return (
                      <td key={data}>
                        {selectedEvaluation !== "Select" && (
                          <>
                            {!isAdmin ? (
                              <input
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleStudentPsychomotorEvaluationChange(
                                    student.student,
                                    data.toString()
                                  )
                                }
                                type="checkbox"
                                checked={
                                  (selectedEvaluation === "Legibility" &&
                                    student.legibility === data.toString()) ||
                                  (selectedEvaluation === "Accuracy" &&
                                    student.accuracy === data.toString()) ||
                                  (selectedEvaluation === "Neatness" &&
                                    student.neatness === data.toString()) ||
                                  (selectedEvaluation === "Dexterity" &&
                                    student.dexterity === data.toString())
                                    ? true
                                    : false
                                }
                              />
                            ) : (
                              <MDTypography variant="button">
                                <Icon>
                                  {(selectedEvaluation === "Legibility" &&
                                    student.legibility === data.toString()) ||
                                  (selectedEvaluation === "Accuracy" &&
                                    student.accuracy === data.toString()) ||
                                  (selectedEvaluation === "Neatness" &&
                                    student.neatness === data.toString()) ||
                                  (selectedEvaluation === "Dexterity" &&
                                    student.dexterity === data.toString())
                                    ? "check"
                                    : ""}
                                </Icon>
                              </MDTypography>
                            )}
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </table>
        </div>
        {isAdmin && (
          <div className="enabilityTable">
            <MDTypography variant="h6">_</MDTypography>
            <table>
              <thead>
                <tr>
                  <th>
                    <MDTypography variant="h6">Enability</MDTypography>
                  </th>
                </tr>
              </thead>
              {students.map((student) => {
                return (
                  <tr key={student.No}>
                    <Switch
                      checked={student.isEnabled}
                      onChange={() => updateStudentEnability(student.No)}
                    />
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>
    </Card>
  );
}

BroadSheet.propTypes = {
  data: PropTypes.object.isRequired,
  isClicked: PropTypes.number,
  isAdmin: PropTypes.bool,
};
