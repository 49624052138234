import PropTypes from "prop-types";

import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";

import { useState, useEffect } from "react";

export default function CreateScoreForm({ submitted, cancelled, initialValue, test, idToCreate, classToCreate, subjectToCreate, students }) {
  const [opacity, setOpacity] = useState(0);
  const [scoreValue, setScoreValue] = useState(0);
  const [testValue, setTestValue] = useState(test);
  const [studentsValues, setStudentsValues] = useState(students);
  const [errorState, setErrorState] = useState(false);
//   const [tValue, setIdValue] = useState(test);
  const [idValue, setIdValue] = useState(idToCreate);




  const handleSubmit = (e) => {
    e.preventDefault();
    if(isNaN(scoreValue)){
      setErrorState(true)
      return
    } else {
    const data = {
      score: scoreValue,
      test: testValue,
      userId: studentsValues.studentArr[idValue].studentDetails._id,
      classId: classToCreate,
      subjectId: subjectToCreate
    };
    submitted(data);
    }
  };

  useEffect(() => {
    setOpacity(1);
  }, []);
  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      <Card
        style={{ width: `${window.innerWidth - 60 + "px"}`, maxWidth: "430px" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <form className="form" action="" method="post" onSubmit={handleSubmit}>
          <div className="formHeaderRow">
            <MDTypography variant="h6">Edit Score</MDTypography>
            <Icon style={{ cursor: "pointer" }} onClick={cancelled}>
              cancel
            </Icon>
          </div>
          {errorState && <small style={{color: 'red', fontSize: 12,float: 'left'}}>**please enter a number</small>}
          <div className="rows">
            <MDInput
              type="text"
              label="Score"
              value={scoreValue}
              onChange={(e) => setScoreValue(e.target.value)}
              fullWidth
              required
            ></MDInput>
          </div>

          <div className="rows">
            <MDButton type="submit" variant="contained" color="dark" fullWidth>
              <Icon>add</Icon>
              Submit
            </MDButton>
          </div>
        </form>
      </Card>
    </div>
  );
}

CreateScoreForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  test: PropTypes.string.isRequired,
  idToCreate: PropTypes.string.isRequired,
  classToCreate: PropTypes.string.isRequired,
  subjectToCreate: PropTypes.string.isRequired,
  students: PropTypes.object.isRequired,
};
