//Dummy Data
import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import Card from "@mui/material/Card";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import BroadSheet from "./broadSheet";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loading from "components/loadingComponent";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import Footer from "examples/Footer";
import { LoginContext } from "../../App";
import { server } from "utils/server";


export default function AdminSettings() {
 // const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [isClicked, setIsClicked] = useState(0);
  const [adminState, setAdminState] = useState(false);
  const [teacherState, setTeacherState] = useState(true);
  const [staffState, setStaffState] = useState(false);
  const [parentState, setParentState] = useState(false);
  const [pupilState, setPupilState] = useState(false);
  const [pupilData, setPupilData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [userState, setUserState] = useState(true);
  const [generalEndState, setGeneralEndState] = useState(true);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [skills, setSkills] = useState([]);
  const [firstTermState, setFirstTermState] = useState(false);
  const [secondTermState, setSecondTermState] = useState(false); 
  const [thirdTermState, setThirdTermState] = useState(true); 
  const [session, setSession] = useState(); 
  const [term, setTerm] = useState();
  const [currentSessions, setCurrentSessions] = useState(); 
  const [currentTerms, setCurrentTerms] = useState();
  const [newSession, setNewSession] = useState(); 
  const [newTerm, setNewTerm] = useState();
  const [selectKey, setSelectKey] = useState(0);
  const [currentSessionUI, setCurrentSessionUI] = useState(0);


  const [broadsheetRendering, setBroadsheetRendering] = useState([]);
  const [renderSheets, setRenderSheets] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [prevFirstSession, setPrevFirstSession] = useState();
  const [renderCreate, setRenderCreate] = useState(false);
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const { server1 } = server()
  const [num, setNum] = useState(0);
  const navigation = useNavigate();
  const [done, setDone] = useState(false);
  var isAdmin= true

  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
  }, []);


  const getSession = async () => {
    
     const response = await fetch(`${server1}/admins/get_current_session`, {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
         "sds": cookie.sds_auth_token
       },
     });
     const responseData = await response.json();
     if (response.ok) {
      const date = new Date();
      if(responseData.data.session){
        setSession(responseData.data.session)
      }
      
    
     if(responseData.data.term){
      setTerm(responseData.data.term)
     }
      
       
     } else {
       console.log('success')
     }
   };

   const setBackward= ()=>{
    var sessions= []
    var currSess= []
    for(let i=prevFirstSession-10; i<prevFirstSession;i++){
      sessions= [...sessions, i]
      currSess.push(
        <option value={i}>
          {`${i}/${i+1}`}
        </option>
      )
    }
    setCurrentSessionUI(currSess)

    setPrevFirstSession(prevFirstSession-10)
    setCurrentSessions(sessions)
    setSelectKey(selectKey+2)
   }

   const setFoward= ()=>{
    var sessions= []
    var currSess= []
    for(let i=prevFirstSession+10; i<prevFirstSession+20;i++){
      sessions= [...sessions, i]
      currSess.push(
        <option value={i}>
          {`${i}/${i+1}`}
        </option>
      )
    }
    setCurrentSessionUI(currSess)
    
    setPrevFirstSession(prevFirstSession+10)
    setCurrentSessions(sessions)
    setSelectKey(selectKey+2)
   }

   useEffect(() => {
      var sessions= []
      var terms=[]
      if(!session || session === undefined){
       setSession(new Date().getFullYear());
      }
      for(let i=session; i<session+10;i++){
        sessions= [...sessions, i]
      }
    
      var currSess= []
      setPrevFirstSession(session+10)
      for (let index = 0; index < sessions.length; index++) {
        currSess.push(
          <option value={sessions[index]}>
            {`${sessions[index]}/${sessions[index]+1}`}
          </option>
        )
      }
        setCurrentSessionUI(currSess)
        // return currSess
    if(!term || term === undefined){
      setTerm(1);
    }
    
      if(term==1){
        terms=[
          {
            term: 'first',
            value: 1
          },
          {
            term: 'second',
            value: 2
          },
          {
            term: 'third',
            value: 3
          }
        ]
      }
      if(term==2){
        terms=[
          {
            term: 'second',
            value: 2
          },
          {
            term: 'first',
            value: 1
          },
          {
            term: 'third',
            value: 3
          }
        ]
      }
      if(term==3){
        terms=[
          {
            term: 'third',
            value: 3
          },
          {
            term: 'first',
            value: 1
          },
          {
            term: 'second',
            value: 2
          }
        ]
      }
      setCurrentSessions(sessions)
      setCurrentTerms(terms)
      setSelectKey(selectKey+2)
      
      //checkLogged();
    }, [term]);
   
    const setSessionInfo= async () => {
      setIsLoading(true)
      const data= {
        session: newSession,
        term: newTerm
      }
       const response = await fetch(`${server1}/admins/set_current_session`, {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
           "sds": cookie.sds_auth_token
         },
         body: JSON.stringify(data)
       });
       const responseData = await response.json();
       if (response.ok) {
        if(responseData.data.session){
          setSession(responseData.data.session)
        }
      
       if(responseData.data.term){
        setTerm(responseData.data.term)
       }
       setIsLoading(false)
       } else {
         console.log('success')
         setIsLoading(false)
       }
     };


useEffect(() => {
    if(!cookie.sds_auth_token){
      navigation('/')
    }
    setIsLoading(true)
    getSession().then(()=>{
      setIsLoading(false)
    })
  //checkLogged();
}, []);





  return (
    <DashboardLayout>
      {isLoading && <Loading />}
        {alertDisplay && (
          <div
            style={{
              background: alertType === "error" ? "#d31a38" : "green",
              width: 350,
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}
      <DashboardNavbar />
      <MDTypography variant="h5">Class Broadsheets</MDTypography>
      <MDBox paddingTop="15px" display="flex" flexDirection="column" gap="15px">
      <MDTypography variant="h6">Current Session: {session&&`${session}/${session+1}`}</MDTypography>
      <MDTypography variant="h6">Current Term: {term?term==1?'first':term==2?'first':'third':''} {term&&'term'}</MDTypography>
      </MDBox>
      <div style={{display: 'table', margin: '0 auto', justifyItems: 'center'}}>
      <h4 style={{textAlign: 'center'}}>Select Session</h4>
      <div key={selectKey}>
      <div style={{display: 'flex'}}>
        <div>
        <button style={{width: 50, margin: 10, cursor: 'pointer'}} onClick={setBackward}>
           {'<'} 
        </button>
        </div>
        <select style={{width: 100, margin: 10}} key={selectKey} onChange={e=>setNewSession(e.target.value)}>
          {currentSessionUI && currentSessionUI}
          
           {currentSessions && currentSessions.map(current=>(
            <option value={current}>
            {`${current}/${current+1}` }
          </option>
          ))}
        </select>
        <div>
          <button style={{width: 50, margin: 10, cursor: 'pointer'}} onClick={setFoward}>
           {'>'}  
          </button>
        </div>
      </div>
      <h4 style={{textAlign: 'center'}}>Select Term</h4>
      <div style={{display: 'table', margin: '0 auto'}}>
      <select style={{width: 100}} key={selectKey} onChange={e=>setNewTerm(e.target.value)}>
          {currentTerms && currentTerms.map(current=>(
            <option value={current.value}>
            {current.term} term
          </option>
          ))}
        </select>
      </div>
      <div style={{display: 'table', margin: '0 auto'}}>
      <button style={
        {
          background: '#1E3F66', 
          width: 100, 
          borderRadius: 10, 
          padding: 10, 
          border: 'none', 
          marginTop: 15,
          color: '#fff',
          cursor: 'pointer'
          }
        } 
        onClick={setSessionInfo}
        >
        set
      </button>
      </div>
      </div>
      </div>
    </DashboardLayout>
  );
}


