import { useState, useContext, useEffect } from "react";
import Loading from "components/loadingComponent";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";

import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { LoginContext } from "../../../App";

import AdminForm from "./adminForm";
import { server } from "utils/server";

export default function ManageAdmin() {
  const [formDisplay, setFormDisplay] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [adminData, setAdminData] = useState(null);
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [allAdmin, setAllAdmin] = useState([]);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const navigation = useNavigate();
  const { server1 } = server()

  const createUser = async (userData) => {
    setFormDisplay(false);
    setIsLoading(true);
    try {
      
      const response = await fetch(`${server1}/admins/create_admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
          "sds": cookie.sds_auth_token
        },
        body: JSON.stringify({
          firstName: userData.firstName,
          middleName: userData.middleName,
          lastName: userData.lastName,
          staffNumber: userData.lastName,
          address: userData.address,
          permanentAddress: userData.permanentAddress,
          phone: userData.phone,
          email: userData.email,
          dateOfbirth: userData.dateOfbirth,
          state: userData.state,
          lga: userData.lga,
          role: userData.role,
        }),
      });
      var msg = await response.json();
      if (response.ok) {
        // User creation succeeded
        setAlertMessage("Admin created successfully");
        setAlertType("success");
        setAdminData(msg.body);
        setAlertDisplay(true);
        getAdmins()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
        const responseData = await response.json();
        
      } else {
        // User creation failed
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
        // const errorData = await response.json();
        // console.log(errorData);
        // console.error("User creation failed:", errorData.error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  };

  const getAdmins = async () => {
    setIsLoading(true);
    const response = await fetch(`${server1}/admins/get_all_admins`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "sds": cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setAllAdmin(responseData.data);
      setIsLoading(false);
     
    } else {
      // User creation failed
      setAlertMessage("could not get classes");
      setAlertType("error");
      setAlertDisplay(true);
      const errorData = await response.json();
      //console.error("User creation failed:", errorData.error);
    }
  };

  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
    getAdmins()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <DashboardLayout>
        {alertDisplay && (
          <div
            style={{
              background: alertType == "error" ? "#d31a38" : "green",
              width: 350,
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}
        <DashboardNavbar zIndex={formDisplay ? -1 : 1} />
        <MDBox sx={{ mx: 2 }}>
          <MDButton variant="contained" color="dark" onClick={() => setFormDisplay(true)}>
            <Icon>add</Icon> Add Admin
          </MDButton>
        </MDBox>
        {formDisplay && (
          <AdminForm cancelled={() => setFormDisplay(false)} submitted={createUser} />
        )}
        {allAdmin.length > 0 && (
          <Card sx={{ p: 2, mt: 2 }}>
            <MDBox display="flex" flexDirection="column">
              <MDBox
                width="100%"
                // maxWidth="450px"
                display="flex"
                justifyContent="space-between"
                marginBottom="4px"
                padding="0px 0px 8px 14px"
                borderBottom="1px solid gray"
              >
                <MDTypography variant="h6">Descriptions</MDTypography>
                <MDTypography variant="h6">Actions</MDTypography>
              </MDBox>
              {allAdmin &&
                allAdmin.map((data) => {
                  return (
                    <div className="itemList" key={data._id}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <MDTypography variant="button" fontWeight="regular">
                          {allAdmin.indexOf(data) + 1}.
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="medium">
                          &nbsp;&nbsp;&nbsp;
                          {`${data.firstName} ${
                            data.middleName ? data.middleName : data.lastName
                          } ${data.middleName ? data.lastName : ""}`}
                        </MDTypography>
                      </div>
                    </div>
                  );
                })}
            </MDBox>
          </Card>
        )}
      </DashboardLayout>
    </>
  );
}
