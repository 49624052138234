import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { server } from "utils/server";

import picture from "assets/images/user.jpg";

import PropTypes from "prop-types";

import { useState, useEffect, useRef } from "react";
import { Cookies, useCookies } from "react-cookie";

// components
import { StudentInfo } from "./components/info";

export default function EditStudent({ submitted, cancelled, initialData }) {
  const [opacity, setOpacity] = useState(0);
  const [profile, setProfile] = useState(picture);
  const [StudentData, setStudentData] = useState([]);
  const [classData, setClassData] = useState("");
  const [classDataName, setClassDataName] = useState("");
  const [classArmValue, setClassArmValue] = useState("");
  const [classes, setClasses] = useState([]);
  const [cookie, setCookie] = useCookies(["sds_auth_token"]);
  const [thirdSection, setThirdSection] = useState(false);
  const [showPromotionAction, setShowPromotionAction] = useState(false);
  const [showRepeatAction, setShowRepeatAction] = useState(false);
  const [showGratuateAction, setShowGratuateAction] = useState(false);
  const [showWithdrawAction, setShowWithdrawActoin] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [promotionClass, setPromotionClass] = useState("");
  const [showReAdmitAction, setShowReAdmitAction] = useState(false);
  const [showReAdmitGraduate, setShowReAdmitGraduate] = useState(false);
  const { server1 } = server();
  const profileRef = useRef(null);
  // const [ displayData, setDisplayData ] = useState([])

  useEffect(() => {
    setStudentData([]);

    const getCurrentSession = async () => {
      try {
        const response = await fetch(`${server1}/admins/get_current_session`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            sds: cookie.sds_auth_token,
          },
        });
        const responseData = await response.json();
        if (response.ok) {
          const term = responseData.data.term;
          if ((await term) === 3) {
            setThirdSection(true);
          } else {
            setThirdSection(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getCurrentSession();

    for (let key in initialData) {
      if (
        key !== "signupDate" &&
        key !== "status" &&
        key !== "_id" &&
        key !== "updatedAt" &&
        key !== "isVerified" &&
        key !== "createdAt" &&
        key !== "userId" &&
        key !== "class" &&
        key !== "isActive" &&
        key !== "studentId" &&
        key !== "paidFees"
      ) {
        setStudentData((prev) => [
          ...prev,
          {
            label: key,
            value: initialData[key] !== "" ? initialData[key] : "N/A",
            enability: true,
            type: `${key === "dateOfBirth" && "date"}`,
          },
        ]);
      }
      if (key === "class") {
        setClassData(initialData[key]);
        setClassArmValue(classData);
      }
    }
  }, [initialData]);

  const handleShowWithdrawAction = () => {
    showWithdrawAction ? setShowWithdrawActoin(false) : setShowWithdrawActoin(true);
  };

  const handleShowRepeatAction = () => {
    showRepeatAction ? setShowRepeatAction(false) : setShowRepeatAction(true);
  };

  const handleShowGratuateAction = () => {
    showGratuateAction ? setShowGratuateAction(false) : setShowGratuateAction(true);
  };

  const handleShowReAdmit = () => {
    showReAdmitAction ? setShowReAdmitAction(false) : setShowReAdmitAction(true);
  };

  const handleShowReAdmitGraduate = () => {
    showReAdmitGraduate ? setShowReAdmitGraduate(false) : setShowReAdmitGraduate(true);
  };

  useEffect(() => {
    for (let index = 0; index < classes.length; index++) {
      if (classes[index]._id === classData) {
        setClassDataName(classes[index].className);
      }
    }
  }, [classData, classes]);

  const getClasses = async () => {
    const response = await fetch(`${server1}/admins/get_all_classes_arms`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      setClasses(responseData.body);
    } else {
      const errorData = await response.json();
      console.error("User creation failed:", errorData.error);
    }
  };

  const handleChangeClass = (value) => {
    setClassArmValue(value);
    var temp = [];
    for (let index = 0; index < classes.length; index++) {
      if (classes[index].className === value) {
        temp = [
          ...temp,
          {
            arm: classes[index].classArm,
            id: classes[index]._id,
          },
        ];
      }
    }
  };

  const changeValue = (label, newValue) => {
    setStudentData((prevData) =>
      prevData.map((item) => (item.label === label ? { ...item, value: newValue } : item))
    );
  };

  const changeEnability = (label, newValue) => {
    setStudentData((prevData) =>
      prevData.map((item) => (item.label === label ? { ...item, enability: newValue } : item))
    );
  };

  const enableFee = async () => {
    try {
      const response = await fetch(`${server1}/admins/enable_fee/${initialData.studentId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });
      window.location.reload();
      // setStudentData(StudentData)
      if (response.ok) {
        setAlertMessage("Student created successfully");
        setAlertType("success");
        setStudentData("Student fee enabled");
        setAlertDisplay(true);
      } else {
        setAlertMessage("Something went wrong");
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const disableFee = async () => {
    try {
      const response = await fetch(`${server1}/admins/disable_fee/${initialData.studentId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });
      if (response.ok) {
        setAlertMessage("Student created successfully");
        setAlertType("success");
        setStudentData("Student fee enabled");
        setAlertDisplay(true);
        window.location.reload();
      } else {
        setAlertMessage("Something went wrong");
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Handling Submit
  const handleSubmit = () => {
    var temp = {
      label: "class",
      value: classArmValue,
      enability: true,
      type: "text",
    };
    var newStudentData = [...StudentData, temp];

    submitted(newStudentData);
  };

  useEffect(() => {
    getClasses();
    setOpacity(1);
  }, []);

  const handelGraduate = async () => {
    try {
      const response = await fetch(`${server1}/admins/graduate_student/${initialData.studentId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });
      const msg = await response.json();
      if (response.ok) {
        setAlertMessage("Student gratuated successfully");
        setAlertType("success");
        setAlertDisplay(true);
        setShowPromotionAction(false);
        window.location.reload();
      } else {
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      setAlertMessage("Something went wrong");
      setAlertType("error");
      setAlertDisplay(true);
    }
  };

  const handelReAdmitGraduate = async () => {
    try {
      const response = await fetch(
        `${server1}/admins/re_admit_graduated_student/${initialData.studentId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            sds: cookie.sds_auth_token,
          },
        }
      );
      const msg = await response.json();
      if (response.ok) {
        setAlertMessage("Student gratuated successfully");
        setAlertType("success");
        setAlertDisplay(true);
        setShowPromotionAction(false);
        window.location.reload();
      } else {
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      setAlertMessage("Something went wrong");
      setAlertType("error");
      setAlertDisplay(true);
    }
  };
  const handelWithdraw = async () => {
    try {
      const response = await fetch(`${server1}/admins/withdraw_student/${initialData.studentId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify({
          classId: promotionClass,
        }),
      });
      const msg = await response.json();
      if (response.ok) {
        setAlertMessage("Student witdrawn successfully");
        setAlertType("success");
        setAlertDisplay(true);
        setShowPromotionAction(false);
        window.location.reload();
      } else {
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      setAlertMessage("Something went wrong");
      setAlertType("error");
      setAlertDisplay(true);
    }
  };

  const handlePromotion = async () => {
    try {
      console.log(promotionClass, initialData.studentId);
      if (!promotionClass) {
        setAlertMessage("Please select a class");
        setAlertType("error");
        setAlertDisplay(true);
      }
      const response = await fetch(`${server1}/admins/promote_student/${initialData.studentId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify({
          classId: promotionClass,
        }),
      });
      const msg = await response.json();
      if (response.ok) {
        setAlertMessage("Student Promoted successfully");
        setAlertType("success");
        setAlertDisplay(true);
        setShowPromotionAction(false);
        window.location.reload();
      } else {
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.log(error);
      setAlertMessage("something went wrong");
      setAlertType("error");
      setAlertDisplay(true);
    }
  };

  const handleRepeat = async () => {
    try {
      const response = await fetch(`${server1}/admins/repeat_student/${initialData.studentId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify({
          classId: promotionClass,
        }),
      });
      const msg = await response.json();
      if (response.ok) {
        setAlertMessage("Student Repeated successfully");
        setAlertType("success");
        setAlertDisplay(true);
        setShowPromotionAction(false);
        window.location.reload();
      } else {
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Re-admit
  const handleReadAdmin = async () => {
    try {
      const response = await fetch(`${server1}/admins/re_admit_student/${initialData.studentId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify({
          classId: promotionClass,
        }),
      });
      const msg = await response.json();
      if (response.ok) {
        setAlertMessage("Student Re-Admitted successfully");
        setAlertType("success");
        setAlertDisplay(true);
        setShowPromotionAction(false);
        window.location.reload();
      } else {
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="modalBg" style={{ opacity: opacity }}>
      {alertDisplay && (
        <div
          style={{
            background: alertType === "error" ? "#d31a38" : "green",
            width: 350,
            position: "absolute",
            zIndex: 10,
            right: 40,
            color: "white",
            borderRadius: 20,
          }}
        >
          <div
            style={{
              height: 40,
              borderRadius: "50%",
              float: "right",
              marginRight: 18,
              cursor: "pointer",
              marginTop: 2,
            }}
            onClick={() => setAlertDisplay(false)}
          >
            ×
          </div>
          <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
        </div>
      )}
      <Card
        style={{ maxWidth: "600px", width: "88%" }}
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 0.5,
        }}
      >
        <div className="editFormLayer">
          <div className="formHeaderRow">
            <MDTypography variant="h6">Student</MDTypography>
            <MDTypography onClick={cancelled}>
              <Icon style={{ cursor: "pointer" }}>cancel</Icon>
            </MDTypography>
          </div>

          {StudentData && (
            <div style={{ display: "flex", padding: "15px 10px 10px 10px", flexWrap: "wrap" }}>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "15px",
                  padding: "20px 20px",
                  minWidth: "100px",
                }}
              >
                <img
                  alt=""
                  src={profile}
                  style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                />
                <input
                  ref={profileRef}
                  type="file"
                  style={{ position: "absolute", zIndex: "-1", opacity: 0 }}
                />
                <MDButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={() => profileRef.current.click()}
                >
                  Change
                </MDButton>
                {!initialData.withdrawed &&
                  !initialData.graduated &&
                  (initialData.fees !== "Paid" ? (
                    <MDButton color="success" size="small" onClick={() => disableFee()}>
                      Fees Paid
                    </MDButton>
                  ) : (
                    <MDButton color="error" size="small" onClick={() => enableFee()}>
                      Enable Fee
                    </MDButton>
                  ))}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                  gap: "10px",
                  height: "300px",
                  overflowY: "auto",
                  minWidth: "200px",
                }}
              >
                {StudentData &&
                  StudentData.map((data) => {
                    return (
                      <div
                        key={data.label}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                          padding: "0px 10px",
                        }}
                      >
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <MDTypography variant="button" fontWeight="medium">
                            {data.label === "firstName" && "First Name"}
                            {data.label === "middleName" && "Middle Name"}
                            {data.label === "lastName" && "Last Name"}
                            {data.label === "dateOfBirth" && "Date Of Birth"}
                            {data.label === "userName" && "User Name"}
                            {data.label === "permanentAddress" && "Permanent Address"}
                            {data.label !== "firstName" &&
                              data.label !== "middleName" &&
                              data.label !== "lastName" &&
                              data.label !== "dateOfBirth" &&
                              data.label !== "permanentAddress" &&
                              data.label !== "userName" &&
                              data.label}
                          </MDTypography>
                          <button
                            style={{
                              cursor: "pointer",
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => {
                              if (data.enability) {
                                changeEnability(data.label, false);
                              } else {
                                changeEnability(data.label, true);
                              }
                            }}
                          >
                            <MDTypography variant="overline" fontWeight="regular">
                              <Icon>{data.enability ? "edit" : "check"}</Icon>&nbsp;
                              {data.enability ? "Edit" : "Save"}
                            </MDTypography>
                          </button>
                        </div>
                        <MDInput
                          size="small"
                          value={data.value}
                          disabled={data.enability}
                          onChange={(e) => changeValue(data.label, e.target.value)}
                          type={data.type}
                        />
                      </div>
                    );
                  })}
                <div style={{ margin: "15px 0px", width: "100%" }}>
                  <MDTypography variant="h6">Student Class</MDTypography>
                  <div className="rows" style={{ padding: "0px 0px 10px 20px" }}>
                    <MDTypography variant="button">Select class for student</MDTypography>
                    <select
                      style={{ width: "240px", padding: "8px 4px" }}
                      required
                      onChange={({ target: { value } }) => handleChangeClass(value)}
                    >
                      <option value={classData} style={{ color: "#666" }}>
                        {classDataName}
                      </option>
                      {classes.map((classDetail) => {
                        return <option value={classDetail._id}>{classDetail.className}</option>;
                      })}
                    </select>
                  </div>
                </div>
                {thirdSection && (
                  <>
                    {showPromotionAction && (
                      <div className="promote-action-container">
                        <MDTypography variant="h6">Select class to be promoted to</MDTypography>
                        <div className="promote-action-sub-container">
                          <select
                            style={{ width: "100%", padding: "8px 4px" }}
                            required
                            onChange={({ target: { value } }) => setPromotionClass(value)}
                          >
                            <option value={classData} style={{ color: "#666" }}>
                              {classDataName}
                            </option>
                            {classes.map((classDetail) => {
                              return (
                                <option value={classDetail._id}>{classDetail.className}</option>
                              );
                            })}
                          </select>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px",
                            }}
                          >
                            <MDButton color="success" onClick={() => handlePromotion()}>
                              Promote
                            </MDButton>
                            <MDButton color="error" onClick={() => setShowPromotionAction(false)}>
                              Cancel
                            </MDButton>
                          </div>
                        </div>
                      </div>
                    )}
                    {showWithdrawAction && (
                      <StudentInfo
                        text="Are you sure you want to WITHDRAW this student"
                        action={handelWithdraw}
                        cancel={handleShowWithdrawAction}
                      />
                    )}
                    {showReAdmitGraduate && (
                      <StudentInfo
                        text="Are you sure you want to WITHDRAW this student"
                        action={handelWithdraw}
                        cancel={handleShowWithdrawAction}
                      />
                    )}
                    {showGratuateAction && (
                      <StudentInfo
                        text="Are you sure you want to this student to GRATUATE"
                        action={handelGraduate}
                        cancel={handleShowGratuateAction}
                      />
                    )}
                    {showReAdmitAction && (
                      <StudentInfo
                        text="Are you sure you want to re-admit this student"
                        action={handleReadAdmin}
                        cancel={handleShowReAdmit}
                      />
                    )}
                    {showRepeatAction && (
                      <StudentInfo
                        text="Are you sure you want to this re-admit this student"
                        action={handelReAdmitGraduate}
                        cancel={handleShowReAdmitGraduate}
                      />
                    )}
                    <MDTypography variant="h6">Actions</MDTypography>
                    {!initialData.withdrawed && !initialData.graduated && (
                      <div className="student-action-button-container">
                        {!showGratuateAction && (
                          <MDButton color="success" onClick={() => handleShowGratuateAction()}>
                            Graduate
                          </MDButton>
                        )}
                        {!showPromotionAction && (
                          <MDButton color="info" onClick={() => setShowPromotionAction(true)}>
                            Promote
                          </MDButton>
                        )}
                        {!showRepeatAction && (
                          <MDButton color="error" onClick={() => handleShowRepeatAction()}>
                            Repeat
                          </MDButton>
                        )}
                        {!showWithdrawAction && (
                          <MDButton onClick={() => handleShowWithdrawAction()} color="warning">
                            Withdraw
                          </MDButton>
                        )}
                      </div>
                    )}
                  </>
                )}
                {!showReAdmitAction && initialData.withdrawed && (
                  <MDButton onClick={() => handleShowReAdmit()} color="warning">
                    Re- Admit
                  </MDButton>
                )}
                {initialData.graduated && (
                  <MDButton onClick={() => handleShowReAdmit()} color="warning">
                    Re- Admit
                  </MDButton>
                )}
              </div>
            </div>
          )}
          <div className="rows">
            <MDButton onClick={handleSubmit} variant="contained" color="dark" fullWidth>
              <Icon>add</Icon>
              Submit
            </MDButton>
          </div>
        </div>
      </Card>
    </div>
  );
}

EditStudent.propTypes = {
  submitted: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
};
