/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useContext } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Loading from "components/loadingComponent";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import SettingsLinks from "layouts/settings";
import UpgradeToEnterprice from "./upgrade";
import { LoginContext } from "../../App";
import { server } from "utils/server.js";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const settingsRoutes = [
    "/manage-subjects",
    "/manage-classes",
    "/manage-form-teachers",
    "/manage-parents",
    "/manage-students",
    "/manage-psychomotor-skills",
    "/manage-admins",
  ];
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [settingsLinksDisplay, setSettingsLinksDisplay] = useState(false);
  const [hoveredGeneralAccounting, setHoveredGeneralAccounting] = useState(false);
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [adminState, setAdminState] = useState(false);
  const [teacherState, setTeacherState] = useState(false);
  const [staffState, setStaffState] = useState(false);
  const [parentState, setParentState] = useState(false);
  const [pupilState, setPupilState] = useState(false);
  const [userState, setUserState] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [generalEndState, setGeneralEndState] = useState(true);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [cookie, setCookie, removeCookie] = useCookies();
  const navigation = useNavigate();
  const { server1 } = server();

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const getUser = async () => {
    try {
      //setIsLoading(true);
      const response = await fetch(`${server1}/admins/get_current_user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
      });
      const resData = await response.json();
      if (response.ok) {
        // User creation succeeded
        // setAllParents(responseData.data);

        const responseData = resData;

        if (responseData.data[0].type.includes("admin")) {
          setAdminState(true);
          setStaffState(true);
        }
        if (responseData.data[0].type.includes("parent")) {
          setParentState(true);
        }
        if (responseData.data[0].type.includes("teacher")) {
          setTeacherState(true);
          setStaffState(true);
        }
        if (responseData.data[0].type.includes("student")) {
          setPupilState(true);
        }
      } else {
        // User creation failed
        // setAlertMessage("not authorized");
        setAlertType("error");
        // setAlertDisplay(true);

        //console.error("User creation failed:", errorData.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeSidenav = () => setMiniSidenav(dispatch, true);
  const handleLogOut = () => {
    setIsLoading(true);
    removeCookie("sds_auth_token");

    // setTimeout(()=>{
    //   navigation("/");
    // }, 5000)

    //setIsLoggedIn("");
    navigation("/");
  };

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  useEffect(() => {
    getUser();
    setSettingsLinksDisplay(false);
  }, [collapseName]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });
  const renderGeneralRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "general") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      }
      return returnValue;
    }
  );
  const renderAdminRoutes = routes.map(
    ({ type, name, icon, caret, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "admin" && name !== "Settings" && name !== "General Accounting") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={window.location.pathname.startsWith(route)}
            />
          </NavLink>
        );
      } else if (name === "Settings") {
        returnValue = (
          <div className="settingsDropDown">
            <SidenavCollapse
              onClick={() => setSettingsLinksDisplay((prev) => !prev)}
              name={name}
              icon={icon}
              caret={caret}
              active={settingsRoutes.indexOf(window.location.pathname) !== -1}
            />
            {settingsLinksDisplay && <SettingsLinks />}
          </div>
        );
      } else if (name === "General Accounting") {
        returnValue = (
          <div
            className="settingsDropDown"
            onMouseOut={() => setHoveredGeneralAccounting(false)}
            onMouseOver={() => setHoveredGeneralAccounting(true)}
          >
            <SidenavCollapse name={name} icon={icon} />
            {hoveredGeneralAccounting && <UpgradeToEnterprice />}
          </div>
        );
      }
      return returnValue;
    }
  );
  const renderGeneralEndRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "generalend") {
        returnValue = href ? (
          <div
            //href={"/dashboard"}
            key={key}
            // target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
            onClick={handleLogOut}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </div>
        ) : (
          // to={route}
          <div onClick={handleLogOut} key={key}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </div>
        );
      }
      return returnValue;
    }
  );
  const renderStaffRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "staff") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      }
      return returnValue;
    }
  );
  const renderTeacherRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "teacher") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      }
      return returnValue;
    }
  );
  const renderPupilRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "pupil") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      }
      return returnValue;
    }
  );
  const renderUserRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "user") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      }
      return returnValue;
    }
  );
  const renderParentRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "parents") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      }
      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      {isLoading && <Loading />}
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              MENU
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>
        {renderGeneralRoutes}
        {staffState && renderStaffRoutes}
        {adminState && renderAdminRoutes}
        {userState && renderUserRoutes}
        {pupilState && renderPupilRoutes}
        {parentState && renderParentRoutes}
        {teacherState && renderTeacherRoutes}
        {generalEndState && renderGeneralEndRoutes}
      </List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
