import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import { Cookies, useCookies } from "react-cookie";

import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ClassForm from "./classForm";
import Loading from "components/loadingComponent";
import { LoginContext } from "../../../App";
import EditClassForm from "./editClass";
import { server } from "utils/server";

export default function ManageClasses() {
  const [formDisplay, setFormDisplay] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [classData, setClassData] = useState(null);
  // const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const [classes, setClasses] = useState([]);
  const [cookie, setCookie, removeCookie] = useCookies(["sds_auth_token"]);
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [valueToEdit, setValueToEdit] = useState("");
  const [otherInfoToEdit, setOtherInfoToEdit] = useState({});
  const [renderEdit, setRenderEdit] = useState(false);
  const [idToEdit, setIdToEdit] = useState("");
  const { server1 } = server();

  useEffect(() => {
    if (!cookie.sds_auth_token) {
      navigation("/");
    }
    getClasses()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    valueToEdit !== "" && setRenderEdit(true);
  }, [valueToEdit]);

  useEffect(() => {
    !renderEdit && setValueToEdit("");
  }, [renderEdit]);

  const getClasses = async () => {
    setIsLoading(true);
    const response = await fetch(`${server1}/admins/get_all_classes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sds: cookie.sds_auth_token,
      },
    });
    const responseData = await response.json();
    if (response.ok) {
      // User creation succeeded
      setClasses(responseData.body);
    } else {
      // User creation failed
      setAlertMessage("could not get classes");
      setAlertType("error");
      setAlertDisplay(true);
      const errorData = await response.json();
      //console.error("User creation failed:", errorData.error);
    }
  };

  const getFormError = async (error) => {
    setAlertMessage(error);
    setAlertType("error");
    setAlertDisplay(true);
  };

  const createClass = async (userData) => {
    setIsLoading(true);
    try {
      // {
      //   name: userData.name,
      //   arm: userData.arm,
      //   teacher: userData.teacher,
      // }
      setFormDisplay(false);

      const response = await fetch(`${server1}/admins/create_class`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify(userData),
      });

      const responseData = await response.json();
      if (response.ok) {
        // User creation succeeded
        setAlertMessage("Class created successfully");
        setAlertType("success");
        setClassData(responseData.body);
        setAlertDisplay(true);
        getClasses()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
      } else {
        // User creation failed
        setAlertMessage(responseData.message);
        setAlertType("error");
        setAlertDisplay(true);
        // const errorData = await response.json();
        // console.error("User creation failed:", errorData.error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  };

  const setToEdit = (data, id) => {
    displayDropDownMenu(id);
    setValueToEdit(data.className);
    setOtherInfoToEdit(data);
    setIdToEdit(data._id);
  };

  const editClass = async (data) => {
    try {
      setRenderEdit(false);
      console.log(data);
      const response = await fetch(`${server1}/admins/update_class/${idToEdit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify(data),
      });
      var msg = await response.json();
      console.log({ msg });
      if (response.ok) {
        // User creation succeeded
        setAlertMessage("Class updated successfully");
        setAlertType("success");
        setAlertDisplay(true);
        getClasses()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
        //const responseData = await response.json();
      } else {
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteClassSubject = async (id) => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const deleteClass = async (id) => {
    try {
      console.log(id);
      setRenderEdit(false);
      const response = await fetch(`${server1}/admins/delete_class`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sds: cookie.sds_auth_token,
        },
        body: JSON.stringify({id: id})
      });
      var msg = await response.json();
      console.log(response);
      if (response.ok) {
        // User creation succeeded
        setAlertMessage("Class updated successfully");
        setAlertType("success");
        setClassData(msg.body);
        setAlertDisplay(true);

        getClasses()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
        //const responseData = await response.json();
      } else {
        // User creation failed
        setAlertMessage(msg.message);
        setAlertType("error");
        setAlertDisplay(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayDropDownMenu = (id) => {
    const elem = document.getElementById(id);
    elem.style.display = elem.style.display === "none" ? "flex" : "none";
  };
  return (
    <>
      {isLoading && <Loading />}
      <DashboardLayout>
        {alertDisplay && (
          <div
            style={{
              background: alertType === "error" ? "#d31a38" : "green",
              width: 350,
              position: "absolute",
              zIndex: 10,
              right: 40,
              color: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                height: 40,
                borderRadius: "50%",
                float: "right",
                marginRight: 18,
                cursor: "pointer",
                marginTop: 2,
              }}
              onClick={() => setAlertDisplay(false)}
            >
              ×
            </div>
            <div style={{ fontSize: 14, marginLeft: 20, marginTop: 7 }}>{alertMessage}</div>
          </div>
        )}
        <DashboardNavbar zIndex={formDisplay ? -1 : 1} />
        <MDBox sx={{ mx: 2 }}>
          <MDButton variant="contained" color="dark" onClick={() => setFormDisplay(true)}>
            <Icon>add</Icon> Add Class
          </MDButton>
        </MDBox>
        {formDisplay && (
          <ClassForm
            submitted={createClass}
            formError={getFormError}
            cancelled={() => setFormDisplay(false)}
          />
        )}
        {renderEdit && (
          <EditClassForm
            initialValue={valueToEdit}
            otherValues={otherInfoToEdit}
            formError={getFormError}
            cancelled={() => setRenderEdit(false)}
            submitted={editClass}
          />
        )}
        {classes.length > 0 && (
          <Card sx={{ p: 2, mt: 2 }}>
            <MDBox display="flex" flexDirection="column">
              <MDBox
                width="100%"
                // maxWidth="450px"
                display="flex"
                justifyContent="space-between"
                marginBottom="4px"
                padding="0px 0px 8px 14px"
                borderBottom="1px solid gray"
              >
                <MDTypography variant="h6">Descriptions</MDTypography>
                <MDTypography variant="h6">Actions</MDTypography>
              </MDBox>
              {classes &&
                classes.map((data) => {
                  const id = `${data.className}${Math.random()}`;
                  return (
                    <div className="itemList" key={data._id}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <MDTypography variant="button" fontWeight="regular">
                          {classes.indexOf(data) + 1} .
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="medium">
                          &nbsp;&nbsp;&nbsp; {data.className.toUpperCase()}
                        </MDTypography>
                      </div>
                      <div className="itemListDropdown">
                        <button onClick={() => displayDropDownMenu(id)}>
                          <MDTypography variant="button" fontWeight="medium">
                            <Icon>more_vert</Icon>
                          </MDTypography>
                        </button>
                        <div className="itemListMenu" id={id}>
                          <Card
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              overflow: "hidden",
                              zIndex: 5,
                            }}
                          >
                            <div
                              className="itemListContent"
                              onClick={() => {
                                setToEdit(data, id);
                              }}
                            >
                              <MDTypography
                                variant="overline"
                                fontWeight="medium"
                                color="dark"
                                display="flex"
                                alignItems="center"
                              >
                                <Icon>edit</Icon>&nbsp;Edit
                              </MDTypography>
                            </div>
                            <div
                              className="itemListContent"
                              onClick={() => {
                                deleteClass(data._id);
                              }}
                            >
                              <MDTypography
                                variant="overline"
                                fontWeight="medium"
                                color="error"
                                display="flex"
                                alignItems="center"
                              >
                                <Icon>delete</Icon>&nbsp;Delete
                              </MDTypography>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </MDBox>
          </Card>
        )}
      </DashboardLayout>
    </>
  );
}
